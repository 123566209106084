// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";

// firebase
import firebase from "../../../../firebase";

// app

import i18n from "../../../../assets/i18n/i18n";

import setMessages from "../../../store/actions/MessageAction";
import _ from "lodash";
import moment from "moment";
import { scroller } from "react-scroll";
import { Grid } from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
//import Popover from "@material-ui/core/Popover";
import EditIcon from '@material-ui/icons/Edit';
import Fab from "@material-ui/core/Fab";
import { Form } from "semantic-ui-react";
import MicrophoneTest from "./MicrophoneTest";
// shalom hack!!! this was ugly hard coded the channel to messageofday messed with the currentmsgChannel as state instead of props
class MessageOfDay extends Component {
	state = {
		messagesRef: firebase.database().ref("messageOfDay"),
		channelsRef: firebase.database().ref("openChannels"),
		messages: [],
		serverCommand: 0,
		isMessagesLoading: false,
		keyReference: null,
		isInfiniteScrolling: true,
		isReduxMessagesAccessLocked: false,
		elementScrollTop: 0,
		skipFirstAddedChild: true,
		isUpdateChannelInfo: false,
	};
/*
	componentDidMount() {
		const { savedMessages } = this.props;
		//const currentmsgChannel= {id:0};
		const currentmsgChannel = {
			...this.state.currentmsgChannel,
			id: "-MPWAlmbd8KXm0ZXi2mt",
		};
		this.setState(() => ({ currentmsgChannel }));
		//this.setState({currentmsgChannel:{...this.state.currentmsgChannel, id :'-MPWAlmbd8KXm0ZXi2mt'}})

		// add message listener
		//console.log(currentmsgChannel.id)
		this.addMessageListener(currentmsgChannel.id);

		// add scroll listener
		this.messagesWrapper = document.getElementById("sc-messages");
		if (this.messagesWrapper)
			this.messagesWrapper.addEventListener(
				"scroll",
				this.addScrollListener
			);

		// validate current channel data on redux
		if (
			savedMessages &&
			savedMessages.length &&
			!!_.find(savedMessages, (e) => e.channelId === currentmsgChannel.id)
		) {
			this.setState({ isReduxMessagesAccessLocked: true });
		}
	}
*/
/*
	componentWillUnmount() {
		const {
			messagesRef,
			messages,
			isInfiniteScrolling,
			keyReference,
			isReduxMessagesAccessLocked,
			currentmsgChannel,
		} = this.state;
		//const { currentmsgChannel } = this.props;

		// save loaded messages to redux
		if (!isReduxMessagesAccessLocked && messages.length) {
			this.props.setMessages({
				channelId: currentmsgChannel.id,
				messages,
				isInfiniteScrolling,
				keyReference,
			});
		}

		// unlink message ref child
		messagesRef.child(currentmsgChannel.id).off("child_added");

		// remove scroll listener
		this.messagesWrapper.removeEventListener(
			"scroll",
			this.addScrollListener
		);
	}
	*/

	render() {
		const { messagesRef, messages, isMessagesLoading } = this.state;
		//const drawerWidth = 240;
		const options = [
			{ key: 's', text: 'Shalom Darmanjian', value: 'Shalom Darmanjian' },
			{ key: 'r', text: 'Richard Bavona', value: 'Richard Bavona' },
			{ key: 'a', text: 'Abby Cadabbie', value: 'Abby Cadabbie' },   // TODO read these options from DB instead of hardcoding
		  ]
		//console.log(currentmsgChannel)
		return (
			messagesRef &&
			messages && (
				<section className="sc-side-panel-list-wrapper">
					{/* Content */}
					<section className="sc-messages" id="sc-messages">
						{
							/* Loading */
							isMessagesLoading && (
								<div className="sc-loading-message">
									<div className="sc-fake">
										<div className="sc-fake-la sc-fake-el-la-one" />
										<div className="sc-fake-la sc-fake-el-la-two" />
										<div className="sc-fake-la sc-fake-el-la-three" />
										<div className="sc-fake-la sc-fake-el-la-four" />
									</div>
								</div>
							)
						}

						{/* Message: Empty or List */}
						<Grid columns={2} >
							<Grid.Column width={5}>
								<Paper style={{ 
      padding:'10px'
    }}>
									<h3><center>Call Center Notifications</center></h3>
									1. Doughnuts in the breakroom 
									<br></br>
									2. Company retreat in June
									<br></br>
									3. CSAT Scores above 90 for December get margaritas at retreat!!
									<br></br>
									<br></br>
									{this.props.currentUser&&this.props.currentUser.type===1&&(<Fab size="small" color="primary" aria-label="add">
							<EditIcon/></Fab>) }
							{this.props.currentUser&&this.props.currentUser.type===1&&(<Fab size="small" color="primary" aria-label="add"><AddIcon/> </Fab>)
						}<p><br></br></p>
									
									{/*add the type and edit icon*/}
									<h3><center>Team Notifications</center></h3>
									{!messages && !isMessagesLoading
										? this.displayEmptyMessage()
										: this.displayMessages(messages)}<br></br>
										{this.props.currentUser&&this.props.currentUser.type===1&&(<Fab size="small" color="primary" aria-label="add">
							<EditIcon/></Fab>) }
							{this.props.currentUser&&this.props.currentUser.type===1&&(<Fab size="small" color="primary" aria-label="add"><AddIcon/> </Fab>)}
										<p><br></br></p>
										
									<h3><center>Personal Message of The Day</center></h3>
									{/*add the type and edit icon, pull team from team for agents*/}
									{this.props.currentUser&&this.props.currentUser.type===0?(<p>1. Way to kill it yesterday Shalom CSAT 95!</p>)
									:(<Form.Group inline>
										<Form.Select
												fluid
												label="Select Agent"
												options={options}
												placeholder="Shalom Darmanjian"
												onChange={(e,d) => this.setState({assign: d.value})}
											/></Form.Group>)} 
									
									{/*pull this from user's personal*/}
									<p><br></br></p>
										<p><br></br></p>
									</Paper>
							</Grid.Column>

							<Grid.Column width={6}>
							
										<Paper style={{textAlign: "center"}}>
									<h3><center>Test your microphone/network</center></h3>
									<MicrophoneTest
								channelsRef={null}
								currentChannel={null}
								currentUser={null}
								messagesRef={null}
							/>
							</Paper>
							
							</Grid.Column>
						</Grid>
					</section>
				</section>
			)
		);
	}

	/**
	 * add message listener
	 *
	 * @param channelId
	 */
	addMessageListener = (channelId) => {
		const { messagesRef } = this.state;
		const { savedMessages } = this.props;

		// load cached redux state
		if (
			savedMessages &&
			savedMessages.length &&
			!!_.find(savedMessages, (e) => e.channelId === channelId)
		) {
			savedMessages.forEach((x) => {
				// validate channel
				if (x && x.channelId === channelId) {
					// set messages
					this.setState(
						{
							messages: x.messages,
							keyReference: x.keyReference,
							isInfiniteScrolling: x.isInfiniteScrolling,
							isMessagesLoading: false,
						},
						() => {
							// scroll to last message
							if (x.messages && x.messages.length)
								this.scrollToLastMessage();

							// update new message
							this.updateNewMessage(channelId);
						}
					);
				}
			});
		} else {
			messagesRef
				.child(channelId)
				.orderByChild("timestamp")
				.limitToLast(1)
				.once("child_added")
				.then((snap) => {
					if (snap.exists()) {
						const snapshot = snap.val();
						//console.log(channelId)
						// set key reference
						this.setState(
							{
								keyReference: snapshot.timestamp,
								isMessagesLoading: false,
							},
							() => {
								// load messages
								this.loadMessages(channelId);

								// update new message
								this.updateNewMessage(channelId);
							}
						);
					}
				});
		}
	};

	/**
	 * add scroll listener
	 *
	 * @param event
	 */
	addScrollListener = (event) => {
		const { isInfiniteScrolling, elementScrollTop } = this.state;

		// infinite scrolling until active
		if (isInfiniteScrolling) {
			// validate event target
			if (event && event.target && event.target.scrollTop) {
				const { scrollTop } = event.target;
				if (elementScrollTop > scrollTop && elementScrollTop <= 200) {
					// unlock redux access for storing new messages
					this.setState({ isReduxMessagesAccessLocked: false });

					// load messages
					this.loadMessages();
				}

				// update scrollTop
				this.setState({ elementScrollTop: scrollTop });
			}
		}
	};

	/**
	 * listen to firebase in real-time to update new message
	 *
	 * @param channelId
	 */
	updateNewMessage = (channelId) => {
		this.state.messagesRef
			.child(channelId)
			.orderByChild("timestamp")
			.limitToLast(1)
			.on("child_added", (snap) => {
				const { messages, skipFirstAddedChild } = this.state;
				const previousSnapshot =
					messages.length && messages[messages.length - 1].snapshot;
				const snapshot = snap.val();

				// skip first added child
				if (skipFirstAddedChild) {
					this.setState({ skipFirstAddedChild: false });
					return;
				}

				// message
				const message = {
					snapshot,
					isMessageOnSameDay: this.messageOnSameDay(
						previousSnapshot,
						snapshot
					),
					isMessageOnSameDayBySameUser: this.messageOnSameDayBySameUser(
						previousSnapshot,
						snapshot
					),
				};

				// push message
				messages.push(message);

				// set messages
				// remove loading
				this.setState(
					{
						messages,
						isMessagesLoading: false,
						isUpdateChannelInfo: true,
					},
					() => {
						// unlock redux access for storing new messages
						this.setState({ isReduxMessagesAccessLocked: false });

						// scroll to last message
						this.scrollToLastMessage();
					}
				);
			});
	};

	/**
	 * load messages on scroll
	 */
	loadMessages = () => {
		//const { currentmsgChannel } = this.props;
		const {
			keyReference,
			messages,
			messagesRef,
			currentmsgChannel,
		} = this.state;
		const messagesLimit = 51;
		const loadedMessages = [];
		let previousSnapshot = null;
		//console.log(currentmsgChannel.id)
		messagesRef
			.child(currentmsgChannel.id)
			.orderByChild("timestamp")
			.endAt(keyReference)
			.limitToLast(messagesLimit)
			.once("value", (snap) => {
				if (snap.exists()) {
					const snaps = snap.val();
					const snapshots = Object.values(snaps);

					// old messages + existing messages
					messages
						.slice(1) // remove first element
						.forEach((message) => snapshots.push(message.snapshot));

					// iterate snapshots
					snapshots.forEach((snapshot, index) => {
						// remember key
						if (index === 0) {
							this.setState({ keyReference: snapshot.timestamp });
						}

						// message
						const message = {
							snapshot,
							isMessageOnSameDay: this.messageOnSameDay(
								previousSnapshot,
								snapshot
							),
							isMessageOnSameDayBySameUser: this.messageOnSameDayBySameUser(
								previousSnapshot,
								snapshot
							),
						};

						// set previous snapshot
						previousSnapshot = snapshot;

						// push message
						loadedMessages.push(message);
					});
				}
			})
			.then(() => {
				const loadedMessagesLength = loadedMessages.length;
				const messagesLength = messages.length;

				// set messages
				// set infinite scrolling
				this.setState({
					messages: loadedMessages,
					isInfiniteScrolling:
						loadedMessagesLength !== messagesLength,
				});
				/*
				// scroll to last message
				if (loadedMessagesLength !== 0 && !messagesLength) {
					this.scrollToLastMessage();
				}
				*/
			})
			.catch();
	};

	/**
	 * if message is sent on the same day
	 *
	 * @param previousSnapshot
	 * @param snapshot
	 * @returns {boolean}
	 */
	messageOnSameDay = (previousSnapshot, snapshot) => {
		return (
			!!previousSnapshot &&
			moment(snapshot.timestamp).isSame(previousSnapshot.timestamp, "day") // granularity: day
		);
	};

	/**
	 * check:
	 * 1) if message is sent on the same day
	 * 2) if message is from the same user
	 *
	 * @param previousSnapshot
	 * @param snapshot
	 * @returns {boolean}
	 */
	messageOnSameDayBySameUser = (previousSnapshot, snapshot) => {
		return (
			!!previousSnapshot &&
			this.messageOnSameDay(previousSnapshot, snapshot) &&
			previousSnapshot.user.id === snapshot.user.id
		);
	};

	/**
	 * display all messages
	 *
	 * @param messages
	 */
	displayMessages = (messages) =>
		messages &&
		messages.length > 0 &&
		messages.map((message, index) => (
			<article key={index}>
				{/* content */}
				<div>
					{/* non-continuous message */}

					{/* description */}
					{message.snapshot.content && (
						<p
							dangerouslySetInnerHTML={{
								__html: message.snapshot.content,
							}}
						/>
					)}
				</div>
			</article>
		));

	/**
	 * display empty message
	 *
	 * @returns {*}
	 */
	displayEmptyMessage = () => (
		<div className="sc-start-conversation">
			<p>{i18n.t("CHAT.MESSAGES_PANEL.MESSAGES.EMPTY_CHANNEL")}</p>
			nothing!!!!
		</div>
	);

	/**
	 * scroll to the last message
	 */
	scrollToLastMessage = () => {
		const options = {
			duration: 0,
			delay: 0,
			smooth: false,
			containerId: "sc-messages",
			offset: 50, // scrolls to element + 50 pixels down the page
		};
		scroller.scrollTo("last-message", options);
	};
}

// props
const mapStateFromProps = (state) => ({
	savedMessages: state.message,
	currentmsgChannel: state.currentmsgChannel,
	currentUser: state.user.currentUser,
});

export default connect(mapStateFromProps, { setMessages })(MessageOfDay);
