// app
import * as actionTypes from '../actions/ActionTypes';


// init channel state
const initChannelState = {
	currentChannel: null,
	currentLiveChannelList: [],
	currentQueuedChannelList: [],
	currentTeamRoomList:[],
	currentTeamsRoom:null,
};

// reducer: Channel
const channelReducer = (state = initChannelState, action) => {
	switch (action.type) {
		case actionTypes.SET_CURRENT_CHANNEL:
			return {
				...state,
				currentChannel: action.payload
			};
		
		case actionTypes.SET_LIVE_CHANNEL_LIST:
			return {
				...state,
				currentLiveChannelList: action.payload
			};

		case actionTypes.SET_QUEUED_CHANNEL_LIST:
			return {
				...state,
				currentQueuedChannelList: action.payload
			};
		
		case actionTypes.SET_TEAM_ROOM_LIST:  // TODO put this into a room action/reducter seperate from channels
			return {
				...state,
				currentTeamRoomList: action.payload
			};

		case actionTypes.SET_CURRENT_TEAMS_ROOM:  // TODO put this into a room action/reducter seperate from channels
			return {
				...state,
				currentTeamsRoom: action.payload
			};


		default:
			return state;
	}
};

export default channelReducer;
