// action types
import * as actionTypes from './ActionTypes';

// set user
export const setUser = (user) => {
	return {
		type: actionTypes.SET_CURRENT_USER,
		payload: user
	};
};

// update user avatar
export const updateUserAvatar = (user) => {
	return {
		type: actionTypes.UPDATE_USER_AVATAR,
		payload: user
	};
};

// update user status
export const updateUserStatus = (status) => {
	return {
		type: actionTypes.UPDATE_USER_STATUS,
		payload: status
	};
};

// update user colors
export const updateUserColors = (colors) => {
	return {
		type: actionTypes.UPDATE_USER_COLORS,
		payload: colors
	};
};

// set alerts badge show or not show (0/1)
export const setAlertBadgesShow = (show) => {
	return {
		type: actionTypes.SET_ALERT_BADGES_SHOW,
		payload: show
	};
};

// set teams badge show or not show (0/1)
export const setTeamsBadgesShow = (show) => {
	return {
		type: actionTypes.SET_TEAMS_BADGES_SHOW,
		payload: show
	};
};

// set calls badge show or not show (0/1)
export const setCallsBadgesShow = (show) => {
	return {
		type: actionTypes.SET_CALLS_BADGES_SHOW,
		payload: show
	};
};

// set calls badge show or not show (0/1)
export const setCallsScreen = (screenState) => {
	return {
		type: actionTypes.SET_CALLS_SCREEN,
		payload: screenState
	};
};


// clear user
export const clearUser = () => {
	return {
		type: actionTypes.CLEAR_USER
	}
};
