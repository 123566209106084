// firebase
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

// initialize firebase
const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId:process.env.REACT_APP_FIREBASE_MEASURE_ID
	/*
	apiKey: "AIzaSyB6aC99EtbOJ-ZynGiM33ePXOqB8yD1Pd4",
	authDomain: "synth-296423.firebaseapp.com",
	databaseURL: "https://synth-296423-default-rtdb.firebaseio.com",
	projectId: "synth-296423",
	storageBucket: "synth-296423.appspot.com",
	messagingSenderId: "851266916999",
	appId: "1:851266916999:web:74991f553a6ca301781e22",
	measurementId: "G-N41DCLHXN8"
	*/
  };
firebase.initializeApp(config);

export default firebase;
