// action types
import * as actionTypes from './ActionTypes';

// set channel
export const setCustomer = (customer) => {
	return {
		type: actionTypes.SET_CURRENT_CUSTOMER,
		payload: customer
	};
};

export const setCustomerTicketCount = (ticketCount) => {
	return {
		type: actionTypes.SET_CURRENT_CUSTOMER_TICKET_COUNT,
		payload: ticketCount
	};
};

export const setCustomerNoteCount = (noteCount) => {
	return {
		type: actionTypes.SET_CURRENT_CUSTOMER_NOTE_COUNT,
		payload: noteCount
	};
};


