// react
import React, { Component } from 'react';

// redux
import { connect } from 'react-redux';

// firebase
import firebase from '../../../../../firebase';

// app
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Button from '@material-ui/core/Button/Button';
import i18n from '../../../../../assets/i18n/i18n';
import Icon from '@material-ui/core/es/Icon/Icon';
import { updateUserAvatar, updateUserStatus } from '../../../../store/actions/UserAction';
import {setLiveChannelList,setQueuedChannelList,setChannel } from "../../../../store/actions/ChannelAction";
import {setCustomer,setCustomerNoteCount,setCustomerTicketCount} from "../../../../store/actions/CustomerAction";
import classNames from 'classnames/bind';
import FileUploadModal from '../common/FileUploadModal';
import Avatar from '@material-ui/core/Avatar';
class UserPanel extends Component {
	state = {
		usersRef: firebase.database().ref('employees'),
		channelsRef: firebase.database().ref('openChannels'),
		queuedChannelsRef: firebase.database().ref('queuedChannels'),
		openMenu: null,
		openFileModal: false
	};

	
	render() {
		const { openMenu, openFileModal } = this.state;
		const { currentUser, userStatus, userColors} = this.props;
		const userTitleStyle = { color: userColors.color_primary };
		const userSubTitleStyle = { color: userColors.color_secondary };

		const circleClass = classNames({
			'sc-circle': true,
			'sc-1': userStatus === '1',
			'sc-2': userStatus === '2',
			'sc-3': userStatus === '3',
			'sc-4': userStatus === '4'
		});

		return (
			<section className="sc-user-panel">
				{/* Button */}
				<Button onClick={this.handleOpenMenu}>
				<center>
				<Avatar alt={currentUser.displayName} src={currentUser.photoURL} style={{height:50,width:50}}/>
					<h5 className="sc-title sc-text-truncate" style={userTitleStyle}>
						{currentUser.displayName}
					</h5>
					<p className="sc-sub-title" style={userSubTitleStyle}>
						<span className={circleClass}/>
						{i18n.t(`CHAT.SIDE_PANEL.USER_PANEL.STATUS_CODE.${userStatus}`)}
					</p>
					</center>	
				</Button>

				{/* Menu */}
				<Menu
					className="sc-user-panel-menu"
					anchorEl={openMenu}
					onClick={this.handleCloseMenu}
					open={Boolean(openMenu)}>
					<MenuItem>
						<div className="sc-status">
							<button
								type="button"
								className="sc-circle sc-1 cd-tooltip"
								onClick={this.handleChangeStatus}
								value="1"
								disabled={userStatus === '1'}>
								<span className="cd-right">{i18n.t('CHAT.SIDE_PANEL.USER_PANEL.STATUS_CODE.1')}</span>
							</button>
							
							<button
								type="button"
								className="sc-circle sc-2 cd-tooltip"
								onClick={this.handleChangeStatus}
								value="2"
								disabled={userStatus === '2'}>
								<span className="cd-right">{i18n.t('CHAT.SIDE_PANEL.USER_PANEL.STATUS_CODE.2')}</span>
							</button>
							<button
								type="button"
								className="sc-circle sc-3 cd-tooltip"
								onClick={this.handleChangeStatus}
								value="3"
								disabled={userStatus === '3'}>
								<span className="cd-left">{i18n.t('CHAT.SIDE_PANEL.USER_PANEL.STATUS_CODE.3')}</span>
							</button>
							<button
								type="button"
								className="sc-circle sc-4 cd-tooltip"
								onClick={this.handleChangeStatus}
								value="4"
								disabled={userStatus === '4'}>
								<span className="cd-left">{i18n.t('CHAT.SIDE_PANEL.USER_PANEL.STATUS_CODE.4')}</span>
							</button>
						</div>
					</MenuItem>
					
					<MenuItem onClick={this.handleSignOut}>
						<Icon>power_settings_new</Icon>
						{i18n.t('CHAT.SIDE_PANEL.USER_PANEL.LOGOUT')}
					</MenuItem>
				</Menu>

				{
					// File Upload Modal
					openFileModal && (
						<FileUploadModal
							uploadPath="settings/profile/"
							openFileModal={openFileModal}
							imagePreview={currentUser.photoURL}
							handleCloseFileModal={this.handleCloseFileModal}
							prepareMediaToUpload={this.handleChangeAvatar}
						/>
					)
				}
			</section>
		);
	}

	/**
	 * handle open menu
	 *
	 * @param event
	 */
	handleOpenMenu = (event) => {
		this.setState({ openMenu: event.currentTarget });
	};

	/**
	 * handle close menu
	 */
	handleCloseMenu = () => {
		this.setState({ openMenu: null });
	};

	/**
	 * change user status
	 *
	 * @param event
	 */
	handleChangeStatus = (event) => {
		const { usersRef } = this.state;
		const { currentUser } = this.props;
		const status = { code: event.target.value };

		// update on firebase
		usersRef
			.child(currentUser.uid)
			.update(status)
			.then(() => {
				// update on redux
				this.props.updateUserStatus(status.code);

				// close menu
				this.handleCloseMenu();
			});
	};

	/**
	 * handle change avatar
	 *
	 * @param fileUrl
	 */
	handleChangeAvatar = (fileUrl) => {
		const { usersRef } = this.state;
		const { currentUser } = this.props;

		// upload image on storage
		firebase
			.auth()
			.currentUser
			.updateProfile({ photoURL: fileUrl })
			.then(() => {
				// updated user
				const updatedUser = firebase.auth().currentUser;

				// update on redux
				this.props.updateUserAvatar({ ...updatedUser });
			});

		// update image on database
		usersRef
			.child(currentUser.uid)
			.update({ avatar: fileUrl })
			.then();
	};

	/**
	 * handle sign-out
	 */
	handleSignOut = () => {
		const {queuedChannelList,liveChannelList,currentUser}= this.props;
		const {channelsRef,queuedChannelsRef,usersRef}=this.state;
		liveChannelList.map((liveChannel) => 
			this.clearAgentAndChannel(channelsRef,liveChannel,0,0))
		
		setTimeout(() => {  console.log("waiting for firebase"); }, 500); // SHALOM!!! change this to actually wait for firebase change
		queuedChannelList.map((queuedChannel) => 
			this.clearAgentAndChannel(queuedChannelsRef,queuedChannel,0,-1))

			this.props.setLiveChannelList([])
		this.props.setQueuedChannelList([])
		this.props.setCustomer(null)
		this.props.setCustomerNoteCount(0)
		this.props.setCustomerTicketCount(0)
		this.props.setChannel(null);
		// update on firebase
		usersRef
			.child(currentUser.uid)
			.update({code:"0"})
			.then(() => {
				// update on redux
				this.props.updateUserStatus("0");
				
			});
		firebase
			.auth()
			.signOut()
			.then();
	};
	clearAgentAndChannel = (channelRef,channel,agentFlag,channelFlag) => {
		// Takes in openChannels or queuedChannels only tested
		channelRef
        .child(channel.id)
        .child('agentID')
        .set(agentFlag)
        .then(() => {
          // empty errors
          this.setState({ errors: [] });
        })
        .catch((error) => {
          this.setState({ errors: [error] });
		})
		channelRef
        .child(channel.id)
        .child('active')
        .set(channelFlag)
        .then(() => {
          // empty errors
          this.setState({ errors: [] });
        })
        .catch((error) => {
          this.setState({ errors: [error] });
		})
	}
	/**
	 * handle open file modal
	 */
	handleOpenFileModal = () => {
		this.setState({ openFileModal: true });
	};

	/**
	 * handle close file modal
	 */
	handleCloseFileModal = () => {
		this.setState({ openFileModal: false });
	};
}


const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	liveChannelList: state.channel.currentLiveChannelList,
	queuedChannelList: state.channel.currentQueuedChannelList,
});
export default connect(mapStateToProps,{ setChannel,setCustomerNoteCount,setCustomerTicketCount,updateUserAvatar, updateUserStatus,setLiveChannelList,setQueuedChannelList,setCustomer })(UserPanel);
