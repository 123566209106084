// react
import React, { Component } from "react";

// redux

// firebase
import firebase from "../../../../../firebase";

// app

//import NotesIcon from "@material-ui/icons/Notes";
//import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";

import Grid from "@material-ui/core/Grid";
//import MoreVertIcon from "@material-ui/icons/MoreVert";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
class MessagesHeader extends Component {
	state = {
		usersRef: firebase.database().ref("employees"),
	};

	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.currentUser !== prevProps.currentUser) {
			this.setState({ currentUser: this.props.currentUser });
		}
	}

	render() {
		const { currentChannel } = this.props;

		const rows = [createData("Facebook", "Support", "Open", "50%")];
		var date = new Date(currentChannel.timestamp);
		function createData(name, calories, fat, carbs) {
			return { name, calories, fat, carbs };
		}

		const StyledTableCell = withStyles((theme) => ({
			head: {
				backgroundColor: theme.palette.grey[500],
				color: theme.palette.common.white,
			},
			body: {
				fontSize: 20,
			},
		}))(TableCell);
		return (
			<section className="sc-messages-header">
				<Grid container direction="row">
					{" "}
					{/*left and right */}
					<Grid item container xs={11} direction="row">
						{" "}
						{/*avatar and enduser details */}
						<Grid item>
							<Avatar
								alt="channel-avatar"
								src={currentChannel.avatar}
							/>

							{/* why didn't scss work? */}
						</Grid>
						<Grid item>
							<Typography>
								{" "}
								&nbsp;&nbsp;{!currentChannel.type&&currentChannel.name}{" "}
								{currentChannel.type &&(currentChannel.details)}
							</Typography>
							<Typography>
								{" "}
								&nbsp;&nbsp; Last Message{" "}
								{date.toLocaleTimeString()}{" "}
							</Typography>
						</Grid>
					</Grid>
					<Grid>
						{!currentChannel.type &&(<TableContainer component={Paper}>
							<Table size="small" aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell>
											Channel
										</StyledTableCell>
										<StyledTableCell align="center">
											Queue
										</StyledTableCell>
										<StyledTableCell align="center">
											Status
										</StyledTableCell>
										<StyledTableCell align="center">
											CSAT
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row) => (
										<TableRow key={row.name}>
											<TableCell
												component="th"
												scope="row"
											>
												{row.name}
											</TableCell>
											<TableCell align="center">
												{row.calories}
											</TableCell>
											<TableCell align="center">
												{row.fat}
											</TableCell>
											<TableCell align="center">
												{row.carbs}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>)}
					</Grid>
					{/*
						<Grid item xs={2}>
							<Grid item justify="flex-end">
								<NotesIcon
									onClick={this.handleOpenChannelInfoPopover}
								/>
								Notes
							</Grid>
							<Grid item>
								<PersonOutlineOutlinedIcon
									onClick={this.handleOpenChannelInfoPopover}
								/>
								Contact
							</Grid>
							<Grid item>
								<MoreVertIcon
									onClick={this.handleOpenChannelInfoPopover}
								/>
								More
							</Grid>
						</Grid>
					*/}
				</Grid>

				{/* Channel Info Popover */}
				{/*
				<Popover
					className="sc-channel-info-popover"
					anchorEl={openChannelInfoPopover}
					open={Boolean(openChannelInfoPopover)}
					onClose={this.handleCloseChannelInfoPopover}
				>
					<ChannelInfoPopover
						currentChannel={currentChannel}
						isUpdateChannelInfo={isUpdateChannelInfo}
					/>
				</Popover>
				*/}
			</section>
		);
	}
}

export default MessagesHeader;
