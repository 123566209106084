// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
// firebase
import firebase from "../../../../firebase";
// app

//import { Grid } from "semantic-ui-react";
import { Grid, Box } from "@material-ui/core/";
import { Doughnut, Line, Pie } from "react-chartjs-2";

import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";

import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { withStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import TableHead from "@material-ui/core/TableHead";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import doughnutlabel from'chartjs-plugin-doughnutlabel';
//REF https://github.com/reactchartjs/react-chartjs-2
//REF https://reactchartjs.github.io/react-chartjs-2/#/

const lineOptions = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};
const options = {
	/*
    title:{display:true,
	text:'ff',
	position:'bottom',
	},*/
	responsive:true,
    maintainAspectRatio: false,
	plugins: {doughnutlabel: {
		labels: [{
		  text: '3',
		  padding:"10",
		  font: {
			size: 20,
			weight: 'bold'
		  }
		 
		}, {
		  text: 'agents'
		}]
	  } } , 
};

const backgroundColor = [
	// TODO grab this data from firebase userprefs
	"rgba(255, 99, 132, 0.2)",
	"rgba(54, 162, 235, 0.2)",
	"rgba(255, 206, 86, 0.2)",
	"rgba(75, 192, 192, 0.2)",
	"rgba(153, 102, 255, 0.2)",
	"rgba(255, 159, 64, 0.2)",
];
const borderColor = [
	// TODO grab this data from firebase userprefs
	"rgba(255, 99, 132, 1)",
	"rgba(54, 162, 235, 1)",
	"rgba(255, 206, 86, 1)",
	"rgba(75, 192, 192, 1)",
	"rgba(153, 102, 255, 1)",
	"rgba(255, 159, 64, 1)",
];
const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.grey[500],
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 20,
	},
}))(TableCell);
class Queues extends Component {
	state = {
		metricsRef: firebase.database().ref("metrics"),
		queuesRef: firebase.database().ref("queues"),
		//lineChartData:[],
		pieChartData: {
			labels: [],
			datasets: [
				{
					label: "--",
					data: [],
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
				},
			],
		},
		lineChartData: {
			//labels: [...Array(dataVal.length).keys()], // TODO change to dates
			labels: [...Array(1).keys()], // TODO change to dates
			datasets: [
				{
					label: "CSAT Score Last Seven Days ",
					data: [],
					fill: true,
					backgroundColor: "rgb(255, 99, 132)", // TODO pull from userprefs
					borderColor: "rgba(255, 99, 132, 0.2)",
				},
			],
		},
		statsData: [],
		liveAgents: [],
		customers: [],
		selected: null,
		
	};

	componentDidMount() {
		this.grabFirebaseData();
		this.queuesListener();
		// TODO listen to the queues and fill up statsData live agent data
	}

	componentWillUnmount() {
		this.removeFirebaseDataListener(); //no need since just pull once on mount
		// TODO remove queues listener  and live agent listeners
	}

	render() {
		const { currentUser, userStatus, userColors } = this.props;
		const { pieChartData, lineChartData } = this.state;
		const rows = [createData("Facebook", "Support", "Open", "50%")];
		const doughnutOptions = {
			centerText: {
				display: true,
				text: "agents" + this.state.liveAgents.length,
			},
		};
		function createData(name, calories, fat, carbs) {
			return { name, calories, fat, carbs };
		}
		return (
			currentUser &&
			userStatus &&
			userColors && (
				<section className="sc-side-panel-list-wrapper">
					<Box>
						<Grid container spacing={3} direction="row">
							<Grid item xs={8}>
								<TableContainer
									component={Paper}
									style={{
										padding: "5px",
										minWidth: 320,
									}}
								>
									<Table>
										<TableHead>
											<TableRow>
												<StyledTableCell>
													Team Name
												</StyledTableCell>
												<StyledTableCell align="center">
													Avail
												</StyledTableCell>
												<StyledTableCell align="center">
													Chat
												</StyledTableCell>
												<StyledTableCell align="center">
													Wrapup
												</StyledTableCell>
												<StyledTableCell align="center">
													Break
												</StyledTableCell>
												<StyledTableCell align="center">
													WaitQ
												</StyledTableCell>
												<StyledTableCell align="center">
													Accpt
												</StyledTableCell>
												<StyledTableCell align="center">
													Abd
												</StyledTableCell>
												<StyledTableCell align="center">
													Abd%
												</StyledTableCell>
												<StyledTableCell align="center">
													SLA%
												</StyledTableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{this.displayQueues(
												this.state.statsData
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={4}>
								{" "}
								<Paper style={{ height: "260px" }}>
									<Doughnut
										data={pieChartData}
										options={options}
									/>
																		
								</Paper>
							</Grid>
						</Grid>

						<p></p>
						<Grid spacing={1} container direction="row">
							<Grid xs={12} item>
								<Grid container direction="row" spacing={1}>
									<Grid item xs={3}>
										<Paper>
											{this.state.selected && (
												<center>
													<h1>
														{
															this.state
																.liveAgents
																.length
														}
													</h1>
													<h6>AVAILABLE AGENTS</h6>
												</center>
											)}
											<ul className="cd-remove-bullets ">
												{/*this.displayChannels(channels, true, userColors)*/}

												{this.displayLiveAgents(
													this.state.liveAgents
												)}
											</ul>
										</Paper>
									</Grid>
									<Grid item xs={3}>
										<Paper>
											{this.state.selected && (
												<center>
													<h1>
														{
															this.state.customers
																.length
														}
													</h1>
													<h6>CUSTOMERS IN QUEUE</h6>
												</center>
											)}
										</Paper>
									</Grid>
									<Grid xs={6} item>
									{this.state.selected && (<Paper>
										<center>
												<h6>LIVE AGENT STATS</h6>
											</center>
											<Table
												size="small"
												aria-label="customized table"
											>
												<TableHead>
													<StyledTableCell></StyledTableCell>

													<StyledTableCell>
														Name
													</StyledTableCell>
													<StyledTableCell>
														Accept
													</StyledTableCell>
													<StyledTableCell align="center">
														Alert
													</StyledTableCell>
													<StyledTableCell align="center">
														Reject
													</StyledTableCell>
													<StyledTableCell align="center">
														Wrapup
													</StyledTableCell>
													<StyledTableCell>
														Break
													</StyledTableCell>
													<StyledTableCell align="center">
														Chat
													</StyledTableCell>
													<StyledTableCell align="center">
														CSAT
													</StyledTableCell>
												</TableHead>
												<TableBody>
													{this.displayLiveAgentsStats(
														this.state.liveAgents
													)}
												</TableBody>
											</Table>
										</Paper>)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</section>
			)
		);
	}

	grabFirebaseData = () => {
		const { currentUser } = this.props;
		const { queuesRef, lineChartData, pieChartData } = this.state;
		var pieLabels = [];
		var pieData = [];
		var total = 0;
		var statuses = { Break: 0, Available: 0, Wrapup: 0 };
		//var lineData=[...lineChartData]
		const metricsDesired = [
			"CSAT",
			"breaks",
			"chatTime",
			"closeOut",
			"miscTime",
		];
		//var data=[]

		queuesRef.child("teams").once("value", (snap) => {
			if (snap.exists()) {
				snap.forEach((child) => {
					child.child("agents").forEach((agent) => {
						statuses[agent.val().status] += 1;
						total+=1
					});
					/*
					if (metricsDesired.indexOf(child.key) >= 0) {
						const dataVal = child.val();

						if (child.key === "CSAT") {
							var sliced = dataVal.slice(
								dataVal.length - 7,
								dataVal.length
							);
							lineChartData["labels"] = [
								...Array(sliced.length).keys(),
							]; // TODO change to dates
							lineChartData["datasets"][0]["data"] = sliced;
						} else {
							pieData.push(dataVal[dataVal.length - 1]);
							pieLabels.push(child.key);
						}
					}*/
				});
				//for each type of status
				
				Object.keys(statuses).forEach((key) => {
					pieData.push(statuses[key]);
					pieLabels.push(key+':'+(100*statuses[key]/total).toFixed(0)+'%');
					console.log(statuses[key]);
				});
				//pieData.push( status array
				//pieLabels.push( status label
				pieChartData["labels"] = pieLabels;
				pieChartData["datasets"][0]["data"] = pieData;

				this.setState({
					pieChartData: pieChartData,
				});
			}
		});
	};
	removeFirebaseDataListener = () => {
		const { metricsRef } = this.state;
		metricsRef.off();
	};
	queuesListener = () => {
		const loadedTeamsStats = [];
		var tmpsnap = [];
		this.state.queuesRef.child("teams").on("child_added", (snap) => {
			// push teams

			tmpsnap = snap.val(); // Shalom!! hack
			loadedTeamsStats.push(snap.val());
		});

		this.setState({ statsData: loadedTeamsStats });
	};
	displayQueues = (rows) =>
		rows.map((row) => (
			<TableRow
				key={row.teamName}
				hover
				onClick={(event) => this.handleClick(event, row)}
				role="checkbox"
				tabIndex={-1}
				selected={row.teamName === this.state.selected}
			>
				<TableCell component="th" scope="row">
					{row.teamName}
				</TableCell>
				<TableCell align="center">{row.stats.avail}</TableCell>
				<TableCell align="center">{row.stats.chat}</TableCell>
				<TableCell align="center">{row.stats.wrapup}</TableCell>
				<TableCell align="center">{row.stats.break}</TableCell>
				<TableCell align="center">{row.stats.waitQ}</TableCell>
				<TableCell align="center">{row.stats.accpt}</TableCell>
				<TableCell align="center">{row.stats.abd}</TableCell>
				<TableCell align="center">
					{Math.round((100 * row.stats.abd) / row.stats.accpt, 2)}
				</TableCell>
				<TableCell align="center">{row.stats.sla}</TableCell>
			</TableRow>
		));
	displayLiveAgents = (liveAgents) =>
		liveAgents &&
		liveAgents.length > 0 &&
		liveAgents.map((liveAgent) => (
			<TableRow
				hover
				onClick={(event) => this.handleClick(event, liveAgent.id)}
				role="checkbox"
				tabIndex={-1}
			>
				<li
					style={{
						backgroundColor:
							liveAgent.status === "Available" ? "green" : "red", // TODO set to a system color not hard coded
					}}
				>
					<TableCell
						align="left"
						style={{ width: 40, height: 80 }}
					></TableCell>
				</li>
				<TableCell align="left">
					{<Avatar alt="Remy Sharp" src={liveAgent.avatar} />}
				</TableCell>
				<TableCell align="left">
					{<p style={{ fontSize: "10px" }}>{liveAgent.name + ""}</p>}
				</TableCell>
				<TableCell align="left">
					{<p style={{ fontSize: "10px" }}>{liveAgent.status}</p>}
				</TableCell>
				<TableCell align="right" style={{ width: 120 }}>
					{<MoreVertIcon hover />}
				</TableCell>
			</TableRow>
		));
	displayLiveAgentsStats = (liveAgents) =>
		liveAgents &&
		liveAgents.length > 0 &&
		liveAgents.map((liveAgent) => (
			<TableRow
				hover
				onClick={(event) => this.handleClick(event, liveAgent.id)}
				role="checkbox"
				tabIndex={-1}
			>
				<TableCell align="left">
					{<Avatar alt="Remy Sharp" src={liveAgent.avatar} />}
				</TableCell>
				<TableCell align="left">
					{<p style={{ fontSize: "10px" }}>{liveAgent.name + ""}</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>0</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>2</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>1</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>2</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>{liveAgent.daily.breaks}</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>{liveAgent.daily.chatTime}</p>}
				</TableCell>
				<TableCell align="center">
					{<p style={{ fontSize: "10px" }}>{liveAgent.daily.CSAT}</p>}
				</TableCell>
			</TableRow>
		));

	changeLiveAgent = (liveAgent) => {};
	handleClick = (event, row) => {
		var stats = [];
		this.setState({ selected: row.teamName });
		this.setState({ liveAgents: row.agents });
		
	};

	
}

// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentChannel: state.channel.currentChannel,
	userStatus: state.user.status,
	userColors: state.user.colors,
});

export default connect(mapStateToProps)(Queues);
