// user: this is the agent/manager/operations
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_STATUS = 'UPDATE_USER_STATUS';
export const UPDATE_USER_COLORS = 'UPDATE_USER_COLORS';
export const SET_ALERT_BADGES_SHOW ='SET_ALERT_BADGES_SHOW'; // ? perhaps put into own badges or screensactions?
export const SET_TEAMS_BADGES_SHOW ='SET_TEAMS_BADGES_SHOW'; // ? perhaps put into own badges or screensactions?
export const SET_CALLS_BADGES_SHOW ='SET_CALLS_BADGES_SHOW'; // ? perhaps put into own badges or screens actions?
export const CLEAR_USER = 'CLEAR_USER'; 
export const SET_CALLS_SCREEN ='SET_CALLS_SCREEN' // ? perhaps put into own screens actions?

// channel: these are the endusers communication path, channel is more about view
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL';
export const SET_LIVE_CHANNEL_LIST = 'SET_LIVE_CHANNEL_LIST';
export const SET_QUEUED_CHANNEL_LIST = 'SET_QUEUED_CHANNEL_LIST';
export const SET_CURRENT_TEAMS_ROOM ='SET_CURRENT_TEAMS_ROOM';


// rooms: these are team chats alerts etc, live longer than channels
export const SET_CURRENT_TEAM_ROOM = 'SET_CURRENT_TEAM_ROOM';
export const SET_TEAM_ROOM_LIST = 'SET_TEAM_ROOM_LIST';


// messages
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_ALERT_MESSAGES_COUNT = 'SET_ALERT_MESSAGES_COUNT';
export const SET_TEAMS_MESSAGES_COUNTS = 'SET_TEAMS_MESSAGES_COUNTS';
export const SET_LIVE_CHANNEL_MESSAGES_COUNTS = 'SET_LIVE_CHANNEL_MESSAGES_COUNTS';


// customer: this is when an enduser becomes or is identified as a customer
export const SET_CURRENT_CUSTOMER ='SET_CURRENT_CUSTOMER';
export const SET_CURRENT_CUSTOMER_TICKET_COUNT ='SET_CURRENT_CUSTOMER_TICKET_COUNT';
export const SET_CURRENT_CUSTOMER_NOTE_COUNT ='SET_CURRENT_CUSTOMER_NOTE_COUNT';
