import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import { setCallsScreen } from "../../../store/actions/UserAction";

// app
import Tickets from "./Tickets";
import Notes from "./Notes";
import ContactInfo from "./ContactInfo";

// ui
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";


class Widgets extends Component {
	render() {
		const { noteCount, ticketCount } = this.props;
		return (
			<div>
				<Accordion disabled
				/*
					expanded={this.props.callsScreen.contact}
					onChange={this.handleAccordionChange("contact")}
					*/
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography>
							<Badge>Contact Info</Badge>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ContactInfo />
					</AccordionDetails>
				</Accordion>
				<Accordion disabled
				/*
					expanded={this.props.callsScreen.notes}
					onChange={this.handleAccordionChange("notes")}
				*/	
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<Badge
								badgeContent={noteCount}
								color="primary"
								overrllap="rectangle"
							>
								Notes
							</Badge>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						
							<Notes />
						
					</AccordionDetails>
				</Accordion>
				<Accordion disabled 
					/*
					expanded={this.props.callsScreen.tickets}
					onChange={this.handleAccordionChange("tickets")} 
					*/
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2a-content"
						id="panel2a-header"
					>
						<Typography>
							<Badge
								badgeContent={ticketCount}
								color="primary"
								overrllap="rectangle"
							>
								Tickets
							</Badge>
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						
							<Tickets 
							ticketCount={ticketCount}/>
						
					</AccordionDetails>
				</Accordion>
				<Accordion disabled>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>Knowledge Base</Typography>
					</AccordionSummary>
				</Accordion>
				<Accordion disabled>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3a-content"
						id="panel3a-header"
					>
						<Typography>Escalations!</Typography>
					</AccordionSummary>
				</Accordion>
			</div>
		);
	}
	handleAccordionChange = (panel) => (event, isExpanded) => {
		var tmpCallScreen = this.props.callsScreen;
		tmpCallScreen[panel] = isExpanded ? true : false;

		this.props.setCallsScreen(tmpCallScreen);
		this.setState({ errors: [] }); // TODO do something better than forceupdate or setstate to re-render component
	};
}
const mapStateToProps = (state) => ({
	noteCount: state.customer.currentNoteCount,
	ticketCount: state.customer.currentTicketCount,
	callsScreen: state.user.callsScreen,
});
export default connect(mapStateToProps, { setCallsScreen })(Widgets);
