import {
	setChannel,
} from "../../../../app/store/actions/ChannelAction";
// reactstore
import React, { Component } from "react";
import Icon from "@material-ui/core/es/Icon/Icon";
import Box from '@material-ui/core/Box';
//=import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
// firebase
import firebase from "../../../../firebase";
import Avatar from '@material-ui/core/Avatar';
import { HorizontalBar } from "react-chartjs-2";
import { Grid, Dimmer, Loader } from "semantic-ui-react";
//const io = require("socket.io-client");
import { io } from "socket.io-client";
import callLogger from "../../utilities/helpers/Logger"
const port = process.env.PORT || 443;// was 5000
var audioContext = null;
var tmpBuffer =new Float32Array()
var globalCnt=0
var soundval=0
var packetLen=0
const packetSize=4
var pingcount=0

//const socket = io(process.env.REACT_APP_AUDIO_SERVER + port);
var socket=null

const minRate=(16000*1.5)// 2bytes*16khz*1.5 rate of about 24kz to cover 16000 of 2bytedata
var testMicClicked=false
function send(input, channel) {
	//socket.emit("ping_from_client", input, channel.id);
	socket.timeout(2500).emit("mic_test", input, 'MicTest'); // 250ms* packetsize since audio is every 250ms 300 is a little buffer over 250
	packetLen=packetLen+(new Int8Array(input)).length
	/*
	socket.emit("mic_test", input, 'MicTest', function(ack){
		console.log('The server acknowledged our event:',ack);
        if (ack>minRate){
            datarate=ack;
        }
	});
*/
	//console.log(`sent something`);
}

const SpeechRecognition =
	window.SpeechRecognition || window.webkitSpeechRecognition;
	var recognition =[]
	try{
 recognition = new SpeechRecognition();
}catch(err){
	 recognition =[]
}

const lineOptions = {
	maintainAspectRatio: false,
	backgroundColor: "rgb(15,15,15)",
	title: {
		display: true,
		align: 'bottom',
		fontSize: 2,
		text: ''
	},
	scales: {
		x: {
			display: false,
		 },
		 y: {
			display: false,
		 },
		xAxes: [
			{
				ticks: {
					beginAtZero: true,
					display:false,
					min: 0,
					max: 1   
				},
				gridLines: {
					
					lineWidth: 110,
					color: "rgba(110, 0, 0, 0)",
				},
				scaleLabel: {
					display: true,
					labelString: 'Microphone Volume'
				  },
			},
		],
		yAxes: [{
            gridLines: {
				drawBorder: false,
				lineWidth: 110,
                color: "rgba(110, 0, 0, 0)",
            }
        }],
	},
	legend: {
		display: false
	 },
};
const labels=['']
var 		lineChartData= {
	//labels: [...Array(dataVal.length).keys()], // TODO change to dates
	labels , // TODO change to dates
	datasets: [
		{
			label: "MIC",
			data: [],
			fill: true,
			backgroundColor: "rgba(10, 25, 59,0.1)", // TODO pull from userprefs
			borderColor: "rgba(255, 99, 132, 0.2)",
			
		},
	],
}
class MicrophoneTest extends Component {
	
	state = {
		message: "",
		errors: [],
		talking: false,
		transcript:false,
		talkInterval: 0,
		locked: false,
		dataRate: 0,
		ackLen:0,
		pingrate:[],
		lagCnt:0,
		liveMic:false,
		renderer: false, // this is a hack for stopping renderer but getting let mic render
		last_command: 0,
		openFileModal: false,

	};
	componentWillUnmount() { // think about agent leaving b4 mictest done?
		
		clearInterval(this.interval);
		lineChartData["datasets"][0]["data"][0] = [0]
		if (socket != null) {
			socket.disconnect()
		}
		if (this.state.talking === true) {
			console.log('microphone comp unmounted')
			this.setState({ talking: false });
					
			this.setState({ transcript: false });
			audioContext.close();
			console.log(`audio closed?`);
		}
		/*
		if (this.state.talking===true){
	console.log('microphone comp unmounted')
	
	this.setState({ talking: false });
	
	audioContext.close();
	console.log(`audio closed?`);
		}*/
	}
	componentDidMount() {
		
	this.interval = setInterval(
		() => {
			if (this.state.talking===true){
			this.setState({ time: Date.now() }); // This is an ugly hack to re-render microphone volume thingy Shalom!!! fix (stops if)
		}
			
		},500
	); // ! don't hardcode this shalom!!!!
	}	
  
	
/*
	shouldComponentUpdate(nextProps, nextState) {
		if (  this.props.currentChannel.active!=1 ) {
			//this.setState({ renderer: true });
		  return false;
		}
		return true;
	  }
	  */
	render() {
		return (
			<section >
				{/* Buttons */}
				{console.log('microphonetest: render')}
			
				
					<Grid>
					
					<Grid.Column width={7}>
						{this.state.talking &&<HorizontalBar
							          width={60}
									  height={30}
										data={lineChartData}
										options={lineOptions}
									/>}
									
									</Grid.Column>
                                    <Grid.Column  width={5}>
					<Avatar  component={Paper} elevation={5}>
					{!this.state.talking ? (
						<img
							width="16"
							height="25"
							src={require("../../../../assets/mic.png")}
							alt="loading..."
							onClick={this.talkingChange}
						/>
					) : (
						<img
							width="33"
							height="50"
							src={require("../../../../assets/mic.gif")}
							alt="loading..."
							onClick={this.talkingChange}
						/>
					)}</Avatar>
                    
					</Grid.Column>



                                    <Grid.Column  width={1}>
                                    {this.state.talking &&
                                    <Dimmer active inverted>
                                    <Loader inverted>Testing </Loader>
                                    </Dimmer>}
                                    
                                    </Grid.Column>
                                    
									</Grid>
									{console.log('t:'+this.state.talking+' d:'+this.state.dataRate)}
									{!this.state.talking && this.state.dataRate>0 &&<h3>
                                    Microphone:{this.micResults()}
									Network:{this.networkResults()}
									</h3>}
									{!this.state.talking &&this.state.pingrate.length>2 &&<p>
                                    Ping:{this.pingResults()}
									
									</p>}
									{/*!this.state.talking && this.state.dataRate>0 && 
                                    <h3>Microphone:{this.state.liveMic ? <font color="green"> PASS</font>:<font color="red"> FAIL</font>} Network:{this.state.ackLen>packetLen*0.8 &&this.state.dataRate >minRate &&this.state.lagCnt<6? <font color="green"> PASS</font>:<font color="red"> FAIL</font>}</h3>*/}
   
                                   
                                    
									
							
			
				
			</section>
		);
	}
	pingResults =()=>{
		//console.log(this.state.pingrate.length)
		if (this.state.pingrate.length>2){
			var dd=new Float32Array(this.state.pingrate.slice(1,this.state.pingrate.length))//remove first one since haproxy slow on first one?
			dd=dd.map(a => Math.abs(a))
			dd=Math.floor(dd.reduce((acc,v,i,a)=>(acc+v/a.length),0));
			if (dd<250){
				return <font color="green"> {dd} ms Good</font>
			}else if (dd<350){
				return <font color="#f3ff00"> {dd} ms Ok</font>
			}else if (dd<550){
				return <font color="orange"> {dd} ms Laggy</font>
			}else {
				return <font color="red"> {dd} ms Bad</font>
			}
		}else
		return <font color="red"> FAIL </font>	
	}
    micResults = ()=>{
			if (this.state.liveMic){
				return <font color="green"> PASS </font>
			} else
			   return <font color="red"> FAIL</font>	
	}
	networkResults =()=>{
		if (this.state.ackLen>packetLen*0.8 &&this.state.dataRate >minRate){
			if (this.state.ackLen<packetLen*0.9 && this.state.lagCnt<6){ // between .8 &.9 =poor
				return  <font color="orange"> POOR</font>
			}else if (this.state.lagCnt<6){
				return  <font color="green"> PASS</font>
			} else{
				return  <font color="red"> FAIL: Lag</font>
			}
		 }else {
			 return  <font color="red"> FAIL: Speed</font>
		 } 
	}

	determineMic = ()=>{
		
		var enumeratorPromise = navigator.mediaDevices.enumerateDevices().then(function(devices) {
			
			var yesmic=0
			var mic = devices.find(function(device) {
			  if( device.kind === "audioinput"){
				  yesmic=yesmic+1
			  }
			});
			if (yesmic>0){
				this.setState({liveMic:true})
				console.log('found mic')
				
			}
			else{
				this.setState({liveMic:false})
					
				console.log('No mic')
			}
	  }.bind(this))
	}

	talkingChange = () => {
		const { talking, talkInterval, locked,renderer,transcript } = this.state;
		const { messagesRef, currentChannel, currentUser } = this.props;
	    /*
		if (renderer===true && locked===true){
			this.setState({ locked: false });
		}
		*/
		if (locked === false ) {
			this.setState({ locked: true }); 
			packetLen=0
			
            
			socket = io(process.env.REACT_APP_AUDIO_SERVER + port);
			
			// deactivated talking button?
			socket.on("connect_error", () => {
				socket.disconnect()
				this.setState({dataRate:1})
			});
			var tmp;
			if (talking === false) {
				webaudio_tooling_obj(currentChannel);
				this.setState({ talking: true });
				this.determineMic()
				this.setState({ dataRate: 1 });
				//datarate=1 // this is to make sure i fail if doesn't change since 0 will not work
				console.log(`audio opened?`);
				this.setState({ talkInterval: Date.now() });
				console.log("talkInterval inside:", talkInterval);
                this.interval =  setTimeout(
                    () => {
                        this.handleCommand('1')
                        			
                        if (this.state.dataRate===0){
							this.setState({dataRate:1})
						}
                        audioContext.close();
                        console.log(`audio closed?`);
                        tmp = (Date.now() - this.state.talkInterval) / 1000;
                        console.log("talked for ", tmp);
                        console.log('DR:'+this.state.dataRate+' PL:'+packetLen)
                        this.setState({ locked: false });	
                        if (this.state.dataRate>minRate){
                            console.log('DATARATE WAS GOOD!')
                        }
						//this.setState({ talking: false });
                    },5000
                ); // ! don't hardcode this shalom!!!!
			} /*else {
				this.setState({ talking: false });
						
				
				audioContext.close();
				console.log(`audio closed?`);
				tmp = (Date.now() - talkInterval) / 1000;
				console.log("talked for ", tmp);
				
			}*/
		}
	};

	handleCommand = (command) => {
		const { locked } = this.state;
		const { channelsRef, currentChannel, messagesRef } = this.props;
		// send message

		if (command === "1") {
			
            socket.timeout(2000).emit("check_rate", 'MicTest', function(err,ack,ackL,lags){
                console.log('checkrate:',ack);
				console.log('packetlen:'+packetLen)
				console.log('ackL:'+ackL)
				console.log('lags:'+lags)
				var dataOut = {
					"datetime": Date.now(),
					"level":"info",
					"message":"checing rate from audioserver",
					"context":{
						"switchboard":{
							"method":"microphone-test",
							"user":this.props.currentUser,
							"channel":this.props.currentChannel

						}
					},
					"event":{
						"info":{
							"name":"network issues",
							"message":"checing rate from audioserver",
							"backtrace":{
							"lags":lags,										
							"network": {"rate":ack,"ackLen":ackL,"packetL":packetLen}
							}
						}

					}
					
				  }; 
			  
				callLogger(dataOut);
				this.setState({ talking: false });
				if (err){
					console.log('timeout from audioserver')
				}
				this.checkPing()
               if (ack>0){ 
	    		  console.log('checkrate:',ack);
                  this.setState({dataRate:ack});
				  this.setState({ackLen:ackL});
				  this.setState({lagCnt:lags})
				  
			   }
        	}.bind(this));
			//this.checkPing()
            //socket.emit("remove_mictest", 'MicTest');
			this.props.setChannel(null)
			
			// any validation? retry?
		}

		
	
		this.setState({ locked: true }); // deactivate the talking button
	};
	checkPing =()=>{
		
		var pre=Date.now()
		var pack=new Int16Array(32000)
		pingcount=0
		this.setState({pingrate:[]})
		
				
				socket.timeout(3800).emit("ping_check", Date.now(),pack, function(err,ack){
					var tmp=(Date.now()-ack)
					if (!err && !isNaN(tmp)){
						this.state.pingrate.push(tmp)
						this.setState({pingrate:this.state.pingrate})
					}
					socket.timeout(3800).emit("ping_check", Date.now(),pack, function(err,ack){
						var tmp=(Date.now()-ack)
						if (!err && !isNaN(tmp)){
							this.state.pingrate.push(tmp)
							this.setState({pingrate:this.state.pingrate})
						}
						socket.timeout(3800).emit("ping_check", Date.now(),pack, function(err,ack){
							var tmp=(Date.now()-ack)
							if (!err && !isNaN(tmp)){
								this.state.pingrate.push(tmp)
								this.setState({pingrate:this.state.pingrate})
							}
						
						socket.timeout(3800).emit("ping_check", Date.now(),pack, function(err,ack){
							var tmp=(Date.now()-ack)
							if (!err && !isNaN(tmp)){
								this.state.pingrate.push(tmp)
								this.setState({pingrate:this.state.pingrate})
							}
							
							console.log(this.state.pingrate)
							socket.emit("remove_mictest", 'MicTest');
							var dataOut = {
								"timestamp": Date.now(),				
								"mictest": {"pings":this.state.pingrate}
						  }; 
							callLogger(dataOut);
					
						}.bind(this));
						
				
					}.bind(this));
							
					
			
				}.bind(this));
			}.bind(this));
		
		
		/*
		var dataOut = {
			"timestamp": Date.now(),				
			"mictest": {"rate":ack,"ackLen":ackL,"lagCount":lags,"packetL":packetLen}
	  }; 
		this.callLogger(dataOut);
	  */
	}
	
	
}


//---------- this is the shit here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
function webaudio_tooling_obj(currentChannel) {
	audioContext = new AudioContext();

	console.log(audioContext.sampleRate);
	

    //const audioContext = new AudioContext({ sampleRate: 8000 });
    audioContext.audioWorklet.addModule('worklet/recorderworkletprocess.js').then(
        function () {
            const recorder = new AudioWorkletNode(audioContext, 'recorder-worklet');
            let constraints = { audio: true };
            navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
                const microphone = audioContext.createMediaStreamSource(stream);
				socket.emit("initiate_mictest", 'MicTest');
                microphone.connect(recorder);
                recorder.connect(audioContext.destination);
				recorder.port.onmessage = event =>process_microphone_buffer(event)
				
            });
        }
    );
	console.log(audioContext.sampleRate);

	// ---
	function Float32Concat(first, second)
{
    var firstLength = first.length,
        result = new Float32Array(firstLength + second.length);

    result.set(first);
    result.set(second, firstLength);

    return result;
}
	function process_microphone_buffer(event) {
		// invoked by event loop

		var microphone_output_buffer;
		if (event['data']){
		tmpBuffer = Float32Concat(tmpBuffer, event['data']); // just mono - 1 channel for now
		//microphone_output_buffer=tmpBuffer.concat(event['data'])
		//microphone_output_buffer =event['data']
		//tmpBuffer=event['data'].slice(event['data'].length-20,event['data'].length)
		// convert float audio data to 16-bit PCM
		//console.log(event['data'])
		//var buffer = new ArrayBuffer(event['data'].length * 2);
		/*
          var output = new DataView(buffer);
          for (var i = 0, offset = 0; i < microphone_output_buffer.length; i++, offset += 2) {
                  var s = Math.max(-1, Math.min(1, microphone_output_buffer[i]));
                  output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
          }
                      //socketio.emit('write-audio', buffer);
          */
		
		if (tmpBuffer.length>12000*packetSize){
			microphone_output_buffer=tmpBuffer
		//console.log("LENGTH!!!:"+tmpBuffer.length)
		tmpBuffer=[]
		var buffer = downsampleBufferR2(
			microphone_output_buffer,
			audioContext.sampleRate,
			16000
		); 
		
		/*
		var buffer = debugBuffer(
			microphone_output_buffer,
			audioContext.sampleRate,
			16000
		);
		*/
		// take this oversized buffer and pull a sliding data, trim microphone_output buffer by sliding window size
		//console.log(event['data'].length)
		var dd=new Int16Array(buffer.slice(0,buffer.length))
		dd=dd.map(a => Math.abs(a))
		dd=dd.reduce((acc,v,i,a)=>(acc+v/a.length),0);
		//console.log(dd)
		//console.log(Math.max(...dd));
		soundval=Math.floor(Math.random() * 100)
		lineChartData["datasets"][0]["data"] = [Math.max(dd)/4000]	// don't hard code 4000!! XXX shalom!
		var ee="rgba(10, 25, 59,"+Math.max(dd)/4000+")"
		lineChartData["datasets"][0]["backgroundColor"]= [ee];
		//console.log(audioContext.sampleRate)
		//send(microphone_output_buffer,currentChannel)
		send(buffer, currentChannel); //microphone_output_buffer)
		// microphone_output_buffer  <-- this buffer contains current gulp of data size BUFF_SIZE
		}} 
		//show_some_data(microphone_output_buffer, 5, "from getChannelData");
	}
	
	var downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
		if (outSampleRate === sampleRate) {
			return buffer;
		}

		if (outSampleRate > sampleRate) {
			console.log(
				"downsampling rate show be smaller than original sample rate"
			);
		}

		var sampleRateRatio = sampleRate / outSampleRate;
		var newLength = Math.round(buffer.length / sampleRateRatio);
		var result = new Int16Array(newLength);
		var offsetResult = 0;
		var offsetBuffer = 0;
		while (offsetResult < result.length) {
			var nextOffsetBuffer = Math.round(
				(offsetResult + 1) * sampleRateRatio
			);
			var accum = 0,
				count = 0;
			for (
				var i = offsetBuffer;
				i < nextOffsetBuffer && i < buffer.length;
				i++
			) {
				accum += buffer[i];
				count++;
			}

			result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
			offsetResult++; 
			offsetBuffer = nextOffsetBuffer; 
		}
		return result.buffer;
	};
	
	var downsampleBufferR2 = function (buffer, sampleRate, outSampleRate) { // this buffer is bigger than needed this function is agnostic
	// downsampling variables
	var filter = [
		-0.037935, -0.00089024, 0.040173, 0.019989, 0.0047792, -0.058675, -0.056487,
		-0.0040653, 0.14527, 0.26927, 0.33913, 0.26927, 0.14527, -0.0040653, -0.056487,
		-0.058675, 0.0047792, 0.019989, 0.040173, -0.00089024, -0.037935
	];
	const samplingRateRatio = sampleRate / outSampleRate; // 48k/16k=3
	const nOutputSamples = Math.floor((buffer.length - filter.length) / (samplingRateRatio)) + 1; // 506
	var outputBuffer = new Int16Array(nOutputSamples);

	for (var i = 0; i < outputBuffer.length; i++) {
		var offset = Math.round(samplingRateRatio * i);
		var sample = 0;
		for (var j = 0; j < filter.length; ++j) {
			sample += buffer[offset + j] * filter[j];
		}
		//console.log(sample* 0x7fff)
		var s=Math.max(-1,Math.min(1, sample)); 
		outputBuffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
	}
	return outputBuffer.buffer;
	};
	var debugBuffer = function (buffer, sampleRate, outSampleRate) { // this buffer is bigger than needed this function is agnostic
		// downsampling variables
		
		var filter = [
			-0.037935, -0.00089024, 0.040173, 0.019989, 0.0047792, -0.058675, -0.056487,
			-0.0040653, 0.14527, 0.26927, 0.33913, 0.26927, 0.14527, -0.0040653, -0.056487,
			-0.058675, 0.0047792, 0.019989, 0.040173, -0.00089024, -0.037935
		]; 
		const samplingRateRatio = sampleRate / outSampleRate; // 48k/16k=3
		//const nOutputSamples = Math.floor((buffer.length - filter.length) / (samplingRateRatio)) + 1; // 506
		const nOutputSamples = Math.floor((buffer.length) / (samplingRateRatio));
		var outputBuffer = new Int16Array(nOutputSamples);
		globalCnt=globalCnt+1;
		for (var i = 0; i < outputBuffer.length; i++) {
			
			var offset = Math.round(samplingRateRatio * i);
			/*
			var sample = 0;
			for (var j = 0; j < filter.length; ++j) {
				sample += buffer[offset + j] * filter[j];
			}
			//console.log(sample* 0x7fff)
			var s=Math.max(-1,Math.min(1, sample)); 
			outputBuffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
			*/
			var s=Math.max(-1,Math.min(1, buffer[offset])); 
			//outputBuffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
			//outputBuffer[i]= Math.round(buffer[offset]*32767)
			outputBuffer[i]= globalCnt;// Math.floor(Math.sin(Math.PI*200*globalCnt/32000)*15000)
		}
		if (globalCnt>32000){
			globalCnt=0;
		}
		//outputBuffer[0]=globalCnt;
		console.log(globalCnt)
		return outputBuffer.buffer;
		};


	/*
	function start_microphone(stream) {
		//const { currentChannel } = this.props;
		//console.log(currentChannel.id)
		socket.emit("initiate", currentChannel.id);
		//gain_node = audioContext.createGain();
		//gain_node.connect( audioContext.destination );
		console.log('here')
		microphone_stream = audioContext.createMediaStreamSource(stream);
		console.log(microphone_stream)
		//microphone_stream.connect(gain_node);

		script_processor_node = audioContext.createScriptProcessor(
			BUFF_SIZE,
			1,
			1
		);
		script_processor_node.onaudioprocess = process_microphone_buffer;

		microphone_stream.connect(script_processor_node);
	}
*/
	//  webaudio_tooling_obj = function()
}
// props
const mapStateToProps = (state) => ({

	currentChannel: state.channel.currentChannel,

});


export default connect(mapStateToProps, {
	setChannel,

})(MicrophoneTest);