// action types
import * as actionTypes from './ActionTypes';

// set messages
const setMessages = (messages) => {
	return {
		type: actionTypes.SET_MESSAGES,
		payload: messages
	};
};

// set messages
export const setAlertMessagesCount = (count) => {
	return {
		type: actionTypes.SET_ALERT_MESSAGES_COUNT,
		payload: count
	};
};

// set messages
export const setTeamMessagesCounts = (counts) => {
	console.log('settingteamcounts')
	return {
		type: actionTypes.SET_TEAMS_MESSAGES_COUNTS,
		payload: counts
	};
};

// set messages
export const setLiveMessagesCounts = (counts) => {
	return {
		type: actionTypes.SET_LIVE_CHANNEL_MESSAGES_COUNTS,
		payload: counts
	};
};


export default setMessages
