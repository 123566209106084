// app
import * as actionTypes from '../actions/ActionTypes';


// init channel state
const initCustomerState = {
	currentCustomer: null,
	currentTicketCount: 0,
	currentNoteCount: 0
};

// reducer: Customer
const customerReducer = (state = initCustomerState, action) => {
	switch (action.type) {
		case actionTypes.SET_CURRENT_CUSTOMER:
			return {
				...state,
				currentCustomer: action.payload
			};
		
		case actionTypes.SET_CURRENT_CUSTOMER_TICKET_COUNT:
			return {
				...state,
				currentTicketCount: action.payload
			};

		case actionTypes.SET_CURRENT_CUSTOMER_NOTE_COUNT:
			return {
				...state,
				currentNoteCount: action.payload
			};

		default:
			return state;
	}
};

export default customerReducer;
