// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";

// firebase
import firebase from "../../../../firebase";

// app
import LoadingAnimation from "../../utilities/loading-animation/LoadingAnimation";

import MessagesPanel from "../chat/messages/MessagesPanel";
import { setAlertMessagesCount } from "../../../store/actions/MessageAction";
import { setAlertBadgesShow } from "../../../store/actions/UserAction";
import Paper from "@material-ui/core/Paper";
// ui
import { Grid } from "semantic-ui-react";

class Alerts extends Component {
	state = {
		currentAlertRoom: null,
		alertRoomsRef: firebase.database().ref("alertRoom"),
		isAnimationLoading: false,
	};

	componentDidMount() {
		this.loadAlertRoom();
		this.clearBadgeSetShow(0);
	}

	componentWillUnmount() {
		this.clearBadgeSetShow(1);
	}

	render() {
		const { isAnimationLoading, currentAlertRoom,alertRoomsRef } = this.state;
		const { currentUser } = this.props;

		return isAnimationLoading ? (
			<LoadingAnimation />
		) : (
			<section className="sc-side-panel-list-wrapper">
				<Grid columns="equal">
					<Grid.Column>
						<Paper>
						{currentAlertRoom && (
							<MessagesPanel
								key={currentAlertRoom.id}
								currentChannel={currentAlertRoom}
								currentUser={currentUser}
								channelsRef={alertRoomsRef}
								disableMicrophone
								disableMessage
							/>
						)}
						</Paper>
					</Grid.Column>
					<Grid.Column>disabled for now</Grid.Column>
					<Grid.Column>disabled for now</Grid.Column>
				</Grid>
			</section>
		);
	}

	loadAlertRoom = () => {
		const loadedRooms = [];
		const { currentUser } = this.props;

		this.state.alertRoomsRef.once("value", (snapshots) => {
			// should only be one alertRoom!
			snapshots.forEach((snapshot, index) => {
				const tmpsnap = snapshot.val();
				if (tmpsnap.agentID === currentUser.uid) {
					loadedRooms.push(tmpsnap);
				}
			});

			if (loadedRooms.length < 2) {
				// TODO clean this
				this.setState({ currentAlertRoom: loadedRooms[0] });
			} else {
				console.log("too many alert rooms?");
				console.log(loadedRooms);
			}
		});
	};

	clearBadgeSetShow(flag) {
		this.props.setAlertBadgesShow(flag);
		this.props.setAlertMessagesCount(0);
	}
}
// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
});
export default connect(mapStateToProps, {
	setAlertMessagesCount,
	setAlertBadgesShow,
})(Alerts);
