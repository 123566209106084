// action types
import * as actionTypes from './ActionTypes';

// set channel
export const setChannel = (channel) => {
	return {
		type: actionTypes.SET_CURRENT_CHANNEL,
		payload: channel
	};
};

export const setLiveChannelList = (channels) => {
	return {
		type: actionTypes.SET_LIVE_CHANNEL_LIST,
		payload: channels
	};
};

export const setQueuedChannelList = (channels) => {
	return {
		type: actionTypes.SET_QUEUED_CHANNEL_LIST,
		payload: channels
	};
};

export const setTeamRoomList = (rooms) => {
	return {
		type: actionTypes.SET_TEAM_ROOM_LIST,
		payload: rooms
	};
};

export const setCurrentTeamsRoom = (room) => {
	return {
		type: actionTypes.SET_CURRENT_TEAMS_ROOM,
		payload: room
	};
};


