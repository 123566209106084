// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";

// firebase
import firebase from "../../../../firebase";
import formatMessageTime from "../../utilities/helpers/Date";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import TableContainer from '@material-ui/core/TableContainer';
import { setCustomerNoteCount } from "../../../store/actions/CustomerAction";
import AddNote from "./AddNote";
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import { withStyles } from "@material-ui/core/styles";
const StyledTableRow = withStyles((theme) => ({
	root: {
	  '&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	  },
	},
  }))(TableRow);
class Notes extends Component {
	state = {
		expanded: null,
		notesRef: firebase.database().ref("notes"),
		notes: [],
		
	};
	componentDidMount() {
		const { currentCustomer } = this.props;

		// add note listener
		if (currentCustomer) {
			this.addNoteListener(currentCustomer.id);
		}

		
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.currentCustomer&&prevProps.currentCustomer !== this.props.currentCustomer &&this.props.currentCustomer.id) {
			// TODO remove other note listener if I'm watchning bob vs now chris
			this.addNoteListener(this.props.currentCustomer.id);
		}
		if (this.props.noteCount==0&&prevProps.noteCount !== this.props.noteCount ){
			this.setState({notes:[]})
			this.setState({expanded:null})
		}
	  }
	  
    // TODO umount the listener
	render() {
		const { notes } = this.state;

		return (
			<div>
				<Paper style={{ maxHeight: 200, overflow: "auto" }}>
				{this.displayNotes(notes)}
				</Paper>
				<p></p>
				<div>
					<AddNote />
				</div>
			</div>
		);
	}

	/**
	 * add message listener
	 *
	 * @param channelId
	 */
	addNoteListener = (currentCustomerId) => {
		const { notesRef } = this.state;
		const loadedNotes = [];

		notesRef.child(currentCustomerId).on("child_added", (snap) => {
			if (snap.exists()) {
				loadedNotes.push(snap.val());
				this.setState({
					notes: loadedNotes,
				});
				this.props.setCustomerNoteCount(loadedNotes.length);
			}
		});
	};

	handleChange = (panel) => (event, isExpanded) => {
		this.setState({ expanded: isExpanded ? panel : false });
	};

	displayNotes = (notes) =>
		notes &&
		notes.length > 0 &&
		notes.map((note, index) => (
			<article key={"note_" + index}>
				{/* content */}
				<div>
					{/* non-continuous message */}

					{/* description */}
					{note.content && (
						<Accordion
							expanded={this.state.expanded === index}
							onChange={this.handleChange(index)}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography>{ formatMessageTime(
								note.timestamp,
								"MMM Do YYYY, h:mm:ss a"
						  )}</Typography>
							</AccordionSummary>
							<AccordionDetails><TableContainer component={Paper} style={{ 
      padding:'5px',minWidth: 320
    }}>
								
								<Table>
								<Typography><b>Note:</b> {note.content}</Typography>
								<StyledTableRow >
								<Typography><b>Agent: </b>{note.user.name}</Typography>
								</StyledTableRow>
								</Table>
								</TableContainer>
							</AccordionDetails>
						</Accordion>
					)}
				</div>
			</article>
		));


}

const mapStateToProps = (state) => ({
	currentCustomer: state.customer.currentCustomer,
	noteCount: state.customer.currentNoteCount,
});

export default connect(mapStateToProps, { setCustomerNoteCount })(Notes);
