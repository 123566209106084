// app
import * as actionTypes from '../actions/ActionTypes';


// init user state
const initUserState = {
	currentUser: null,
	status: null,
	colors: {background :{primary:"#ffc0c2",secondary:"#ffe6e9"},color_primary:"#222222",color_secondary:"#222222"},
	isAnimationLoading: true,
	alertBadgesShow:1,
	teamsBadgesShow:1,   // TODO combine these into one for all the chat badges?
	callsBadgesShow:1,
	callsScreen:{live:false,queued:false,contact:false,notes:false,tickets:false,knowledge:false,escalations:false},
};

// reducer: User
const userReducer = (state = initUserState, action) => {
	switch (action.type) {
		case actionTypes.SET_CURRENT_USER:
			return {
				...state,
				currentUser: action.payload,
				isAnimationLoading: false
			};
		case actionTypes.UPDATE_USER_AVATAR:
			return {
				...state,
				currentUser: action.payload
			};
		case actionTypes.UPDATE_USER_STATUS:
			return {
				...state,
				status: action.payload
			};
		case actionTypes.UPDATE_USER_COLORS:
			return {
				...state,
				colors: { ...state.colors, ...action.payload }
			};
		
			
		case actionTypes.CLEAR_USER:
			return {
				...state,
				currentUser: null,
				isAnimationLoading: false
			};
		case actionTypes.SET_ALERT_BADGES_SHOW:
			return {
				...state,
				alertBadgesShow: action.payload
			};
		case actionTypes.SET_TEAMS_BADGES_SHOW:
			return {
				...state,
				teamsBadgesShow: action.payload
			};
		case actionTypes.SET_CALLS_BADGES_SHOW:
			return {
				...state,
				callsBadgesShow: action.payload
			};
		case actionTypes.SET_CALLS_SCREEN:
			return {
				...state,
				callsScreen: action.payload
			};
		
		
		default:
			return state;
	}
};

export default userReducer;
