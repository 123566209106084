// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
// firebase
import firebase from "../../../../firebase";
// app

//import { Grid } from "semantic-ui-react";
import { Grid, Box } from "@material-ui/core/";
import { Doughnut, Line, Pie } from "react-chartjs-2";

import Paper from "@material-ui/core/Paper";

//REF https://github.com/reactchartjs/react-chartjs-2
//REF https://reactchartjs.github.io/react-chartjs-2/#/

const lineOptions = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};
const doughnutOptions = {
	rotation: 1 * Math.PI,
	circumference: 1 * Math.PI,
};

const backgroundColor = [
	// TODO grab this data from firebase userprefs
	"rgba(255, 99, 132, 0.2)",
	"rgba(54, 162, 235, 0.2)",
	"rgba(255, 206, 86, 0.2)",
	"rgba(75, 192, 192, 0.2)",
	"rgba(153, 102, 255, 0.2)",
	"rgba(255, 159, 64, 0.2)",
];
const borderColor = [
	// TODO grab this data from firebase userprefs
	"rgba(255, 99, 132, 1)",
	"rgba(54, 162, 235, 1)",
	"rgba(255, 206, 86, 1)",
	"rgba(75, 192, 192, 1)",
	"rgba(153, 102, 255, 1)",
	"rgba(255, 159, 64, 1)",
];

class Metrics extends Component {
	state = {
		metricsRef: firebase.database().ref("metrics"),
		//lineChartData:[],
		pieChartData: {
			labels: [],
			datasets: [
				{
					label: "--",
					data: [],
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
				},
			],
		},
		lineChartData: {
			//labels: [...Array(dataVal.length).keys()], // TODO change to dates
			labels: [...Array(1).keys()], // TODO change to dates
			datasets: [
				{
					label: "CSAT Score Last Seven Days ",
					data: [],
					fill: true,
					backgroundColor: "rgb(255, 99, 132)", // TODO pull from userprefs
					borderColor: "rgba(255, 99, 132, 0.2)",
				},
			],
		},
	};

	componentDidMount() {
		this.grabFirebaseData();
	}

	componentWillUnmount() {
		this.removeFirebaseDataListener(); //no need since just pull once on mount
	}

	render() {
		const { currentUser, userStatus, userColors } = this.props;
		const { pieChartData, lineChartData } = this.state;

		return (
			currentUser &&
			userStatus &&
			userColors && (
				<section className="sc-side-panel-list-wrapper">
					<Box>
						<Grid container spacing={3} direction="column">
							<Grid item xs={8}>
								<Paper>
									<Line
										data={lineChartData}
										options={lineOptions}
									/>
								</Paper>
							</Grid>
						</Grid>

						<p></p>
						<Grid spacing={2} container>
							<Grid xs={5} item>
								<Paper>
									<Pie data={pieChartData} />
								</Paper>
							</Grid>
							<Grid xs={6} item>
								<Grid
									container
									spacing={0}
									direction="column"
									alignItems="stretch"
								>
									<Grid item xs={5}>
										<Paper >
											<Doughnut
												data={pieChartData}
												options={doughnutOptions}
											/>
										</Paper>
									</Grid>
									<Grid item xs={5}>
										<Paper >
											<Doughnut
												data={pieChartData}
												options={doughnutOptions}
											/>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</section>
			)
		);
	}

	grabFirebaseData = () => {
		const { currentUser } = this.props;
		const { metricsRef, lineChartData, pieChartData } = this.state;
		var pieLabels = [];
		var pieData = [];
		//var lineData=[...lineChartData]
		const metricsDesired = [
			"CSAT",
			"breaks",
			"chatTime",
			"closeOut",
			"miscTime",
		];
		//var data=[]

		metricsRef.child(currentUser.uid).once("value", (snap) => {
			if (snap.exists()) {
				snap.forEach((child) => {
					if (metricsDesired.indexOf(child.key) >= 0) {
						const dataVal = child.val();

						if (child.key === "CSAT") {
							var sliced = dataVal.slice(
								dataVal.length - 7,
								dataVal.length
							);
							lineChartData["labels"] = [
								...Array(sliced.length).keys(),
							]; // TODO change to dates
							lineChartData["datasets"][0]["data"] = sliced;
						} else {
							pieData.push(dataVal[dataVal.length - 1]);
							pieLabels.push(child.key);
						}
					}
				});
				pieChartData["labels"] = pieLabels;
				pieChartData["datasets"][0]["data"] = pieData;

				this.setState({
					pieChartData: pieChartData,
					lineChartData: lineChartData,
				});
			}
		});
	};
	removeFirebaseDataListener = () => {
		const { metricsRef } = this.state;
		metricsRef.off();
	};
}

// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentChannel: state.channel.currentChannel,
	userStatus: state.user.status,
	userColors: state.user.colors,
});

export default connect(mapStateToProps)(Metrics);
