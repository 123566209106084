// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";

// app

import firebase from "../../../../firebase";

import { Grid } from "semantic-ui-react";
import i18n from "../../../../assets/i18n/i18n";
import { CirclePicker } from "react-color";

import COLORS from "../../../../assets/json/colors";
import { updateUserColors } from "../../../store/actions/UserAction";
import Paper from "@material-ui/core/Paper";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/es/Icon/Icon';
import FileUploadModal from '../../core/chat/common/FileUploadModal';
import { updateUserAvatar} from '../../../store/actions/UserAction';
import { Form } from 'semantic-ui-react'
import Avatar from '@material-ui/core/Avatar';
const scrOptions = [
	{ key: 'li', value: 'li', text: 'Light' },
	{ key: 'da', value: 'da', text: 'Dark' } 
]
class Config extends Component {
	state = {
		usersRef: firebase.database().ref('employees'),
		channelsRef: firebase.database().ref('openChannels'),
		queuedChannelsRef: firebase.database().ref('queuedChannels'),
		openMenu: null,
		value:this.props.currentUser.displayName,
		openFileModal: false
	};

	componentDidMount() {
		// add class to body
		document.body.classList.add("sc-body");

		// add resize listener
		window.addEventListener("resize", this.handleChangeOnResize);
	}

	componentWillUnmount() {
		// remove class from body
		document.body.classList.remove("sc-body");

		// remove resize listener
		window.removeEventListener("resize", this.handleChangeOnResize);
	}

	render() {
		const { currentUser, userStatus, userColors } = this.props;
		const { openMenu, openFileModal } = this.state;
		return (
			currentUser &&
			userStatus &&
			userColors && (
				<section className="sc-side-panel-list-wrapper">
					<Grid columns="equal">
						<Grid.Column width={5}>
							<Paper style={{ 
      padding:'10px'
    }}>
								<div className="sc-palette">
									<h5>
										<center>Color Config</center>
									</h5>
									<div className="sc-colors">
										<div className="sc-block">
											<h6>
												{i18n.t(
													"CHAT.SIDE_PANEL.COLOR_PANEL.BACKGROUND"
												)}
											</h6>
											<div className="sc-color">
												<CirclePicker
													colors={
														COLORS.SIDE_PANEL
															.BACKGROUND
													}
													color={
														userColors
															? userColors
																	.background
																	.primary
															: null
													}
													circleSize={16}
													onChangeComplete={
														this
															.onChangeSidePanelBackground
													}
												/>
											</div><br></br>
										</div>
										<div className="sc-block">
											<h6>
												{i18n.t(
													"CHAT.SIDE_PANEL.COLOR_PANEL.COLOR.PRIMARY"
												)}
											</h6>
											<div className="sc-color">
												<CirclePicker
													colors={
														COLORS.SIDE_PANEL.COLOR
															.PRIMARY
													}
													color={
														userColors
															? userColors.color_primary
															: null
													}
													circleSize={16}
													onChangeComplete={
														this
															.onChangeSidePanelColorPrimary
													}
												/>
											</div> <br></br>
										</div>
										<div className="sc-block">
											<h6>
												{i18n.t(
													"CHAT.SIDE_PANEL.COLOR_PANEL.COLOR.SECONDARY"
												)}
											</h6>
											<div className="sc-color">
												<CirclePicker
													colors={
														COLORS.SIDE_PANEL.COLOR
															.SECONDARY
													}
													color={
														userColors
															? userColors.color_secondary
															: null
													}
													circleSize={16}
													onChangeComplete={
														this
															.onChangeSidePanelColorSecondary
													}
												/>
											</div>
										</div>
									</div>
								</div>
							</Paper>
						</Grid.Column>
						<Grid.Column>
							<Paper style={{ 
      padding:'10px'
    }}>
								<h5>
									<center>Screen Config</center>
								</h5>
								<Form.Group inline>
          <label>Screen Size</label>
          <Form.Radio
            label='Small'
            value='sm'
            checked={false}
            onChange={this.handleChange}
          />
          <Form.Radio
            label='Medium'
            value='md'
            checked={false}
            onChange={this.handleChange}
          />
          <Form.Radio
            label='Large'
            value='lg'
            checked={false}
            onChange={this.handleChange}
          />
		  <br></br>
		  <Form.Select
            fluid
            label='Screen mode'
            options={[scrOptions]}
            placeholder='Light'
          />
        </Form.Group>
							</Paper>
						</Grid.Column>
						<Grid.Column>
							<Paper style={{ 
      padding:'10px'
    }}>
								<h5>
									<center>Profile</center>
								</h5><br></br><center>
								<Avatar alt={currentUser.displayName} src={currentUser.photoURL} style={{height:50,width:50}}/>
								<Button
										onClick={this.handleOpenFileModal}
									>
										<Icon>supervised_user_circle</Icon>
										Change Avatar
								</Button></center>
								<br></br>
								<h6><b>Name:</b> {currentUser.displayName}</h6>
								<br></br>
								<h6><b>Team(s):</b> Support, Sales</h6>
								<br></br>
								<h6><b>Birthday:</b> Oct 16</h6>
								<br></br>
								<h6><b>Email:</b> {currentUser.email}</h6>
								<br></br>
								<h6><b>Phone:</b> 352-333-0015</h6>
								<br></br>
								<form onSubmit={this.handleSubmit}>        
								<label>
								New Name: 
								<input type="text" value={this.state.value} onChange={this.handleChange} />        
								</label>
								<input type="submit" value="Submit" />
							</form>
								{
					// File Upload Modal
					openFileModal && (
						
						<FileUploadModal
							uploadPath="settings/profile/"
							openFileModal={openFileModal}
							imagePreview={currentUser.photoURL}
							handleCloseFileModal={this.handleCloseFileModal}
							prepareMediaToUpload={this.handleChangeAvatar}
							
						/>
					)
				}
							</Paper>
						</Grid.Column>
					</Grid>
				
				</section>
			)
		);
	}
	/**
	 * handle change avatar
	 *
	 * @param fileUrl
	 */
	handleChangeAvatar = (fileUrl) => {
		const { usersRef } = this.state;
		const { currentUser } = this.props;

		// upload image on storage
		firebase
			.auth()
			.currentUser
			.updateProfile({ photoURL: fileUrl })
			.then(() => {
				// updated user
				const updatedUser = firebase.auth().currentUser;

				// update on redux
				this.props.updateUserAvatar({ ...updatedUser });
			});

		// update image on database
		usersRef
			.child(currentUser.uid)
			.update({ avatar: fileUrl })
			.then();
	};

	/**
	 * handle change avatar
	 *
	 * @param fileUrl
	 */
	handleChange= (event) => {    this.setState({value: event.target.value});  }
	handleSubmit= (event) => {
		const { usersRef,value } = this.state;
		const { currentUser } = this.props;

		// upload image on storage
		firebase
			.auth()
			.currentUser
			.updateProfile({ displayName:value })
			.then(() => {
				// updated user
				const updatedUser = firebase.auth().currentUser;

				// update on redux
				this.props.updateUserAvatar({ ...updatedUser });
			});

		// update image on database
		usersRef
			.child(currentUser.uid)
			.update({ name: value })
			.then();
			event.preventDefault();
	};

	onChangeSidePanelBackground = (selectedColor) => {
		// payload
		const payload = {
			background: {
				primary: selectedColor.hex,
				secondary: this.colorLuminance(selectedColor.hex, 0.2),
			},
		};

		// update state to redux
		this.props.updateUserColors(payload);

		// update colors
		this.updateColorsToDatabase();
	};

	/**
	 * on change: side panel color primary
	 *
	 * @param color
	 */
	onChangeSidePanelColorPrimary = (color) => {
		// payload
		const payload = {
			color_primary: color.hex,
		};

		// update state to redux
		this.props.updateUserColors(payload);

		// update colors
		this.updateColorsToDatabase();
	};

	/**
	 * on change: side panel color secondary
	 *
	 * @param color
	 */
	onChangeSidePanelColorSecondary = (color) => {
		// payload
		const payload = {
			color_secondary: color.hex,
		};

		// update state to redux
		this.props.updateUserColors(payload);

		// update colors
		this.updateColorsToDatabase();
	};
/**
	 * handle close file modal
	 */
	handleCloseFileModal = () => {
		this.setState({ openFileModal: false });
	};


/**
	 * handle open file modal
	 */
	handleOpenFileModal = () => {
		this.setState({ openFileModal: true });
	};
	/**
	 * update colors to database
	 */
	updateColorsToDatabase = () => {
		const { usersRef } = this.state;
		const { currentUser, userColors } = this.props;

		// update colors on users database
		usersRef.child(currentUser.uid).update({ colors: userColors }).then();
	};
	colorLuminance = (hex, lum) => {
		const hexValue = hex.replace("#", "");
		let rgb = "#";
		let c;
		let i;

		// convert to decimal and change luminosity
		for (i = 0; i < 3; i += 1) {
			c = parseInt(hexValue.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(
				16
			);
			rgb += `00${c}`.substr(c.length);
		}

		return rgb;
	};
}

// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentChannel: state.channel.currentChannel,
	userStatus: state.user.status,
	userColors: state.user.colors,
});

export default connect(mapStateToProps, {updateUserAvatar, updateUserColors })(Config);
