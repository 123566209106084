// react
import React, { Component } from "react";
// redux
import { connect } from "react-redux";
import {
	setTeamMessagesCounts,
	setAlertMessagesCount,
	setLiveMessagesCounts,
} from "../../../../store/actions/MessageAction";
import { setTeamRoomList,setLiveChannelList, } from "../../../../store/actions/ChannelAction";

// firebase
import firebase from "../../../../../firebase";

// app
import classNames from "classnames/bind";
import UserPanel from "./UserPanel";

//list support
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Drawer } from '@material-ui/core';
import { Link } from "react-router-dom";
import {version} from '../../../../../../package.json';
// icons
import HomeIcon from "@material-ui/icons/Home";
import GroupIcon from "@material-ui/icons/Group";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import Badge from "@material-ui/core/Badge";
//import {Logger} from "react-remote-logger";

import callLogger from "../../../utilities/helpers/Logger"
////////////////////////
var jsonn=0

function formatData(eventInfo,level,msg,method){
    var out = {
        "timestamp": Date.now(),
        "level": level,
        "message": msg,
        "context": {
            "switchBoard": {
                "agentData":jsonn,
                "os":getOS(),
                
                "browser": getUserAgent()
            }
        },
        "event": eventInfo        
    };
    return out
}
function getUserAgent() {
    var i = navigator.userAgent,t,n = i.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    return /trident/i.test(n[1]) ?
        ((t = /\brv[ :]+(\d+)/g.exec(i) || []), "IE " + (t[1] || "")) :
        n[1] === "Chrome" && ((t = i.match(/\b(OPR|Edge)\/(\d+)/)), t != null) ?
            t.slice(1).join(" ").replace("OPR", "Opera") :
            ((n = n[2] ? [n[1], n[2]] : [navigator.appName, navigator.appVersion, "-?"]),
            (t = i.match(/version\/(\d+)/i)) != null && n.splice(1, 1, t[1]),
            n.join(" "))
    ;
}

function getOS() {
    var n = "Unknown OS";
    return (
        navigator.appVersion.indexOf("Win") != -1 && (n = "Windows"),
        navigator.appVersion.indexOf("Mac") != -1 && (n = "MacOS"),
        navigator.appVersion.indexOf("iPad") != -1 && (n = "iPad"),
        navigator.appVersion.indexOf("X11") != -1 && (n = "UNIX"),
        navigator.appVersion.indexOf("Linux") != -1 && (n = "Linux"),
        n
    );
}

class SidePanel extends Component {
	state = {
		messagesRef: firebase.database().ref("messages"),
		teamRoomsRef: firebase.database().ref("teamRooms"),
		openchannelRef: firebase.database().ref("openChannels"),
		channelsRef: firebase.database().ref("openChannels"),
		debugLevel:0,
		selected: null,
	};

	componentDidMount() {
		
		this.interval = setInterval(
			() => {
				this.setState({ time: Date.now() });
				//this.addChannelListener();  // ! this is ugly change this so that i'm pinging for a new open channel.....perhaps run the open listening in each list-item click
				/*
					if (this.props.liveChannelList){
						
						this.props.setLiveMessagesCounts(
							this.props.liveChannelList.length // this hack won't work after two agents are online..... since the list could be big, need to look at how to filter base on userid like in addchannellistener
						);
					}
				*/	
				if (this.state.debugLevel>3){
				console.log(this.props.liveChannelList)
				console.log(this.props.currentChannel)
				console.log('3s-side-panel');
				}
			},3000
		); // ! don't hardcode this shalom!!!!
		
		
		this.openChannelListener();
		this.alertsListener();
		this.loadTeamRooms();
		console.log("mounted sidepanel");
		var eventInfo= {
			"info": {
				"name": 'Agent connected',
				"message": 'Initial information about agent'
			}
		}
		/*
		fetch('https://api.ipify.org?format=jsonp&callback=?').then(response =>{
		 	return response}).then(data=>{
				jsonn=JSON.stringify(data);  
				var dataOut=formatData(eventInfo,"info","agent connected","sidePanelInit")
				
				callLogger(dataOut);
				
			 	}).bind(this)
		*/
		async function getIp(){
			try{
				const response = await fetch('https://ipapi.co/json/')
				let data = await response.json()
				jsonn=JSON.stringify(data);  
				var dataOut=formatData(eventInfo,"info","agent connected","sidePanelInit")
				
				callLogger(dataOut);
			} catch(err){
					console.log(err.message)
			}
		}
		getIp()

		
		/*
		Logger({
			'timestamp': (new Date()).toLocaleString(),
			'level': 'info',
			'message': 'this is a log from a client'
		});
		*/
	}

	componentWillUnmount() {
		console.log('UNmounted side panel!')
		clearInterval(this.interval);
		this.removeAlertsListener();
		this.removeOpenChannelListener();
	}

	render() {
		const {
			currentUser,
			userStatus,
			isDesktopView,
			userColors,
			alertCount,
			teamsCounts,
			liveMessageCounts,
			teamsBadge,
			alertBadge,
			callsBadge,
		} = this.props;
		const { selected } = this.state;
		const userPanelStyle = {
			backgroundColor: userColors.background.secondary,
			//width: 60,
		};
		const sidePanelClass = classNames({
			"cd-col sc-side-panel": false,
			"sc-view-fixed": isDesktopView,
		});

		return (
			<section className="sc-side-panel-wrapper">
				{/* Content */}
				
				<div className={sidePanelClass} style={userPanelStyle}>
				<Drawer  variant="permanent">
					<UserPanel
						currentUser={currentUser}
						userStatus={userStatus}
						userColors={userColors}
					/>

					<div className="sc-content">
						
							<List disablePadding>
								<ListItem
									key="msg"
									component={Link}
									onClick={() =>
										this.setState({ selected: "home" })
									}
									selected={selected === "home"}
									to="/msg"
								>
									<ListItemIcon style={{ minWidth: "35px" }}>
										<HomeIcon />
									</ListItemIcon>
									<ListItemText
										classes={{ primary: "s" }}
										primary="Home"
									/>
								</ListItem>
								<ListItem
									key="calls"
									onClick={() =>
										this.setState({ selected: "calls" })
									}
									selected={selected === "calls"}
									component={Link}
									to="/calls"
								>
									<ListItemIcon style={{ minWidth: "35px" }}>
										<Badge
											badgeContent={
												callsBadge
													? liveMessageCounts
													: null
											}
											color="error"
											overrllap="rectangle"
										>
											<PermPhoneMsgIcon />
										</Badge>
									</ListItemIcon>
									<ListItemText
										classes={{ primary: "s" }}
										primary={currentUser.type===0?"Calls!":"Queues!"}
									/>
								</ListItem>

								<ListItem
									key="teams"
									component={Link}
									onClick={() =>
										this.setState({ selected: "teams" })
									}
									selected={selected === "teams"}
									to="/teams"
								>
									<ListItemIcon style={{ minWidth: "35px" }}>
										<Badge
											badgeContent={
												teamsBadge ? teamsCounts : null
											}
											color="error"
											overrllap="rectangle"
										>
											<GroupIcon />
										</Badge>
									</ListItemIcon>
									<ListItemText
										classes={{ primary: "s" }}
										primary="Teams"
									/>
								</ListItem>
								<ListItem
									key="alerts"
									onClick={() =>
										this.setState({ selected: "alerts" })
									}
									selected={selected === "alerts"}
									component={Link}
									to="/alerts"
								>
									<ListItemIcon style={{ minWidth: "35px" }}>
										{" "}
										{/* //TODO make this in the CSS stuff !*/}
										<Badge
											badgeContent={
												alertBadge ? alertCount : null
											}
											color="error"
											overrllap="rectangle"
										>
											<NotificationsActiveIcon />
										</Badge>
									</ListItemIcon>
									<ListItemText
										classes={{ primary: "s" }}
										primary="Alerts"
									/>
								</ListItem>
								<ListItem
									key="metrics"
									onClick={() =>
										this.setState({ selected: "metrics" })
									}
									selected={selected === "metrics"}
									component={Link}
									to="/metrics"
								>
									<ListItemIcon style={{ minWidth: "35px" }}>
										<AssessmentIcon />
									</ListItemIcon>
									<ListItemText
										classes={{ primary: "s" }}
										primary="Metrics"
									/>
								</ListItem>
								<ListItem
									key="config"
									onClick={() =>
										this.setState({ selected: "config" })
									}
									selected={selected === "config"}
									component={Link}
									to="/config"
								>
									<ListItemIcon style={{ minWidth: "35px" }}>
										<SettingsIcon />
									</ListItemIcon>
									<ListItemText
										classes={{ primary: "s" }}
										primary="Config"
									/>
								</ListItem>
							</List>
							
							
					</div>
					<div style={{	position: "fixed",
						bottom: 0,
						paddingLeft:10,
						textAlign: "center",
						paddingBottom: 10}}>
    							
								<center >=Revision=</center>
								<center style={{ fontSize: 9 }}>{version}</center>
					</div>
					</Drawer>
					
					
				</div>
				
			</section>
		);
	}
	handleClick = (event) => {
		// stop default event
		event.preventDefault();
	};
	loadTeamRooms = () => {
		const { currentUser } = this.props;
		const loadedRooms = [];

		this.state.teamRoomsRef.once("value", (snapshots) => {
			// push channels
			snapshots.forEach((snapshot, index) => {
				const tmpsnap = snapshot.val();

				if (tmpsnap.members.includes(currentUser.uid)) {
					loadedRooms.push(tmpsnap);
					this.teamRoomsListener(tmpsnap);
				}
			});
			// TODO if only one channel just load it into panel

			this.props.setTeamRoomList(loadedRooms);
		});
	};
	addChannelListener = () => { // ! this was a quick fix to get badges into sidepanel, revisit hack!
		var loadedChannels = [];
		var tmpsnap = [];
		const { callsBadge } = this.props;
		const userRef = firebase.auth().currentUser.uid; // Shalom!! hack
		this.state.channelsRef.on("child_added", (snap) => {
			// push channels
			//console.log('channel-added')
			tmpsnap = snap.val(); // Shalom!! hack
			//console.log(firebase.auth().currentUser.uid);// Shalom!! hack
			if (tmpsnap.agentID === userRef ) {
				// ! Shalom!! hack
				if (this.props.liveChannelList){
					loadedChannels = this.props.liveChannelList.filter(
						(user) => user.id !== tmpsnap.id
					);
				}

				//console.log(loadedChannels)
				loadedChannels.push(snap.val());
				this.props.setLiveChannelList(loadedChannels);
				if (callsBadge) {
					
					this.props.setLiveMessagesCounts(
						loadedChannels.length
					);
				}
				//this.setFirstChannel()
			}
			//this.forceUpdate();

			// set to channels
			// set first channel

			/*
			this.setState({ liveChannelList: loadedChannels }, () =>
				this.setFirstChannel()
			);
			*/
			//console.log('chanenls'+this.state.channels)
		});
	};
	openChannelListener = () => {
		// TODO What happens if there are 1000 open channels? can this be better written?
		// TODO yes just listen on new opened channels for agent
		const { currentUser, callsBadge } = this.props;
		const { openchannelRef } = this.state;
		openchannelRef.on("child_changed", (snap) => {
			const tmpsnap = snap.val();
			console.log('detected added channel in side panel')
			if (tmpsnap.agentID === currentUser.uid && tmpsnap.active === 1) {
				
				if (callsBadge) {
					
					this.props.setLiveMessagesCounts(
						this.props.liveMessageCounts + 1
					);
				}
			}
		});
	};
	alertsListener = () => {
		const { currentUser, alertBadge } = this.props;
		const { messagesRef } = this.state;
		messagesRef.child(currentUser.uid).on("child_changed", (snap) => {
			if (alertBadge) {
				this.props.setAlertMessagesCount(
					this.props.liveMessageCounts + 1
				);
			}
		});
	};

	teamRoomsListener = (room) => {
		const { teamRoomsRef } = this.state;
		const { teamsBadge } = this.props;

		teamRoomsRef.child(room.id).on("child_changed", (snap) => {
			if (teamsBadge && snap.val().timestamp) {
				this.props.setTeamMessagesCounts(this.props.teamsCounts + 1);
			}
		});
	};
	removeAlertsListener = () => {
		const { currentUser } = this.props;
		this.state.messagesRef.child(currentUser.uid).off("child_changed");
	};
	removeOpenChannelListener = () => {
		this.state.openchannelRef.off("child_changed");
	};
	
}
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentChannel: state.channel.currentChannel,
	liveChannelList: state.channel.currentLiveChannelList,
	userStatus: state.user.status,
	userColors: state.user.colors,
	alertCount: state.message.alertCount,
	teamsCounts: state.message.currentTeamsCounts,
	liveMessageCounts: state.message.currentLiveMessageCounts,
	teamsBadge: state.user.teamsBadgesShow,
	alertBadge: state.user.alertBadgesShow,
	callsBadge: state.user.callsBadgesShow,
});
export default connect(mapStateToProps, {
	setTeamMessagesCounts,
	setLiveChannelList,
	setAlertMessagesCount,
	setLiveMessagesCounts,
	setTeamRoomList,
})(SidePanel);
