// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import { setCustomerTicketCount } from "../../../store/actions/CustomerAction";

// firebase
import firebase from "../../../../firebase";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Paper} from "@material-ui/core";
import formatMessageTime from "../../utilities/helpers/Date";
import { withStyles } from "@material-ui/core/styles";
import AddTicket from "./AddTicket";
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';

const StyledTableRow = withStyles((theme) => ({
	root: {
	  '&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	  },
	},
  }))(TableRow);
class Tickets extends Component {
	state = {
		expanded: null,
		ticketsRef: firebase.database().ref("tickets"),
		tickets: [],
		
	};

	componentDidMount() {
		const { currentCustomer } = this.props;

		// add ticket listener
		if (currentCustomer) {
			this.addTicketListener(currentCustomer.id);
		}

	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.currentCustomer&&prevProps.currentCustomer !== this.props.currentCustomer &&this.props.currentCustomer.id) {
			// TODO remove other note listener if I'm watchning bob vs now chris
			this.addTicketListener(this.props.currentCustomer.id);
		}
		if (this.props.ticketCount === 0 && prevProps.ticketCount !== this.props.ticketCount ){
			this.setState({tickets:[]})
			this.setState({expanded:null})
		}
	
	  }
	  componentWillUnmount() {
		this.state.ticketsRef.off()
	 } 
    // TODO umount the listener
	render() {
		const {
			tickets,
			
		} = this.state;
		return (
			<div>
				<Paper style={{ maxHeight: 250, overflow: "auto" }}>
				{this.displayTickets(tickets)}
				</Paper>
				<p></p>
				<div>
					<AddTicket/>
				</div>
			</div>
		);
	}

	/**
	 * add message listener
	 *
	 * @param channelId
	 */
	addTicketListener = (currentCustomerId) => {
		const { ticketsRef } = this.state;
		const loadedTickets = [];
		//console.log(ticketsRef)
		ticketsRef.child(currentCustomerId).on("child_added", (snap) => {
			if (snap.exists()) {
				loadedTickets.push(snap.val());
				this.setState({
					tickets: loadedTickets,
				});
				this.props.setCustomerTicketCount(loadedTickets.length);
			}
		});
	};

	handleChange = (panel) => (event, isExpanded) => {
		this.setState({ expanded: isExpanded ? panel : false });
	};

	displayTickets = (tickets) =>
		tickets &&
		tickets.length > 0 &&
		tickets.map((ticket, index) => (
			<article key={"ticket_" + index}>
				{/* content */}
				<div>
					{/* non-continuous message */}

					{/* description */}
					{ticket.body && (
						<Accordion
							expanded={this.state.expanded === index}
							onChange={this.handleChange(index)}
							
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
								style={{maxHeight:10}}
							>
								<Typography>{ formatMessageTime(
								ticket.timestamp,
								"MMM Do YYYY, h:mm:ss a"
						  )}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TableContainer component={Paper} style={{ 
      padding:'5px',minWidth: 320
    }}>
								<Table>
								<StyledTableRow >
								<Typography><b>Subject: </b>{ticket.subject}</Typography>
								</StyledTableRow>
								<StyledTableRow >
								<Typography><b>Status: </b>closed</Typography>
								</StyledTableRow>
								<StyledTableRow >
								<Typography><b>Priority: </b>{ticket.priority}</Typography>
								</StyledTableRow>
								<StyledTableRow >
								<Typography><b>Assigned Group: </b>{ticket.assign}</Typography>
								</StyledTableRow>
								<StyledTableRow >
								<Typography><b>About: </b>{ticket.body}</Typography>
								</StyledTableRow>
								<StyledTableRow >
								<Typography><b>Confirmed: </b>{ticket.confirmed}</Typography>
								</StyledTableRow>
								<StyledTableRow >
								<Typography><b>Agent: </b>{ticket.user.name}</Typography>
								</StyledTableRow>
								</Table>
								</TableContainer>
							</AccordionDetails>
						</Accordion>
					)}
				</div>
			</article>
		));

	
}

const mapStateToProps = (state) => ({
	currentCustomer: state.customer.currentCustomer,
	ticketCount: state.customer.currentTicketCount,
});
export default connect(mapStateToProps, { setCustomerTicketCount })(Tickets);
