// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import { setCustomer } from "../../../store/actions/CustomerAction";

import firebase from "../../../../firebase";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { Form } from "semantic-ui-react";
class AddContact extends Component {
	state = {
		email: "",
		name: "",
		phone: "",
		customersRef: firebase.database().ref("endCustomers"),
		saved: true,
		error: [],
	};

	render() {
		// TODO add the currentEndcuster so that it doesn't render tickets of unknown endusers
		return (
			<Form size='mini'
				style={{
				/*	width: "400px",*/
					height: "210px",
					padding: "10px",
				}}
			>
				<Form.TextArea
					label="Email Address"
					
					onChange={(e) => this.setState({ email: e.target.value })}
				/>

				<Form.TextArea
					label="Name"
					
					onChange={(e) => this.setState({ name: e.target.value })}
				/>

				<Form.TextArea inline size='mini'
					label="Phone"
					
					onChange={(e) => this.setState({ phone: e.target.value })}
				/>

				<div>
					<Button
						variant="contained"
						color="primary"
						size="small"
						startIcon={
							<SaveIcon onClick={() => this.submitFireBase()} />
						}
					>
						Save
					</Button>
				</div>
			</Form>
		);
	}
	submitFireBase = () => {
		const { customersRef, email, name, phone } = this.state;
		if (email && name && phone) {
			customersRef
				.push()
				.set(this.createContact(customersRef.key)) // TODO Check the quality of the contact
				.then(() => {
					// empty errors
					this.setState({ errors: [] });
				})
				.catch((error) => {
					this.setState({ errors: [error] });
				});
		}
	};
	/**
	 * handle input change event
	 *
	 * @param event
	 */
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	createContact = (key) => {
		// TODO fix this key, it's 'endcustomers' instead of new key generate do the push().then(snapshop etc)
		const { currentChannel } = this.props;
		const { email, name, phone } = this.state;

		const data = {
			avatar: currentChannel.avatar,
			id: key,
			phone: phone,
			name: name,
			email: email,
			channelHistory: {
				0: currentChannel.id,
			},
			endUserHistory: {
				0: currentChannel.enduserID,
			},
			ipHistory: {
				0: currentChannel.details,
			},
		};
		this.props.setCustomer(data);
		return data;
	};
}
const mapStateToProps = (state) => ({
	currentChannel: state.channel.currentChannel,
	currentCustomer: state.customer.currentCustomer,
});
export default connect(mapStateToProps, { setCustomer })(AddContact);
