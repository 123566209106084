// react
import React, { Component } from 'react';

// firebase
import firebase from '../../../../../firebase';
// redux
import { connect } from "react-redux";
// app
import classNames from 'classnames/bind';
import formatMessageTime from '../../../utilities/helpers/Date';
import Icon from '@material-ui/core/es/Icon/Icon';
import Modal from '@material-ui/core/es/Modal/Modal';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Popup from 'reactjs-popup';
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import { SET_CURRENT_CHANNEL } from '../../../../store/actions/ActionTypes';


class MessageContent extends Component {
	state = {
		usersRef: firebase.database().ref('employees'),
		endUserRef:firebase.database().ref('endUsers'),
		isImageZoom: false,
		isImageLoaded: false
	};
    
	componentDidMount() {
		this.handleMount();
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (this.props.currentCustomer&&prevProps.currentCustomer !== this.props.currentCustomer &&this.props.currentCustomer.id) {
			this.handleMount();
		}
	  }
	  
	componentWillUnmount() {
		// don't need unmount since the listeners only listen once
		//const { usersRef } = this.state;
		//const { message } = this.props;

		// remove listener
		//const userId = message.user.id;
		//usersRef.child(userId).off();
	}

	render() {
		const { isImageZoom, isImageLoaded,userAvatar } = this.state;
		const { message, currentUser, isMessageOnSameDay, isMessageOnSameDayBySameUser, isLastMessage ,index} = this.props;

		const messageContentClass = classNames({
			'sc-message-content': true,
			'sc-different': !isMessageOnSameDayBySameUser,
			'sc-continuous': isMessageOnSameDayBySameUser,
			'last-message': isLastMessage
		});

		const timeLineClass = classNames({
			'sc-time-one': true,
			'sc-border-dark': !isMessageOnSameDay
		});

		const selfMessageClass = classNames({
			'sc-name': true,
			'sc-self': this.isMessageByCurrentUser(message, currentUser) && !isMessageOnSameDayBySameUser
		});

		const imageLoaderClass = classNames({
			'sc-loader-wrapper': true,
			'sc-image-loading': !isImageLoaded
		});

		const imageLoadedClass = classNames({
			'sc-image': true,
			'sc-image-loading': !isImageLoaded
		});

		const avatarLoadedClass = classNames({
			'sc-content': true,
			'sc-avatar-loaded': userAvatar
		});
		const contentStyle = { background: '#FFF' };
		//const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
		//const arrowStyle = { color: '#000' }; // style for an svg element
		return (
			<article className={messageContentClass}>
				{/* message time */}
				{
					!isMessageOnSameDayBySameUser && (
						<div className={timeLineClass}>
							{!isMessageOnSameDay && (
								<span>
									{formatMessageTime(message.timestamp, 'dddd, MMMM Do')}
								</span>
							)}
						</div>
					)
				}
				
				{/* avatar */}
				
				{console.log('msg#:'+index)}
				{/*console.log(userAvatar)*/}
				{/* content */}
				<div className={avatarLoadedClass}>
				<Box display="flex" justifyContent={message.user.id===this.props.currentUser.uid? "flex-end":"flex-start" }>
					{/* non-continuous message */}
					{!isMessageOnSameDayBySameUser && this.nonContinuousMessage(message, selfMessageClass)}

					{/* continuous message */}
					{isMessageOnSameDayBySameUser && this.continuousMessage(message.timestamp)}
					</Box>
					{/* description */}
					{
						message.content && (
							<Box display="flex" justifyContent={message.user.id===this.props.currentUser.uid? "flex-end":"flex-start"} >
							{message.user.id!==this.props.currentUser.uid && (				
					<Avatar alt="user-avatar" src={userAvatar}/>
							)}
							<Paper elevation={3} ><p					
								dangerouslySetInnerHTML={message.user.id===this.props.currentUser.uid? { __html: message.content }:{ __html: '<p style=color:'+'blue;'+'>'+message.content+'</p>' }}
							/>
							
							</Paper>
							{message.user.id!==this.props.currentUser.uid && typeof message.ai_answer !=="undefined"&& (				
					 
					 <Popup trigger={<button style={{ borderWidth:1,
						borderColor:'rgba(0,0,0,0.2)',
						alignItems:'center',
						justifyContent:'center',
						width:40,
						height:40,
						backgroundColor:'#fff',
						borderRadius:100,
					  }}>      <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/HAL9000.svg/1200px-HAL9000.svg.png" width="25" alt="folder"/>
					 </button>}
					 
					 {...{ contentStyle }}
					 position="right center">
    {message.ai_answer}
  </Popup>					
				 )}
							{message.user.id===this.props.currentUser.uid && (				
					<Avatar alt="user-avatar" src={userAvatar}/>
					 						
				)}
				
							</Box>
						)
					}

					{/* image loader */}
					{
						message.image && !isImageLoaded && (
							<div className={imageLoaderClass}>
								<div className="sc-loader"/>
							</div>
						)
					}

					{/* image */}
					{
						message.image && (
							<button className={imageLoadedClass} type="button" onClick={this.handleShowImageZoom}>
								<img
									src={message.image}
									onLoad={this.handleImageLoaded}
									alt={`file-${message.timestamp}`}/>
							</button>

						)
					}

					{/* image - full screen */}
					{isImageZoom && this.displayImageFullscreen(message.image, `file-${message.timestamp}`)}
						
				</div>
				
			</article>
		);
	}

	/**
	 * handle image loaded
	 */
	handleImageLoaded = () => {
		this.setState({ isImageLoaded: true });
	};

	/**
	 * detect if the message is from current user
	 *
	 * @param message
	 * @param currentUser
	 * @returns {string}
	 */
	isMessageByCurrentUser = (message, currentUser) => {
		return message.user.id === currentUser.uid;
	};

	/**
	 * not a continuous message
	 *
	 * @param message
	 * @param selfMessageClass
	 * @returns {*}
	 */ 
	nonContinuousMessage = (message, selfMessageClass) => (
		<h6 className={selfMessageClass}>
			{this.props.currentChannel&&message.user.name===this.props.currentChannel.details?this.props.currentChannel.name:message.user.name}
			<span className="sc-time cd-tooltip">
				{formatMessageTime(message.timestamp, 'LT')}
				<span className="cd-arrow cd-top cd-fixed-left">
					{formatMessageTime(message.timestamp, 'llll')}
				</span>
			</span>
		</h6>
	);

	/**
	 * continuous message
	 *
	 * @param timestamp
	 * @returns {*}
	 */
	continuousMessage = timestamp => (
		<p className="sc-time-two cd-tooltip">
			{formatMessageTime(timestamp, 'LT')}
			<span className="cd-arrow cd-top cd-fixed-left">
				{formatMessageTime(timestamp, 'llll')}
			</span>
		</p>
	);

	/**
	 * handle show image in large size
	 */
	handleShowImageZoom = () => {
		this.setState({ isImageZoom: true });
	};

	/**
	 * handle close image in large size
	 */
	handleCloseImageZoom = () => {
		this.setState({ isImageZoom: false });
	};

	/**
	 * display image full size
	 *
	 * @param src
	 * @param alt
	 * @returns {*}
	 */
	displayImageFullscreen = (src, alt) => (
		<Modal open={this.state.isImageZoom}>
			<section className="sc-modal-wrapper sc-preview-image-modal">
				<img src={src} alt={alt} className="cd-vh-center"/>
				<Icon
					className="sc-close-float sc-fixed sc-spacing-large bg-size-small bg-dark"
					onClick={this.handleCloseImageZoom}>
					close
				</Icon>
			</section>
		</Modal>
	);
	handleMount=()=>{ // this was to fix the avatar? causing a problem with scrolling!!
		const { usersRef,endUserRef } = this.state;
		const { message, isMessageOnSameDayBySameUser } = this.props;
		console.log('mounted: messagecontent')
		// fetch user avatar from firebase
		const userId = message.user.id;
		
		if (userId && !isMessageOnSameDayBySameUser) {
			usersRef
				.child(`${userId}`)
				.once('value', (snap) => {
					if (snap.exists()) {
						this.setState((state)=>({
							userAvatar: snap.val().avatar
						}));
					}else {
						endUserRef
						.child(`${userId}`)
						.once('value', (snap) => {
							if (snap.exists()) {
								this.setState((state)=>({
									userAvatar: snap.val().avatar
								}));
							}

							}
						)}
				});
		}
		
	}
}
const mapStateToProps = (state) => ({
	currentCustomer: state.customer.currentCustomer,
	
});
export default connect(mapStateToProps)(MessageContent);


