// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
// firebase
import firebase from "../../../../firebase";
import TextField from "@material-ui/core/TextField";

import SaveIcon from "@material-ui/icons/Save";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Popover from "@material-ui/core/Popover";
import { Form,Button } from "semantic-ui-react";
class AddNote extends Component {
	state = {
		note: "",
		notesRef: firebase.database().ref("notes"),
		error: [],
		openNewNotePopover: null,
		
	};
	render() {
		const {note, openNewNotePopover} = this.state;
		return (
			<div>
				<span onClick={this.handleNewNotePopover} role="presentation">
					{this.props.currentCustomer && (
						<Fab size="small" color="primary" aria-label="add">
							<AddIcon />
						</Fab>
					)}
				</span>

				<Popover
					className="sc-channel-info-popover"
					anchorEl={openNewNotePopover}
					open={Boolean(openNewNotePopover)}
					onClose={this.handleCloseNewNotePopover}
				>
					<Form
						style={{
							width: "400px",
							height: "210px",
							padding: "10px",
						}}
					>
					
						<Form.TextArea
							label="Note"
							placeholder="Please add note..."
							onChange={(e) => this.setState({note: e.target.value})}
						/>
						<Form.Checkbox label="Confirmed Credentials" />
						<Button onClick={this.submitFireBase}>Submit</Button>
					</Form>
					
				</Popover>
			</div>
		);
	}
	submitFireBase = () => {
		const { currentCustomer } = this.props;
		const { notesRef } = this.state;
		
		if (currentCustomer) {
			notesRef
				.child(currentCustomer.id)
				.push()
				.set(this.createNote()) // TODO Check the quality of this note
				.then(() => {
					// empty errors
					this.setState({ errors: [] });
				})
				.catch((error) => {
					this.setState({ errors: [error] });
				});
				this.setState({openNewNotePopover:false})
		}
	};
	/**
	 * handle input change event
	 *
	 * @param event
	 */
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	createNote = (newNote) => {
		const { currentUser } = this.props;
		const{note}=this.state;
		//console.log(currentUser.uid)
		const data = {
			timestamp: firebase.database.ServerValue.TIMESTAMP,
			content: note,
			user: {
				id: currentUser.uid,
				name: currentUser.displayName,
				email: currentUser.email,
			},
		};

		return data;
	};

		/**
	 * handle new note popover
	 *
	 * @param event
	 */
	handleNewNotePopover = (event) => {
		this.setState({ openNewNotePopover: event.currentTarget });
	};

	/**
	 * handle close channel info popover
	 */
	handleCloseNewNotePopover = () => {
		this.setState({ openNewNotePopover: null });
	};
}
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentCustomer: state.customer.currentCustomer,
});
export default connect(mapStateToProps)(AddNote);
