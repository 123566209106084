// react
import React, { Component } from "react";

// firebase
import firebase from "../../../../firebase";

// redux
import { connect } from "react-redux";
import { setTeamMessagesCounts } from "../../../store/actions/MessageAction";
import {setTeamsBadgesShow } from "../../../store/actions/UserAction";
import {setCurrentTeamsRoom} from "../../../store/actions/ChannelAction"
// app
import MessagesPanel from "../chat/messages/MessagesPanel";
import Widgets from "../widgets/Widgets";
// UI
import Button from "@material-ui/core/Button";
import LoadingAnimation from "../../utilities/loading-animation/LoadingAnimation";
import { Grid } from "semantic-ui-react";
import Paper from "@material-ui/core/Paper";
import Avatar from '@material-ui/core/Avatar';
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
class Teams extends Component {
	state = {
		teamRoomsRef: firebase.database().ref("teamRooms"),
		isAnimationLoading: false,
		isFirstLoad: true,
	};

	componentDidMount() {
				
		this.clearBadgeSetShow(0);
		//
		//clear badge & set show for teams off might be a problem in 2+ teams?
	}

	componentWillUnmount() {
		this.clearBadgeSetShow(1);
	}

	render() {
		const { isAnimationLoading,teamRoomsRef } = this.state;
		const { currentUser, userColors,rooms, currentTeamRoom,currentChannel} = this.props;

		const channelTitleStyle = {
			color: userColors.color_primary,
			borderColor: userColors.background.primary,
		};

		return isAnimationLoading ? (
			<LoadingAnimation />
		) : (
			<section className="sc-side-panel-list-wrapper">
				<Grid >
					
					<Grid.Column width={4} >
						<Paper style={{ 
      padding:'10px'
    }}>
						{/* Title */}
						
							<Badge
									color="primary"
									badgeContent={rooms.length}
								>
									<Typography>
									<h6>
								Teams&nbsp;&nbsp;
								</h6>
								</Typography>
								</Badge>
							
						{/* Room */}
						<ul className="cd-remove-bullets sc-list">
							{this.displayRooms(rooms, userColors)}
						</ul>
						</Paper>
					</Grid.Column>
					
					<Grid.Column width={6}>
						{currentTeamRoom && (
							<MessagesPanel
								key={currentTeamRoom.id}
								currentChannel={currentTeamRoom}
								currentUser={currentUser}
								channelsRef={teamRoomsRef}
								disableMicrophone
							/>
						)}
					</Grid.Column>
					<Grid.Column width={4}>
						{currentChannel && <Widgets />}
					</Grid.Column>
				</Grid>
			</section>
		);
	}
	
	displayRooms = (rooms, userColors) =>
		rooms &&
		rooms.length > 0 &&
		rooms.map((room) => (
			<li
				key={room.id}
				name={room.name}
				className={
					this.props.currentTeamRoom &&
					this.props.currentTeamRoom.id === room.id
						? "sc-item sc-active"
						: "sc-item"
				}
				style={{
					backgroundColor:
						this.props.currentTeamRoom &&
						this.props.currentTeamRoom.id === room.id
							? userColors.background.primary
							: null,
				}}
			>
				<Button
					style={{ color: userColors.color_secondary }}
					variant="contained"
					type="button"
					onClick={() => this.changeRoom(room)}
					fullWidth
				>
					<Avatar alt="Remy Sharp" src={room.avatar} />&nbsp; {room.name}
				</Button>
			</li>
		));

	changeRoom = (room) => {
		// set current channel
		//this.setState({ currentTeamRoom: room }); // TODO turn this into a redux/action
 		this.props.setCurrentTeamsRoom(room); 

	};

	clearBadgeSetShow(flag) {		
		this.props.setTeamsBadgesShow(flag)
		this.props.setTeamMessagesCounts(0);
	}

}
// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	userStatus: state.user.status,
	userColors: state.user.colors,
	rooms: state.channel.currentTeamRoomList,
	currentTeamRoom:state.channel.currentTeamsRoom,
	currentChannel: state.channel.currentChannel,
});
export default connect(mapStateToProps, { setTeamMessagesCounts,setTeamsBadgesShow,setCurrentTeamsRoom  })(Teams);
