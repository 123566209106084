// react
import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";

// redux
import { connect } from "react-redux";

// firebase
import firebase from "../firebase";

// app
import Login from "./system/core/auth/Login";
import Register from "./system/core/auth/Register";
import Home from "./system/frame/home/Home";
import Msg from "./system/core/messageofday/MessageOfDay";
import Channels from "./system/core/chat/side/Channels";
import Alerts from "./system/core/alerts/Alerts";
import Metrics from "./system/core/metrics/Metrics";
import ManagerMetrics from "./system/core/metrics/ManagerMetrics";
import ENV from "../environment/index";
import Teams from "./system/core/teams/Teams";
import Config from "./system/core/config/Config";
import {
	setUser,
	updateUserStatus,
	updateUserColors,
	clearUser,
} from "./store/actions/UserAction";
import LoadingAnimation from "./system/utilities/loading-animation/LoadingAnimation";
import SidePanel from "./system/core/chat/side/SidePanel";
import { Grid } from "semantic-ui-react";
import Queues from "./system/core/queues/Queues";
class AppRouter extends Component {
	state = {
		usersRef: firebase.database().ref("employees"),
	};
	componentDidMount() {
		// redirect to the chat route on user logged-in state
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				if (user.displayName) {
					// real-time database user
					firebase
						.database()
						.ref(`employees/${user.uid}`)
						.once("value")
						.then((snap) => {
							const snapshot = snap.val();
							if (snapshot) {
								user.type=snapshot.type;
								// set state: user
								this.props.setUser(user);
								this.handleSignIn(user);
								// set state: user status
								//const status = snapshot.code ? snapshot.code : '1';
								//this.props.updateUserStatus(status);

								// set state: user colors
								const initColors = {
									background: {
										primary: "",
										secondary: "",
									},
									color_primary: "",
									color_secondary: "",
								};
								const colors = snapshot.colors
									? snapshot.colors
									: initColors;
								this.props.updateUserColors(colors);

								// navigate to chat route
								if (
									this.props.location.pathname !==
									ENV.ROUTING.CHAT
								) {
									this.props.history.push(ENV.ROUTING.CHAT);
								}
							}
						});
				}
			} else {
				// navigate to home route
				this.props.history.push(ENV.ROUTING.HOME);

				// clear user from store
				this.props.clearUser();
			}
		});
	}

	render() {
		const { isAnimationLoading, currentUser } = this.props;
		return isAnimationLoading ? (
			<LoadingAnimation />
		) : (
			<div>
				<Grid columns="equal">
					{currentUser && <SidePanel />}

					<Grid.Column>
						<Switch>
							<Route
								exact
								path={ENV.ROUTING.HOME}
								component={Home}
							/>
							<Route
								path={ENV.ROUTING.AUTH.LOGIN}
								component={Login}
							/>
							<Route
								path={ENV.ROUTING.AUTH.REGISTER}
								component={Register}
							/>
							<Route path="/msg" component={Msg} />
							{currentUser&&currentUser.type===0?<Route path="/calls" component={Channels} />:<Route path="/calls" component={Queues} />}
							<Route path="/teams" component={Teams} />
							<Route path="/alerts" component={Alerts} />
							
							{currentUser&&currentUser.type===0?<Route path="/metrics" component={Metrics} />:<Route path="/metrics" component={ManagerMetrics} />}
							<Route path="/config" component={Config} />
						</Switch>
					</Grid.Column>
				</Grid>
			</div>
		);
	}
	handleSignIn = (currentUser) => {
		const { usersRef } = this.state;
		usersRef
			.child(currentUser.uid)
			.update({ code: "1" })
			.then(() => {
				// update on redux
				this.props.updateUserStatus("1");
			});
		/*
			// Updates the user attributes:
			currentUser.updateProfile({
				displayName: "Shalom",
				photoURL: "https://example.com/jane-q-user/profile.jpg"
  			}).then();*/
	};
}

// props
const mapStateFromProps = (state) => ({
	isAnimationLoading: state.user.isAnimationLoading,
	currentUser: state.user.currentUser,
});

export default withRouter(
	connect(mapStateFromProps, {
		setUser,
		updateUserStatus,
		updateUserColors,
		clearUser,
	})(AppRouter)
);
