// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
// firebase
import firebase from "../../../../firebase";
import TextField from "@material-ui/core/TextField";
//import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Popover from "@material-ui/core/Popover";
import { Form,Button } from "semantic-ui-react";
import Typography from "@material-ui/core/Typography";
class AddTicket extends Component {
	state = {
		ticket: "",
		ticketsRef: firebase.database().ref("tickets"),
		saved: true,
		error: [],
		openNewTicketPopover: null,
		assign:'',
		priority:'low',
		about:'',
		confirmed:false,
		value:null,
	};
	render() {
		const { ticket, openNewTicketPopover,value } = this.state; 
		const options = [
			{ key: 'r', text: 'Registration', value: 'Registration' },
			{ key: 's', text: 'Sales', value: 'Sales' },
			{ key: 'a', text: 'Accounting', value: 'Accounting' },   // TODO read these options from DB instead of hardcoding
		  ]
		return (
			<div>
				<span onClick={this.handleNewTicketPopover} role="presentation">
					{this.props.currentCustomer && (
						<Fab size="small" color="primary" aria-label="add">
							<AddIcon />
						</Fab>
					)}
					{console.log(this.state)}
				</span>

				<Popover
					className="sc-channel-info-popover"
					anchorEl={openNewTicketPopover}
					open={Boolean(openNewTicketPopover)}
					onClose={this.handleCloseNewTicketPopover}
				>
					<Form
						style={{
							width: "400px",
							height: "350px",
							padding: "15px",
						}}
					>
						<Form.Group >
							{this.props.currentCustomer&&(<h3>
						Customer: {this.props.currentCustomer.name}
						</h3>)}
						
							
							
						</Form.Group>
						<Form.Group inline>
						<Form.Select
								fluid
								label="Assign"
								options={options}
								placeholder="Assign"
								onChange={(e,d) => this.setState({assign: d.value})}
							/></Form.Group>
							<Form.Group>
							<label>Priority:</label>
							<Form.Radio
								label="Low"
								value="lw"
								checked={value==="lw"}
								onChange={this.handleChange}
							/>
							<Form.Radio
								label="Medium"
								value="md"
								checked={value==="md"}
								onChange={this.handleChange}
							/>
							<Form.Radio
								label="High"
								value="lg"
								checked={value==="lg"}
								onChange={this.handleChange}
							/>
							
							</Form.Group>
						<Form.TextArea
							label="About"
							placeholder="Please explain the problem..."
							onChange={(e) => this.setState({about: e.target.value})}
						/>
						<Form.Checkbox label="Confirmed Credentials" />
						<Button onClick={this.submitFireBase}>Submit</Button>
					</Form>
				</Popover>
			</div>
		);
	}
	
	submitFireBase = () => {
		const { currentCustomer } = this.props;
		const { ticketsRef } = this.state;
		if (currentCustomer) {
			ticketsRef
				.child(currentCustomer.id)
				.push()
				.set(this.createTicket()) // TODO Check the quality of this ticket
				.then(() => {
					// empty errors
					this.setState({ errors: [] });
				})
				.catch((error) => {
					this.setState({ errors: [error] });
				});
			this.setState({ openNewTicketPopover: false });
		}
	};
	/*delFireBase = () => {
		const { currentCustomer } = this.props;
		const { ticketsRef } = this.state;
		if (currentCustomer) {
			ticketsRef
				.child(currentCustomer.id)
				.remove()
			this.setState({ openNewTicketPopover: false });
		}
	};*/
	/**
	 * handle input change event
	 *
	 * @param event
	 */
	handleChange = (e, { value }) => this.setState({ value })

	createTicket = () => {
		const { currentUser } = this.props;
		const {assign,priority,about,confirmed,value}=this.state;
		//console.log(currentUser.uid)
		const data = {
			timestamp: firebase.database.ServerValue.TIMESTAMP,
			subject: "fill in subject",
			body: about,
			assign: assign,
			priority: value,
			confirmed: confirmed,

			user: {
				id: currentUser.uid,
				name: currentUser.displayName,
				email: currentUser.email,
			},
		};

		return data;
	};
	/**
	 * handle new ticket popover
	 *
	 * @param event
	 */
	handleNewTicketPopover = (event) => {
		this.setState({ openNewTicketPopover: event.currentTarget });
	};

	/**
	 * handle close channel info popover
	 */
	handleCloseNewTicketPopover = () => {
		this.setState({ openNewTicketPopover: null });
	};
}
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentCustomer: state.customer.currentCustomer,
});
export default connect(mapStateToProps)(AddTicket);
