// app
import * as actionTypes from "../actions/ActionTypes";

// init message state
const initMessageState = {
	initState: [],
	alertCount: 0,
	currentTeamsCounts: 0,
	currentLiveMessageCounts: 0,
};

// reducer: Message
const messageReducer = (state = initMessageState, action) => {
	switch (action.type) {
		case actionTypes.SET_MESSAGES: {
			const matchedIndex = state.initState.findIndex(
				(x) => x.channelId === action.payload.channelId
			);
			const lists = state;

			// validate channelId and then update to new state
			if (matchedIndex > -1)
				lists.initState[matchedIndex] = action.payload;
			else lists.initState.push(action.payload);

			return lists;
		}
		case actionTypes.SET_ALERT_MESSAGES_COUNT:
			return {
				...state,
				alertCount: action.payload,
			};
		case actionTypes.SET_TEAMS_MESSAGES_COUNTS:
			return {
				...state,
				currentTeamsCounts: action.payload,
			};
		case actionTypes.SET_LIVE_CHANNEL_MESSAGES_COUNTS:
			return {
				...state,
				currentLiveMessageCounts: action.payload,
			};
		default:
			return state;
	}
};

export default messageReducer;
