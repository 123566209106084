import {
	setChannel,
} from "../../../../store/actions/ChannelAction";
// react
import React, { Component } from "react";
import Icon from "@material-ui/core/es/Icon/Icon";
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
//=import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
// firebase
import firebase from "../../../../../firebase";
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { HorizontalBar } from "react-chartjs-2";
import { Grid } from "semantic-ui-react";
import callLogger from "../../../utilities/helpers/Logger"
//const io = require("socket.io-client");
import { io } from "socket.io-client";
const port = process.env.PORT || 443; // 5000
var audioContext = null;
var tmpBuffer = []
var globalCnt = 0
var soundval = 0
var failure = 0
var talkNow =0
var warnmsg=1
//const socket = io(process.env.REACT_APP_AUDIO_SERVER + port);
var socket = null
var firstTime = true
var micDead = 0
var gnetrate=0
var gnetrateplot=0
var socketError=0
var packetLen=0
var gackLen=0
const packetSize=4
var debounce =Date.now()// a debounce switch for mic
function send(input, channel) {
	packetLen=packetLen+(new Int8Array(input)).length
	// 250ms* packetsize since audio is every 250ms 300 is a little buffer over 250
	socket.timeout(packetSize*250+1000).emit("ping_from_client", input, channel.id, (err) => { 
		if (err){
			console.log('timeout audio-server packetL:'+packetLen)
			if (gnetrate>0){
			gnetrate=0
			//gnetrateplot=0
			}else{
				gnetrate=gnetrate-1;
			}
			//packetLen=packetLen-8000; // DON'T HARDCODE THIS!
			// WHY ARE THEY TIMING OUT? IS IT TRUE? IF PACKSIZE==ACKLENGTH?
		}
		
	});
	
	//console.log('===>inside sending?')
	/*
	socket.emit("ping_from_client", input, channel.id, function (ack) {

		console.log('The server acknowledged our event:'+ ack+' i:'+new Int16Array(input.slice(0,2)));
		if (Math.abs(ack-new Int16Array(input.slice(0,2))[0])<.5){
			console.log('ack confirmed')
			socketError=0
		} else{
			socketError=socketError+1			
		}
		
	});
	*/

}

const SpeechRecognition =
	window.SpeechRecognition || window.webkitSpeechRecognition;
var recognition = []
try {
	recognition = new SpeechRecognition();
} catch (err) {
	recognition = []
}

const lineOptions = {
	maintainAspectRatio: false,
	backgroundColor: "rgb(15,15,15)",
	title: {
		display: true,
		align: 'bottom',
		fontSize: 2,
		text: ''
	},
	scales: {
		x: {
			display: false,
		},
		y: {
			display: false,
		},
		xAxes: [
			{
				ticks: {
					beginAtZero: true,
					display: false,
					min: 0,
					max: 1
				},
				gridLines: {
					drawBorder: true,
					lineWidth: 70,
					color: "rgba(210, 210, 210, .50)",
				},
				scaleLabel: {
					display: true,
					labelString: '' //was volume
				},
			},
		],
		yAxes: [{
			gridLines: {
				drawBorder: false,
				lineWidth: 110,
				
				color: "rgba(210, 210, 210, .50)",
			}
		}],
	},
	legend: {
		display: false
	},
};
const lineOptionsP2 = {
	maintainAspectRatio: false,
	backgroundColor: "rgb(15,15,15)",
	title: {
		display: true,
		align: 'bottom',
		fontSize: 2,
		text: ''
	},
	scales: {
		x: {
			display: false,
		},
		y: {
			display: false,
		},
		xAxes: [
			{
				ticks: {
					beginAtZero: true,
					display: false,
					min: 0,
					max: 1
				},
				gridLines: {

					lineWidth: 110,
					color: "rgba(110, 0, 0, 0)",
				},
				scaleLabel: {
					display: true,
					labelString: 'Delay' //was volume
				},
			},
		],
		yAxes: [{
			gridLines: {
				drawBorder: true,
				lineWidth: 110,
				color: "rgba(210, 210, 210, .50)",
			}
		}],
	},
	legend: {
		display: false
	},
};
const labels = ['mic:','net:']
var lineChartData = {
	//labels: [...Array(dataVal.length).keys()], // TODO change to dates
	labels, // TODO change to dates
	datasets: [
		{
			label: "MIC",
			data: [0,0,0],
			fill: true,
			maxBarThickness: 1,
			backgroundColor: ["rgb(255, 99, 132)","rgb(54, 162, 235)","rgb(54, 162, 235)"], // TODO pull from userprefs
			borderColor: ["rgba(255, 99, 132, 0.2)","rgba(255, 99, 132, 0.2)","rgba(255, 99, 132, 0.2)"],

		},
	],
}
const labelsP2 = ['d:','d:']
var lineChartDataP2 = {
	//labels: [...Array(dataVal.length).keys()], // TODO change to dates
	labelsP2, // TODO change to dates
	datasets: [
		{
			label: "M",
			data: [0,0,0],
			fill: true,
			maxBarThickness: 1,
			backgroundColor: ["rgb(255, 99, 132)","rgb(54, 162, 235)","rgb(54, 162, 235)"], // TODO pull from userprefs
			borderColor: ["rgba(255, 99, 132, 0.2)","rgba(255, 99, 132, 0.2)","rgba(255, 99, 132, 0.2)"],

		},
	],
}
class Microphone extends Component {

	state = {
		message: "",
		errors: [],
		talking: false,
		transcript: false,
		talkInterval: 0,
		liveMic:false,
		micClicked: false,
		errMsg:'some error happened',
		netRate:0,
		renderer: false, // this is a hack for stopping renderer but getting let mic render
		last_command: 0,
		openFileModal: false,

	};
	componentWillUnmount() {
		globalCnt = 0
		gackLen=0
		packetLen=0
		socketError=0
		gackLen=0
        warnmsg=1
		clearInterval(this.interval);
		clearInterval(this.networkInterval);
		lineChartData["datasets"][0]["data"][0] = [0]
		if (socket != null) {
			socket.disconnect()
		}
		if (this.state.talking === true) {
			console.log('microphone comp unmounted')
			this.setState({ talking: false });
			runSpeechRecognition(0, null, null, null);			
			this.setState({ transcript: false });
			audioContext.close();
			this.stopMic();
			console.log(`audio closed?`);
		}
      // fix Warning: Can't perform a React state update on an unmounted component
		this.setState = (state,callback)=>{
			return;
		};
	}
	componentDidMount() {
		warnmsg=1
		this.interval = setInterval(
			() => { // if agent talking or error use this 500ms interval otherwise don't
				//    console.log('[[inside main interval]]')
				if (this.state.talking === true || (failure > 0 && failure < 6) || (micDead > 0 && micDead < 6)) {
					console.log('t:' + this.state.talking + ' f:' + failure + ' m:' + micDead+' g:'+globalCnt)
					if (failure > 0 && failure < 6) {
						
						failure = failure + 1
					}
					if (micDead > 0 && micDead < 6) {
						micDead = micDead + 1
					}
					if (socketError>2 || Math.abs(gackLen-packetLen)>200000 || gnetrate<-5){ // too much delay
					//if (socketError>2){ // too much delay
						var msg=''
						if (Math.abs(gackLen-packetLen)>200000){
							warnmsg=1
							msg='Network issues: too much delay!'
							this.setState({errMsg:msg})
						}else if(socketError>2){
							warnmsg=1
							msg='Network issues: audio not being sent!'
							this.setState({errMsg:msg})
						}else if(gnetrate<-5){
							warnmsg=1
							msg='Network issues: lost connection!'
							this.setState({errMsg:msg})
						}else{
							warnmsg=1
							msg='catch all: some other condition?'
							this.setState({errMsg:'msg'})
							console.log('GR:'+gnetrate)
						}
						firstTime = true
						clearInterval(this.networkInterval);
						console.log('audio server reliability issues aL:'+gackLen+' pL:'+packetLen)
						failure = 1
						var dataOut = {
							"datetime": Date.now(),
							"level":"error",
							"message":msg,
							"context":{
								"switchboard":{
									"method":"microphone",
									"user":this.props.currentChannel.enduserID,
									"agent":this.props.currentChannel.agentID,
									"sessId":this.props.currentChannel.sessId,
									"channel":this.props.currentChannel.id

								}
							},
							"event":{
								"error":{
									"name":"network issues",
									"message":msg,
									"backtrace":{
									"socket_error":socketError,										
									"network": {"rate":gnetrate,"ackLen":gackLen,"packetL":packetLen}
									}
								}

							}
							
					 	 }; 
						callLogger(dataOut);
						if (this.state.talking) {
							runSpeechRecognition(0, null, null, null);
							this.setState({ talking: false });
							this.setState({ transcript: false });
							audioContext.close();

						}
						this.setState({ micClicked: false })
						socket.disconnect()
						socketError=0
						gackLen=0
						packetLen=0
						gnetrate=0
						gnetrateplot=0
					}
					this.setState({netRate:gnetrate}) // might need to pay attention to this
					this.setState({ time: Date.now() }); // This is an ugly hack to re-render microphone volume thingy Shalom!!! fix (stops if)

				} else if (failure > 5) {
					failure = 0
					this.setState({ time: Date.now() });
					
				} 
				if (talkNow > 0 && talkNow < 6) {
						
					talkNow = talkNow + 1
				}
				if (talkNow>5){
					talkNow=0
					this.setState({ time: Date.now() });
				}
				if (micDead > 5) {
					micDead = 0;
					globalCnt = 1
					this.stopMic()
					console.log('stoppppppped mic?')
					this.setState({ time: Date.now() });
				}
				
			}, 500
		); // ! don't hardcode this shalom!!!!
	}
	componentDidUpdate(prevProps, prevState) {
		
		if (this.props.currentChannel.id&&prevProps.currentChannel.id!== this.props.currentChannel.id) {
			console.log('DID I UPDARTE')
			this.stopMic()
		}
		
	  }

	/*
		shouldComponentUpdate(nextProps, nextState) {
			if (  this.props.currentChannel.active!=1 ) {
				//this.setState({ renderer: true });
			  return false;
			}
			return true;
		  }
		  */
	render() {

		return (
			<section className="sc-microphone-form">
				{/* Buttons */}
				{console.log('microphone: render')}
				{console.log(this.props.currentChannel.enduserID)}
				<div style={{ display: 'flex' }}>
					<Grid>
					

					<Grid.Column width={5}>
					{this.state.micClicked && <Paper><h5><center>DELAY</center></h5>
					<LinearProgress variant="buffer" value={lineChartDataP2["datasets"][0]["data"][0]*100} valueBuffer={lineChartDataP2["datasets"][0]["data"][0]*100} /></Paper>}
 {/*
							{this.state.micClicked && <HorizontalBar
								width={90}
								height={30}
								data={lineChartDataP2}
								options={lineOptionsP2}
							/>}
							*/}
						</Grid.Column>
						<Grid.Column width={3}>
							<Avatar component={Paper} elevation={5}>
								{!this.state.micClicked ? (
									!this.props.currentChannel.chatOnly&&warnmsg>0? (<img
										width="16"
										height="25"
										src={require("../../../../../assets/mic.png")}
										alt="loading..."
										onClick={this.startMic}
									/>):(<img
										width="35"
										height="35"
										src={require("../../../../../assets/chatOnly.png")}
										alt="loading..."									
										/>
									)
								) : (
									<img
										width="33"
										height="50"
										src={require("../../../../../assets/mic.gif")}
										alt="loading..."
										onClick={this.stopMic}
									/>
								)}</Avatar>
						</Grid.Column>
						
						<Grid.Column width={5}>
							{this.state.micClicked && <HorizontalBar
								width={90}
								height={30}
								data={lineChartData}
								options={lineOptions}
							/>}

						</Grid.Column>
						
						<Grid.Column width={3}>
							<Box pl={8}>
								<Avatar >
								{warnmsg>0? (<img

										width="41"
										height="25"
										src={require("../../../../../assets/hangup.png")}
										alt="loading..."
										onClick={() => this.handleCommand("1")}
								
								
									/>):(<img
										width="35"
										height="35"
										src={require("../../../../../assets/chatOnly.png")}
										alt="loading..."									
										/>
									)
								}
								</Avatar>
							</Box>
						</Grid.Column>

					</Grid>
					{(this.state.liveMic===false && globalCnt === 0  && this.state.micClicked===true) ? this.sendAlert(micDead,'Microphone not working') : console.log('g:' + globalCnt + ' l:' + lineChartData["datasets"][0]["data"][0]+' m:'+this.state.liveMic+' c:'+this.state.micClicked+' f:'+failure+' t:'+(this.state.liveMic===false && globalCnt === 0 && lineChartData["datasets"][0]["data"][0] === 0 && this.state.micClicked) )}
								{console.log('c:'+(lineChartData["datasets"][0]["data"][0] === 0)+' nr:'+this.state.netRate+' nr:'+lineChartData["datasets"][0]["data"][1])}
								
					{ failure > 0&&this.sendAlert(-1,this.state.errMsg) }
					{ talkNow > 0&&this.talkNow("Ok to talk now") }
				</div>
				{/*
				<Snackbar open={failure > 0} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
					<MuiAlert elevation={6} variant="filled" severity="error"> Network issue: audio not being sent!</MuiAlert>
				</Snackbar>
				*/}
			</section>
		);
	}
	determineMic = ()=>{
		const { talking } = this.state;
		var enumeratorPromise = navigator.mediaDevices.enumerateDevices().then(function(devices) {
			
			var yesmic=0
			var mic = devices.find(function(device) {
			  if( device.kind === "audioinput"){
				  yesmic=yesmic+1
			  }
			});
			if (yesmic>0){
				this.setState({liveMic:true})
				console.log('found mic')
				globalCnt = 1
				micDead = 0
			}
			else{
				this.setState({liveMic:false})
				lineChartData["datasets"][0]["data"][0]=[0]
				globalCnt = 0
				
				console.log('No mic')
			}
	  }.bind(this))
	}
	startMic = () => {
		const { talking, talkInterval, renderer, transcript } = this.state;
		const { messagesRef, currentChannel, currentUser } = this.props;
		console.log('tr:'+transcript + ' f:' + failure + ' ch:' + currentChannel.active)
		
		if (currentChannel.active === 1 && (Date.now()-debounce)/1000>2) {
			//globalCnt = 0
			this.setState({ micClicked: true })
			var dataOut = {
				"datetime": Date.now(),
				"level":"info",
				"message":'Clicked on mic',
				"context":{
					"switchboard":{
						"method":"microphone",
						"user":this.props.currentChannel.enduserID,
						"agent":this.props.currentChannel.agentID,
						"sessId":this.props.currentChannel.sessId,
						"channel":this.props.currentChannel.id

					}
				},
				"event":{
					"info":{
						"name":"Time to mic customer",
						"userInfo":this.props.currentChannel.name,
						"message":Date.now() -this.props.currentChannel.timestamp,
						 
					}

				}
				
			  }; 
			callLogger(dataOut);
			socket = io(process.env.REACT_APP_AUDIO_SERVER + port,{'timeout': 2000, 'connect_timeout': 2000});
			
			
			socket.on("connect_error", () => {
				warnmsg=1
				this.setState({errMsg:'Network issues: can not reach servers'})
				firstTime = true
				console.log('cannot connect to audio server')
				failure = 1
				
				if (this.state.talking) {
					runSpeechRecognition(0, null, null, null);
					this.setState({ talking: false });
					this.setState({ transcript: false });
					audioContext.close();

				}
				this.setState({ micClicked: false })
				this.render()

				socket.disconnect()
				var dataOut = {
					"datetime": Date.now(),
					"level":"error",
					"message":this.state.errMsg,
					"context":{
						"switchboard":{
							"method":"microphone",
							"user":this.props.currentChannel.enduserID,
							"agent":this.props.currentChannel.agentID,
							"sessId":this.props.currentChannel.sessId,
							"channel":this.props.currentChannel.id

						}
					},
					"event":{
						"error":{
							"name":"network issues",
							"message":this.state.errMsg,
							"backtrace":{
							"socket_error":"socket.on(connect_error)"
							}
						}

					}
					
				  }; 
				callLogger(dataOut);

			});

			socket.on("connect", () => {
				try{
				console.log('trying to connect to audio')	
				webaudio_tooling_obj(currentChannel);
				
				this.setState({ talking: true });
				this.setState({ transcript: true });
				runSpeechRecognition(
					1,
					messagesRef,
					currentChannel,
					currentUser,
					this.changeTalking
				);
				packetLen=0
				console.log(`audio opened?`);
				this.setState({ talkInterval: Date.now() });
				console.log("talkInterval inside:", talkInterval);
				this.networkInterval = setInterval( // the point of this is to check network every 1.5s
					() => {
						socketError=socketError+1
						console.log('[[interval running?]]')
						this.setState({ time: Date.now() }); // This is an ugly hack to re-render microphone volume thingy Shalom!!! fix (stops if)					
					},1500
				); // ! don't hardcode this shalom!!!!

			}catch(err){
				console.log('Could not setup mic')
			}
			});
			socket.on("netQual", function(val,ackL)  {
				console.log('dataRate:'+val)  
				this.setState({netRate:val})
				this.setState({ackLen:ackL})
				gnetrateplot=val;
				console.log('datarate:'+gnetrateplot+' ackL:'+ackL+' pL:'+packetLen)
				gnetrate=val;
				
				gackLen=ackL;
				if (val>12000){
					clearInterval(this.networkInterval); // should i be reseting interval so that if in another 1.5s might be errors? works without it
					socketError=0 // then network is working					
				} 

			}.bind(this));
			socket.on("talkNow", function(val)  {
				console.log('Talk now to customer')  
				talkNow=1
				clearInterval(this.networkInterval); // should i be reseting interval so that if in another 1.5s might be errors? works without it
				socketError=0 // then network is working					
				

			}.bind(this));
			socket.on("rtcDead", function(val)  {
				warnmsg=0
				setTimeout(this.handleCommand, 25000, "1"); // this is so if faceserver dies i still kill the cust from db since fs can't
				setTimeout(this.handleCommand, 2000, "0"); // get it off the screen

				this.setState({errMsg:'Customer: End user left on their own'})
				firstTime = true
				console.log('RTCdead likely cust left')
				failure = 1
				
				if (this.state.talking) {
					runSpeechRecognition(0, null, null, null);
					this.setState({ talking: false });
					this.setState({ transcript: false });
					audioContext.close();

				}
				this.setState({ micClicked: false })
				this.render()

				socket.disconnect()
				var dataOut = {
					"datetime": Date.now(),
					"level":"error",
					"message":this.state.errMsg,
					"context":{
						"switchboard":{
							"method":"microphone",
							"user":this.props.currentChannel.enduserID,
							"agent":this.props.currentChannel.agentID,
							"sessId":this.props.currentChannel.sessId,
							"channel":this.props.currentChannel.id

						}
					},
					"event":{
						"error":{
							"name":"network issues",
							"message":this.state.errMsg,
							"backtrace":{
							"socket_error":"socket.on(connect_error)"
							}
						}

					}
					
				  }; 
				callLogger(dataOut);
				
			}.bind(this));
			
			this.determineMic()
		}

	}
	stopMic = () => {
		const { talking, talkInterval, renderer, transcript } = this.state;
		const { messagesRef, currentChannel, currentUser } = this.props;
		var tmp;
		debounce =Date.now()
		//clearInterval(this.interval);
		clearInterval(this.networkInterval);
		lineChartData["datasets"][0]["data"][0]=[0]
		globalCnt = 1
		gackLen=0
		this.setState({ talking: false });
		this.setState({ micClicked: false })
		this.setState({ transcript: false });
		try {
			audioContext.close();
		} catch (error) {
			console.log('no audio context')
		}

		console.log(`audio closed?`);
		tmp = (Date.now() - talkInterval) / 1000;
		//this.setState({ talkInterval: tmp})
		runSpeechRecognition(0, null, null, null);
		console.log("talked for ", tmp);
		socket.disconnect()
		messagesRef
			.child(currentChannel.id)
			.push()
			.set(
				createMessage(
					"⬅️🔇 talked for " +
					tmp +
					" seconds, put user on hold 🔇➡️",
					currentUser
				)
			)
			.then(() => {
				// empty errors
				this.setState({ errors: [] });
			})
			.catch((error) => {
				this.setState({ errors: [error] });
			});
	}
	/*talkingChange = () => {
		const { talking, talkInterval, renderer, transcript } = this.state;
		const { messagesRef, currentChannel, currentUser } = this.props;



		console.log('inside:' + transcript + ' ' + failure + ' ' + currentChannel.active)

		if (transcript === false && (currentChannel.active === 1)) {
			// deactivated talking button?
			console.log("talkInterval:", talkInterval);
			var tmp;
			if (talking === false) {
				globalCnt = 0
				this.setState({ micClicked: true })
				socket = io(process.env.REACT_APP_AUDIO_SERVER + port);


				socket.on("connect_error", () => {
					firstTime = true
					console.log('cannot connect to audio server')
					failure = 1
					if (this.state.talking) {
						runSpeechRecognition(0, null, null, null);
						this.setState({ talking: false });
						this.setState({ transcript: false });
						audioContext.close();

					}
					this.setState({ micClicked: false })
					this.render()

					socket.disconnect()

				});

				socket.on("connect", () => {

					webaudio_tooling_obj(currentChannel);
					this.setState({ talking: true });
					this.setState({ transcript: true });
					runSpeechRecognition(
						1,
						messagesRef,
						currentChannel,
						currentUser,
						this.changeTalking
					);
					console.log(`audio opened?`);
					this.setState({ talkInterval: Date.now() });
					console.log("talkInterval inside:", talkInterval);

				});



			} else {
				this.setState({ talking: false });
				this.setState({ micClicked: false })
				this.setState({ transcript: false });

				audioContext.close();
				console.log(`audio closed?`);
				tmp = (Date.now() - talkInterval) / 1000;
				//this.setState({ talkInterval: tmp})
				runSpeechRecognition(0, null, null, null);
				console.log("talked for ", tmp);
				socket.disconnect()
				messagesRef
					.child(currentChannel.id)
					.push()
					.set(
						createMessage(
							"⬅️🔇 talked for " +
							tmp +
							" seconds, put user on hold 🔇➡️",
							currentUser
						)
					)
					.then(() => {
						// empty errors
						this.setState({ errors: [] });
					})
					.catch((error) => {
						this.setState({ errors: [error] });
					});
			}
		}
	};*/
	changeTalking = () => {
		this.setState({ transcript: false })
		console.log(this.state.transcript)


	};
	sendAlert = (mic,msg) => {
		console.log('race condition:'+mic)
		if (mic===0){
		micDead = 1;
		}


		return (

			<Snackbar open={micDead>0||failure > 0} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}>
				<MuiAlert elevation={6} variant="filled" severity={warnmsg>0?"error":"warning"}> {msg}</MuiAlert>
			</Snackbar>
			/*
		setTimeout(
			() => {
				console.log('alert:'+globalCnt+' '+this.state.talking)
				if (globalCnt<1 && lineChartData["datasets"][0]["data"][0]==0 && this.state.talking){
				alert('No sound from Microphone');
				globalCnt=globalCnt+1;
				}
				
			},1000
		); // ! don't hardcode this shalom!!!!
		
		*/
		)
	}
	talkNow = (msg) => {
		


		return (

			<Snackbar open={talkNow> 0} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}>
				<MuiAlert elevation={6} variant="filled" severity="success"> {msg}</MuiAlert>
			</Snackbar>
	
		)
	}
	handleCommand = (command) => {

		const { channelsRef, currentChannel, messagesRef } = this.props;
		// send message

		if (command === "1" && (currentChannel.active === 1)) {
			if (socket != null){
			socket.emit("remove_client", currentChannel.id);
			this.props.setChannel(null)
			socket.disconnect()
			}
			// any validation? retry?
		}

		channelsRef
			.child(currentChannel.id)
			.child("active")
			.set(0)
			.then(() => {
				// empty errors
				this.setState({ errors: [] });
			})
			.catch((error) => {
				this.setState({ errors: [error] });
			});
		//console.log('did it close?')
		messagesRef
			.child(currentChannel.id)
			.push()
			.set(this.createCommand(command))
			.then(() => {
				// empty errors
				this.setState({ errors: [] });
			})
			.catch((error) => {
				this.setState({ errors: [error] });
			});


	};
	createCommand = (command) => {
		const { currentUser } = this.props;
		var msgg='ended chat with user'
        if (command==0){
			 msgg="end user left"
		}
		 
		const data = {
			timestamp: firebase.database.ServerValue.TIMESTAMP,
			content: msgg,
			user: {
				id: currentUser.uid,
				name: currentUser.displayName,
				email: currentUser.email,
			},
		};

		data.command = command;

		return data;
	};
	
}
function createMessage(content, currentUser) {
	//const { currentUser } = this.props;

	const data = {
		timestamp: firebase.database.ServerValue.TIMESTAMP,
		content: content,
		user: {
			id: currentUser.uid,
			name: currentUser.displayName,
			email: currentUser.email,
		},
	};
	if (content.includes('[QA:')) {
		data.command = "0";
	}
	return data;
}
// =========================== speech to text====================
function runSpeechRecognition(
	command,
	messagesRef,
	currentChannel,
	currentUser,
	changeTalking
) {
	recognition.continuous = true;
	//recognition.interimResults=true;
	// get output div reference
	//var output = document.getElementById("output");
	// get action element reference
	//var action = document.getElementById("action");
	// new speech recognition object
	//var SpeechRecognition = SpeechRecognition || webkitSpeechRecognition;
	//var recognition = new SpeechRecognition();

	// This runs when the speech recognition service starts
	//recognition.onstart = function() {
	//    console.log("<small>listening, please speak...</small>");
	//};
	/*
	  recognition.onspeechend = function() {
		console.log("<small>stopped listening, hope you are done...</small>");
	    
	  }
	  */
	// This runs when the speech recognition service returns result
	recognition.onresult = function (event) {
		var len = event.results.length - 1;
		var transcript = event.results[len][0].transcript;
		var confidence = event.results[len][0].confidence;
		console.log(
			"<b>Text:</b> " +
			transcript +
			"<br/> <b>Confidence:</b> " +
			confidence * 100 +
			"%"
		);
		//console.log(messagesRef);
		if (currentChannel != null) {
			messagesRef
				.child(currentChannel.id)
				.push()
				.set(
					createMessage(
						transcript + " [QA: " + confidence.toFixed(2) + '] 🔉',
						currentUser
					)
				)
				.then(() => { });

			changeTalking();
		}

		//output.classList.remove("hide");
		//console.log(event.results[len][0].transcript)
	};
	recognition.onspeechstart = function(event){
		console.log('SPEECH start!!!')
	}
	recognition.onaudioend= function(event){
		console.log('SPEECH audioend?')
	}
	recognition.onerror = function(event){
		console.log('SPEECH error',event.message)
	}
	recognition.onend = function() {
		console.log("speech rec ended com:"+command)
		if (command===1){
		   recognition.start();
		    console.log('speech restarted')
		}
	  }
	// start recognition
	switch (command) {
		case 1:
			recognition.start();
			console.log("<small>listening, please speak...</small>");
			break;
		case 0:
			console.log(
				"<small>stopped listening, hope you are done...</small>"
			);
			recognition.stop();
			break;
		default:
			break;
	}
}

//---------- this is the shit here $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
function webaudio_tooling_obj(currentChannel) {
	audioContext = new AudioContext();

	console.log(audioContext.sampleRate);


	//const audioContext = new AudioContext({ sampleRate: 8000 });
	
	audioContext.audioWorklet.addModule('worklet/recorderworkletprocess.js').then(
		function () {
			const recorder = new AudioWorkletNode(audioContext, 'recorder-worklet');
			let constraints = { audio: true };
			navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
				const microphone = audioContext.createMediaStreamSource(stream);
				socket.emit("initiate", currentChannel.id);
				microphone.connect(recorder);
				recorder.connect(audioContext.destination);
				recorder.port.onmessage = event => process_microphone_buffer(event)

			});
		}
	);
	console.log(audioContext.sampleRate);
 	
	// ---
	function Float32Concat(first, second) {
		var firstLength = first.length,
			result = new Float32Array(firstLength + second.length);

		result.set(first);
		result.set(second, firstLength);

		return result;
	}
	function process_microphone_buffer(event) {
		// invoked by event loop

		var microphone_output_buffer;
		globalCnt = globalCnt + 1;
		if (globalCnt > audioContext.sampleRate) {
			globalCnt = 1;
		}
		if (event['data']) {
			tmpBuffer= Float32Concat(tmpBuffer, event['data']); // just mono - 1 channel for now
			microphone_output_buffer = event['data']
			//tmpBuffer=event['data'].slice(event['data'].length-20,event['data'].length)
			// convert float audio data to 16-bit PCM
			//console.log(event['data'])
			//var buffer = new ArrayBuffer(event['data'].length * 2);
			/*
			  var output = new DataView(buffer);
			  for (var i = 0, offset = 0; i < microphone_output_buffer.length; i++, offset += 2) {
					  var s = Math.max(-1, Math.min(1, microphone_output_buffer[i]));
					  output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
			  }
						  //socketio.emit('write-audio', buffer);
			  */
			if (tmpBuffer.length>audioContext.sampleRate/2){ // 1s of data
							microphone_output_buffer=tmpBuffer
						//console.log("LENGTH!!!:"+tmpBuffer.length)
						tmpBuffer=[]
			var buffer = downsampleBufferR2(
				microphone_output_buffer,
				audioContext.sampleRate,
				8000
			);

			/*
			var buffer = debugBuffer(
				microphone_output_buffer,
				audioContext.sampleRate,
				16000
			);
			*/
			// take this oversized buffer and pull a sliding data, trim microphone_output buffer by sliding window size
			//console.log(event['data'].length)
			var dd = new Int16Array(buffer.slice(0, buffer.length))
			dd=dd.map(a => Math.abs(a))
			dd=dd.reduce((acc,v,i,a)=>(acc+v/a.length),0);
			var eee="rgba(10, 25, 59,"+Math.max(dd)/4000+")"
			//console.log(Math.max(...dd));
			soundval = Math.floor(Math.random() * 100)
			lineChartData["datasets"][0]["data"] = [Math.max(dd)/4000, (gnetrateplot-24000)/9000]
			lineChartDataP2["datasets"][0]["data"] = [Math.abs(gackLen-packetLen)/128000]
			
			dd="rgb(54, "+(176*(gnetrateplot-24000)/9000).toString()+", 35)"
			var ee="rgb("+(255*(Math.abs(gackLen-packetLen)/128000)).toString()+','+(255-255*(Math.abs(gackLen-packetLen)/128000)).toString()+",25)"
			
			lineChartData["datasets"][0]["backgroundColor"]= [eee,dd]

			//console.log(audioContext.sampleRate)
			//send(microphone_output_buffer,currentChannel)
			send(buffer, currentChannel); //microphone_output_buffer)
			// microphone_output_buffer  <-- this buffer contains current gulp of data size BUFF_SIZE
		}}
		//show_some_data(microphone_output_buffer, 5, "from getChannelData");
	}

	var downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
		if (outSampleRate === sampleRate) {
			return buffer;
		}

		if (outSampleRate > sampleRate) {
			console.log(
				"downsampling rate show be smaller than original sample rate"
			);
		}

		var sampleRateRatio = sampleRate / outSampleRate;
		var newLength = Math.round(buffer.length / sampleRateRatio);
		var result = new Int16Array(newLength);
		var offsetResult = 0;
		var offsetBuffer = 0;
		while (offsetResult < result.length) {
			var nextOffsetBuffer = Math.round(
				(offsetResult + 1) * sampleRateRatio
			);
			var accum = 0,
				count = 0;
			for (
				var i = offsetBuffer;
				i < nextOffsetBuffer && i < buffer.length;
				i++
			) {
				accum += buffer[i];
				count++;
			}

			result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
			offsetResult++;
			offsetBuffer = nextOffsetBuffer;
		}
		return result.buffer;
	};

	var downsampleBufferR2 = function (buffer, sampleRate, outSampleRate) { // this buffer is bigger than needed this function is agnostic
		// downsampling variables
		var filter = [
			-0.037935, -0.00089024, 0.040173, 0.019989, 0.0047792, -0.058675, -0.056487,
			-0.0040653, 0.14527, 0.26927, 0.33913, 0.26927, 0.14527, -0.0040653, -0.056487,
			-0.058675, 0.0047792, 0.019989, 0.040173, -0.00089024, -0.037935
		];
		const samplingRateRatio = sampleRate / outSampleRate; // 48k/16k=3
		const nOutputSamples = Math.floor((buffer.length - filter.length) / (samplingRateRatio)) + 1; // 506
		var outputBuffer = new Int16Array(nOutputSamples);
		
		for (var i = 0; i < outputBuffer.length; i++) {
			var offset = Math.round(samplingRateRatio * i);
			var sample = 0;
			for (var j = 0; j < filter.length; ++j) {
				sample += buffer[offset + j] * filter[j];
			}
			//console.log(sample* 0x7fff)
			var s = Math.max(-1, Math.min(1, sample));
			outputBuffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
		}
		return outputBuffer.buffer;
	};
	var debugBuffer = function (buffer, sampleRate, outSampleRate) { // this buffer is bigger than needed this function is agnostic
		// downsampling variables

		var filter = [
			-0.037935, -0.00089024, 0.040173, 0.019989, 0.0047792, -0.058675, -0.056487,
			-0.0040653, 0.14527, 0.26927, 0.33913, 0.26927, 0.14527, -0.0040653, -0.056487,
			-0.058675, 0.0047792, 0.019989, 0.040173, -0.00089024, -0.037935
		];
		const samplingRateRatio = sampleRate / outSampleRate; // 48k/16k=3
		//const nOutputSamples = Math.floor((buffer.length - filter.length) / (samplingRateRatio)) + 1; // 506
		const nOutputSamples = Math.floor((buffer.length) / (samplingRateRatio));
		var outputBuffer = new Int16Array(nOutputSamples);
		globalCnt = globalCnt + 1;
		for (var i = 0; i < outputBuffer.length; i++) {

			var offset = Math.round(samplingRateRatio * i);
			/*
			var sample = 0;
			for (var j = 0; j < filter.length; ++j) {
				sample += buffer[offset + j] * filter[j];
			}
			//console.log(sample* 0x7fff)
			var s=Math.max(-1,Math.min(1, sample)); 
			outputBuffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
			*/
			var s = Math.max(-1, Math.min(1, buffer[offset]));
			//outputBuffer[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
			//outputBuffer[i]= Math.round(buffer[offset]*32767)
			outputBuffer[i] = globalCnt;// Math.floor(Math.sin(Math.PI*200*globalCnt/32000)*15000)
		}
		if (globalCnt > 32000) {
			globalCnt = 1;
		}
		//outputBuffer[0]=globalCnt;
		console.log(globalCnt)
		return outputBuffer.buffer;
	};
function handleError(){
	globalCnt=-1
};

	/*
	function start_microphone(stream) {
		//const { currentChannel } = this.props;
		//console.log(currentChannel.id)
		socket.emit("initiate", currentChannel.id);
		//gain_node = audioContext.createGain();
		//gain_node.connect( audioContext.destination );
		console.log('here')
		microphone_stream = audioContext.createMediaStreamSource(stream);
		console.log(microphone_stream)
		//microphone_stream.connect(gain_node);

		script_processor_node = audioContext.createScriptProcessor(
			BUFF_SIZE,
			1,
			1
		);
		script_processor_node.onaudioprocess = process_microphone_buffer;

		microphone_stream.connect(script_processor_node);
	}
*/
	//  webaudio_tooling_obj = function()
}
// props
const mapStateToProps = (state) => ({

	currentChannel: state.channel.currentChannel,

});


export default connect(mapStateToProps, {
	setChannel,

})(Microphone);
