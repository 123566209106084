// react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
// firebase
import firebase from "../../../../firebase";
// app

//import { Grid } from "semantic-ui-react";
import { Grid, Box } from "@material-ui/core/";
import { Doughnut, Line,  Bar } from "react-chartjs-2";
import formatMessageTime from "../../utilities/helpers/Date";
import Paper from "@material-ui/core/Paper";
import Avatar from '@material-ui/core/Avatar';
//import datalabels from'chartjs-plugin-datalabels';
//import doughnutlabel from'chartjs-plugin-doughnutlabel';
//REF https://github.com/reactchartjs/react-chartjs-2
//REF https://reactchartjs.github.io/react-chartjs-2/#/

const barOptions = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};
const lineOptions = {
	fill: false,
    maintainAspectRatio: false,
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
	},
};
const doughnutOptions = {
	rotation: 1 * Math.PI,
	circumference: 1 * Math.PI,
    responsive:true,
    maintainAspectRatio: false,
    
    plugins: {
       /* datalabels: {
          display: true,
          backgroundColor: '#ccc',
          borderRadius: 3,
          font: {
            color: 'red',
            weight: 'bold',
          },
          formatter: function(value) {
            return 'line1\nline2\n' + value;
            // eq. return ['line1', 'line2', value]
        } },*/
        doughnutlabel: {
            labels: [{
              text: '550',
              padding:"10",
              font: {
                size: 20,
                weight: 'bold'
              }
             
            }, {
              text: 'total'
            }]
          }   
       
       
    },

       
};

const backgroundColor = [
	// TODO grab this data from firebase userprefs
	"rgba(255, 99, 132, 0.2)",
];
const borderColor = [
	// TODO grab this data from firebase userprefs
	"rgba(255, 99, 132, 1)",
];

class ManagerMetrics extends Component {
	state = {
		metricsRef: firebase.database().ref("metrics"),
		//lineChartData:[],
		pieChartData: {
			labels: [],
			datasets: [
				{
					label: "--",
					data: [],
					backgroundColor: backgroundColor,
					borderColor: borderColor,
					borderWidth: 1,
				},
			],
		},
		chatChartData: {
			labels: ["a", "b","c"],
			datasets: [
				{
					label: "total/day",
					data: [],

					borderWidth: 1,
				},
			],
		},
		ticketChartData: {
			labels: ["a", "b","c"],
			datasets: [
				{
					label: "total/day",
					data: [],

					borderWidth: 1,
				},
			],
		},
		lineChartData: {
			//labels: [...Array(dataVal.length).keys()], // TODO change to dates
			labels: [...Array(1).keys()], // TODO change to dates
			datasets: [
				{
					label: "CSAT Score Last Seven Days ",
					data: [],
					fill: false,
					backgroundColor: "rgb(255, 99, 132)", // TODO pull from userprefs
					borderColor: "rgba(255, 99, 132, 0.2)",
				},
			],
		},
		teamStats: [],
		totals: {
			chat: 0,
			queued: 0,
			goals: 0,
			frt: 0,
			sla: 0,
			solved: 0,
			new: 0,
		},
        daily:null,
	};

	componentDidMount() {
		this.grabFirebaseData();
	}

	componentWillUnmount() {
		this.removeFirebaseDataListener(); //no need since just pull once on mount
	}

	render() {
		const { currentUser, userStatus, userColors } = this.props;
		const { pieChartData, lineChartData, totals,daily } = this.state;

		return (
			currentUser &&
			userStatus &&
			userColors && (
				<section className="sc-side-panel-list-wrapper">
					<Box>
						<Grid container spacing={1} direction="row">
							<Grid item>
								<Grid
									container
									spacing={1}
									xs={4}
									direction="column"
								>
									<Grid item>
										<Paper>
											<center>
												{" "}
												TOTAL CHATS {totals.chat}
											</center>
											{
												<Bar
													getElementAtEvent={
														this.getElementAtEvent
													}
													data={
														this.state.chatChartData
													}
													options={barOptions}
												/>
											}
										</Paper>
									</Grid>
									<Grid item>
                                    <Grid
									container
									spacing={0}
									xs={12}
									direction="column"
								>
										<Paper style={{ height: "65px" }} elevation={0}>
											<h2>
                                                <center>
												QUEUED USERS 
                                                </center>
                                                <center><Avatar style={{height:30,width:30 }}>{totals.queued}</Avatar></center>
											</h2>
										</Paper>
									</Grid>
									<Grid item>
										<Paper style={{ height: "65px",backgroundColor:'#EEEEEE' }}elevation={0}>
											<h2><center>GOALS</center> <center><Avatar style={{height:30,width:30 }}>{totals.goals}</Avatar></center></h2>
										</Paper>
									</Grid>
</Grid>
									<Grid item>
										<Paper style={{ height: "130px" }}>
											<Line
												data={lineChartData}
												options={lineOptions}
											/>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
							<Grid item>
								<Grid
									container
									spacing={1}
									xs={4}
									direction="column"
								>
									<Grid item>
                                        
										<Paper>
											<center>
												{" "}
												NEW TICKETS {totals.new}
											</center>
											{
												<Bar
													data={
														this.state
															.ticketChartData
													}
													options={barOptions}
												/>
											}
										</Paper>
									</Grid>
									<Grid item>
                                    <Grid
									container
									spacing={0}
									xs={12}
									direction="column"
								>
										<Paper style={{ height: "65px"  }} elevation={0}>
											<h2><center>
												FIRST RESPONSE TIME</center><center><Avatar style={{height:30,width:30 }}> {totals.frt.toFixed(0)}</Avatar></center>
											</h2>
										</Paper>
									</Grid>
									<Grid item>
										<Paper style={{ height: "65px",backgroundColor:'#EEEEEE'  }} elevation={0}>
											<h2><center>
												SOLVED TICKETS </center><center><Avatar style={{height:30,width:30 }}> {totals.solved}</Avatar></center>
											</h2>
										</Paper>
									</Grid>
</Grid>
									<Grid item>
										<Paper style={{ height: "130px" }} >
											<Doughnut
												data={pieChartData}
												options={doughnutOptions}
                                                
											/>
										</Paper>
									</Grid>
								</Grid>
							</Grid>
                            {this.state.daily&&<Grid item>
								<Grid
									container
									spacing={1}
									xs={4}
									direction="column"
								>
									<Grid item>
										<Paper>
											<center>
												{" "}
												VISITORS {daily.newTickets}
											</center>
											{
												<Bar
													data={
														this.state
															.ticketChartData
													}
													options={barOptions}
												/>
											}
										</Paper>
									</Grid>
									<Grid item>
										<Paper style={{ height: "130px" }}>
											<h2>
												TICKETS {daily.frt}
											</h2>
										</Paper>
									</Grid>
									<Grid item>
										<Paper style={{ height: "130px" }}>
											<h2>
												OPEN {daily.solvedTickets}
											</h2>
										</Paper>
									</Grid>

									
								</Grid>
							</Grid>}
                            {this.state.daily&&<Grid item>
								<Grid
									container
									spacing={1}
									xs={4}
									direction="column"
								>
									<Grid item>
										<Paper>
											<center>
												{" "}
												CHATS {daily.newTickets}
											</center>
											{
												<Bar
													data={
														this.state
															.ticketChartData
													}
													options={barOptions}
												/>
											}
										</Paper>
									</Grid>
									<Grid item>
										<Paper style={{ height: "130px" }}>
											<h2>
												LOGGED IN {daily.frt}
											</h2>
										</Paper>
									</Grid>
									<Grid item>
										<Paper style={{ height: "130px" }}>
											<h2>
												SOMETHING {daily.solvedTickets}
											</h2>
										</Paper>
									</Grid>

									
								</Grid>
							</Grid>}
						</Grid>
					</Box>
				</section>
			)
		);
	}

	grabFirebaseData = () => {
		const { currentUser } = this.props;
		const { metricsRef } = this.state;
		var stats = [];

		metricsRef
			.child(currentUser.uid)
			.orderByChild("timestamp")
			.limitToLast(7)
			.once("value", (snap) => {
				if (snap.exists()) {
					snap.forEach((child) => {
						const dataVal = child.val();
						stats.push(dataVal);
					});
				}
				this.setState({ teamStats: stats });
				this.chopData(stats);
			});
	};
	chopData = (stats) => {
		var chatTotal = 0;
		var labels = [];
		var tmpChats = [];
		var tmpTickets = [];
		var tmpSlas = [];
		var inTotals = this.state.totals;
        var labels =[];
		const {
			ticketChartData,
			chatChartData,
			lineChartData,
			pieChartData,
		} = this.state;
		stats.forEach((team, index) => {
			inTotals.chat += team.chats;
			inTotals.queued += team.queuedVisits;
			inTotals.goals += team.goals;
			inTotals.frt += team.frt;
			inTotals.sla += team.sla;
            inTotals.new +=team.newTickets
			inTotals.solved += team.solvedTickets;
            labels.push(formatMessageTime(
                team.timestamp,
                "MMM Do "
          ))
			tmpChats.push(team.chats);
			tmpTickets.push(team.newTickets);
			tmpSlas.push(team.sla);
		});
		inTotals.sla = inTotals.sla / stats.length;
		chatChartData["datasets"][0]["data"] = tmpChats;
		ticketChartData["datasets"][0]["data"] = tmpTickets;
		lineChartData["datasets"][0]["data"] = tmpSlas;
        console.log(tmpSlas)
		pieChartData["datasets"][0]["data"] = [
			inTotals.sla,
			100 - inTotals.sla,
		];
        chatChartData["labels"] = labels;
        lineChartData["labels"] = labels;
        ticketChartData["labels"] = labels;
		this.setState({ chatChartData: chatChartData });
		this.setState({ ticketChartData: ticketChartData });
		this.setState({ totals: inTotals });

		//insert the chartdata labels and data
		//inser the ticketdata labels and data
	};

	getElementAtEvent = (element) => {
		if (!element.length) return;
		const { teamStats } = this.state;
		const { _datasetIndex: datasetIndex, _index: index } = element[0];
        this.setState({daily:teamStats[index]})
		console.log(teamStats[index]);
	};
	removeFirebaseDataListener = () => {
		const { metricsRef } = this.state;
		metricsRef.off();
	};
}

// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentChannel: state.channel.currentChannel,
	userStatus: state.user.status,
	userColors: state.user.colors,
});

export default connect(mapStateToProps)(ManagerMetrics);
