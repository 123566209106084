// react
import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

// redux
import thunk from 'redux-thunk';
import { createStore, applyMiddleware  } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools, } from 'redux-devtools-extension';


// app
import AppRouter from './AppRouter';
import rootReducer from './store/reducers';
const middleware = [thunk];
// redux store
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<BrowserRouter>
					<React.Fragment>
						<AppRouter/>
					</React.Fragment>
				</BrowserRouter>
			</Provider>
		);
	}
}

export default withTranslation()(App);
