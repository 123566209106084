// react
import React, { Component } from "react";

// redux
import { connect } from 'react-redux';
import {setCustomer} from '../../../store/actions/CustomerAction'
// firebase
import firebase from "../../../../firebase";

import AddContact from "./AddContact"
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { makeStyles } from '@material-ui/core/styles';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Popover from "@material-ui/core/Popover";
import EditIcon from '@material-ui/icons/Edit';
import Avatar from "@material-ui/core/Avatar";
const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
class ContactInfo extends Component {
	state = {
		name: "",
		endCustomersRef: firebase.database().ref("endCustomers"),
		channelsRef: firebase.database().ref("openChannels"),
		endUserRef:firebase.database().ref('endUsers'),
		emailAddress: "",
		phone: "",
		searched: false,
        linkBtnHidden:true,
        findBtnHidden:false,
        openNewContactPopover: null,
		custID:null,
		holdSnapshot:null,
        
	};
	
    
	render() {
		const { name, emailAddress, phone, email,linkBtnHidden,findBtnHidden,openNewContactPopover,holdSnapshot } = this.state;
        const {currentCustomer}=this.props;
		return (
			<FormControl>
				
				{/* if the user is not in then display this */}
				{!currentCustomer&&<TextField
					id="outlined-basic"
					label="Email Address"
					variant="outlined"
					name="emailAddress"
					value={email}
					onChange={this.handleChange}
				/>}
				{!currentCustomer&&<Button
                    hidden={findBtnHidden}
					variant="contained"
					color="primary"
					onClick={() => this.queryFireBase(emailAddress)}
				>
					Find Customer
				</Button>}
                {!currentCustomer&&<Button
					variant="contained"
                    className={useStyles.margin}
                    
                    fullWidth={false}
					color="primary"
					onClick={() => this.acceptCustomer()}
                    size="small"
                    hidden={linkBtnHidden}
				>
					Accept Customer
				</Button>}
				{(currentCustomer||holdSnapshot) &&<Paper style={{ 
      padding:'10px', minWidth: 320
    }}>
				{currentCustomer&&<Avatar
								alt="channel-avatar"
								src={currentCustomer.avatar}
							/>}

					<b>Name: </b>{currentCustomer?currentCustomer.name:name}
					<p></p>
					<b>Email: </b>{currentCustomer?currentCustomer.email:emailAddress}
					<p></p>
					<b>Phone: </b>{currentCustomer?currentCustomer.phone:phone}
				</Paper>}<br></br>
                <span
						onClick={this.handleNewContactPopover}
						role="presentation"
					>
						{!currentCustomer&&<Fab size="small" color="primary" aria-label="add">
							<AddIcon />
						</Fab>}
						{currentCustomer&&<Fab size="small" color="primary" aria-label="add">
							<EditIcon />
						</Fab>}

					</span>

					<Popover
						className="sc-channel-info-popover"
						anchorEl={openNewContactPopover}
						open={Boolean(openNewContactPopover)}
						onClose={this.handleCloseNewContactPopover}
					>
						<AddContact/>
					</Popover>
			</FormControl>
		);
	}

    /**
	 * handle new contactticket popover
	 *
	 * @param event
	 */
	handleNewContactPopover = (event) => {
		this.setState({ openNewContactPopover: event.currentTarget });
	};
	/**
	 * handle input change event
	 *
	 * @param event
	 */
	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		this.setState({ name: "" });
		this.setState({ phone: "" });
	};
	queryFireBase = (emailAddress) => {
		const { endCustomersRef } = this.state;
		this.setState({ name: " user not found" });
		this.setState({ emailAddress: "" });
        
		endCustomersRef
			.orderByChild("email")
			.equalTo(emailAddress)
			.once("value")
			.then((snaps) => {
				snaps.forEach((snap, index) => {
					const snapshot = snap.val();
					//console.log(snapshot);
					if (snapshot) {
                        
						this.setState({holdSnapshot:snapshot})
						this.setState({ name: " " + snapshot.name });
						this.setState({custID:snapshot.id})
						this.setState({ emailAddress: " " + snapshot.email });
						this.setState({ phone: " " + snapshot.phone });
                        this.setState({linkBtnHidden:false});
					} else {
						this.setState({ name: " user not found" });
					}
				});
			});
	};
    acceptCustomer =() =>{
        const {currentChannel}=this.props
        const {endCustomersRef,custID,holdSnapshot,channelsRef,endUserRef}=this.state;
		this.props.setCustomer(holdSnapshot)
        this.setState({linkBtnHidden:true});
        this.setState({findBtnHidden:true});
        
        endCustomersRef.child(custID)
			.child('channelHistory')
			.push()
			.set(currentChannel.id)

        endCustomersRef.child(custID)
			.child('endUserHistory')
			.push()
			.set(currentChannel.enduserID)
		//update enduser's avatar and name
		channelsRef.child(currentChannel.id)
		.child('avatar')
		.set(holdSnapshot.avatar)
		
		channelsRef.child(currentChannel.id)
		.child('name')
		.set(holdSnapshot.name)

		channelsRef.child(currentChannel.id)
		.child('endCustomerId')
		.set(holdSnapshot.id)

		endUserRef.child(currentChannel.enduserID)
		.child('avatar')
		.set(holdSnapshot.avatar)

		endUserRef.child(currentChannel.enduserID)
		.child('name')
		.set(holdSnapshot.name)
		// TODO append ip address and other historical stuff like channel from facebook etc
        
        // turn on a button to 'unlink customer' to undo this, remove the appends above and use details to rename the enduser back to old_name (maybe they typed it in?)
        // grab customer info
        // update openchannel with name, avatar,facebook etc
        // change the enduser name so rtc server can send message with correct name
        
        // display the contact info grid avatar details etc

    }
	/**
	 * handle close channel info popover
	 */
	handleCloseNewContactPopover = () => {
		this.setState({ openNewContactPopover: null });
	};
    
}
const mapStateToProps = state => ({
	currentChannel: state.channel.currentChannel,
    currentCustomer: state.customer.currentCustomer,
	
});
export default connect(mapStateToProps,{setCustomer})(ContactInfo);
