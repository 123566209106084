// react
import React, { Component } from "react";
import ReactNotifications from 'react-browser-notifications';

// redux
import { connect } from "react-redux";

// firebase
import firebase from "../../../../../firebase";

// app
import formatMessageTime from "../../../utilities/helpers/Date";
import { Button, Accordion, AccordionSummary } from "@material-ui/core/";

import {
	setChannel,
	setLiveChannelList,
	setQueuedChannelList,
} from "../../../../store/actions/ChannelAction";
import { setLiveMessagesCounts } from "../../../../store/actions/MessageAction";
import {
	setCallsBadgesShow,
	setCallsScreen,
} from "../../../../store/actions/UserAction";
import {setCustomer,setCustomerNoteCount,setCustomerTicketCount} from "../../../../store/actions/CustomerAction";
import LoadingAnimation from "../../../utilities/loading-animation/LoadingAnimation";

import { Grid } from "semantic-ui-react";

import MessagesPanel from "../messages/MessagesPanel";
import Microphone from "../messages/Microphone";
import Widgets from "../../widgets/Widgets";

import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import { makeStyles, withStyles } from "@material-ui/core/styles";
const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: "#44b700",
		color: "#44b700",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}))(Badge);

class Channels extends Component {
	
	state = {
		openChannelModal: false,
		channelName: "",
		channelDetails: "",
		channelsRef: firebase.database().ref("openChannels"),
		queuedChannelsRef: firebase.database().ref("queuedChannels"),
		notificationsRef: firebase.database().ref("notifications"),
		messagesRef: firebase.database().ref("messages"),
		endCustomersRef: firebase.database().ref("endCustomers"),
		errors: [],
		isFormEnabled: false,
		isAnimationLoading: false,
		isFirstLoad: true,
		debugLevel: 0,
		blanks: "\xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 ",
	};

	componentDidMount() {
		this.interval = setInterval(
			() => {
				this.setState({ time: Date.now() });
				if (this.state.debugLevel>3){
					console.log('30s-channels.js');
					console.log('length:'+this.props.liveChannelList.length)
				}
			},30000
		); // ! don't hardcode this shalom!!!!
		this.addChannelListener();
		this.addQueuedChannelListener();
		this.closedChannelListener();
		this.changedChannelListener(); // ! shalom!! change this to a new table to listen for timestamp change, channels don't change just this table
		this.closedQueuedChannelListener();
		this.clearBadgeSetShow(0);
	}
	
	componentWillUnmount() {
		clearInterval(this.interval);
		this.removeChannelListener();
		this.removeQueuedChannelListener();
		this.removeClosedChannelListener();
		this.removeChangedChannelListener();
		this.removeClosedQueuedChannelListener();
		this.clearBadgeSetShow(1);
		//this.props.setCallsScreen(callsScreen)
	}
	constructor() {
		super();
		this.showNotifications = this.showNotifications.bind(this);
		this.handleClick = this.handleClick.bind(this);
	  }
	 
	  showNotifications() {
		// If the Notifications API is supported by the browser
		// then show the notification
		if(this.n.supported()) this.n.show();
	  }
	 
	  handleClick(event) {
		// Do something here such as
		// console.log("Notification Clicked") OR
		 window.focus();// OR
		// window.open("http://www.google.com")
	 
		// Lastly, Close the notification
		this.n.close(event.target.tag);
	  }
	render() {
		const { isAnimationLoading, channelsRef, messagesRef } = this.state;
		const {
			currentUser,
			currentChannel,
			userColors,
			liveChannelList,
			queuedChannelList,
		} = this.props;

		return isAnimationLoading ? (
			<LoadingAnimation />
		) : (
			<section className="sc-side-panel-list-wrapper">
				<Grid>
					<Grid.Column width={4}>
						{/* Title */}
						{/* Channels */}
						
						<Accordion
							disabled={liveChannelList.length > 0 ? false : true}
							expanded={this.props.callsScreen.live &&liveChannelList.length > 0 }
							onChange={this.handleAccordionChange("live")}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Badge
									color="primary"
									badgeContent={liveChannelList.length}
								>
									<Typography>
										{/*Live Calls! <span>{channels.length}</span>*/}
										Live Calls!&nbsp;&nbsp;{" "}
									</Typography>
								</Badge>
							</AccordionSummary>

							<ul className="cd-remove-bullets sc-list">
								{/*this.displayChannels(channels, true, userColors)*/}

								{this.displayChannels(
									liveChannelList,
									true,
									userColors
								)}
							</ul>
						</Accordion>
						<p></p>
						<p></p>
						<Accordion
							disabled={
								queuedChannelList.length > 0 ? false : true
							}
							expanded={this.props.callsScreen.queued}
							onChange={this.handleAccordionChange("queued")}
							style={{ maxHeight: "55vh", overflow: "auto" }}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Badge
									color="primary"
									badgeContent={queuedChannelList.length}
								>
									<Typography>
										Queued Calls &nbsp;&nbsp;{"   "}
									</Typography>
								</Badge>
							</AccordionSummary>

							<ul className="cd-remove-bullets sc-list">
								{this.displayChannels(
									queuedChannelList,
									null,
									userColors
								)}
							</ul>
						</Accordion>
					</Grid.Column>
					<Grid.Column width={6}>
						{currentChannel && (
							<MessagesPanel
								key={currentChannel.id}
								currentChannel={currentChannel}
								currentUser={currentUser}
								channelsRef={channelsRef}
							/>
						)}
						{currentChannel!==null && currentChannel.active==1 && 
							<Microphone
								channelsRef={channelsRef}
								currentChannel={currentChannel}
								currentUser={currentUser}
								messagesRef={messagesRef}
							/>
						}
					</Grid.Column>
					<Grid.Column width={4}>
						{currentChannel && <Widgets />}
					</Grid.Column>
				</Grid>
				<ReactNotifications
          onRef={ref => (this.n = ref)} // Required
          title="Salestreamer customer wants help!" // Required
          body="Click here to answer"
          icon="icon.png"
          tag="abcdef"
          timeout="10000"
          onClick={event => this.handleClick(event)}
        />
			</section>
		);
	}

	handleAccordionChange = (panel) => (event, isExpanded) => {
		var tmpCallScreen = this.props.callsScreen;
		tmpCallScreen[panel] = isExpanded ? true : false;
		this.props.setChannel(null)// this is a shalom hack on accordian collapse/open to remove 3rd column
		this.props.setCallsScreen(tmpCallScreen);
		this.setState({ errors: [] }); // TODO do something better than forceupdate or setstate to re-render component
	};

	/**
	 * add live channel listener
	 * ensure the channel belongs to this person querying for the channel
	 */
	addChannelListener = () => {
		var loadedChannels = [];
		var tmpsnap = [];
		const userRef = firebase.auth().currentUser.uid; // Shalom!! hack
		this.state.channelsRef.on("child_added", (snap) => {
			// push channels
			console.log('channel-added')
			tmpsnap = snap.val(); // Shalom!! hack
			//console.log(firebase.auth().currentUser.uid);// Shalom!! hack
			if (tmpsnap.agentID === userRef) {
				// ! Shalom!! hack
				
				loadedChannels = this.props.liveChannelList.filter(
					(user) => user.id !== tmpsnap.id
				);


				console.log(loadedChannels)
				loadedChannels.push(snap.val());
				this.changeLiveChannelList(loadedChannels);
				this.n.show();
				
				//this.setFirstChannel()
			}
			//this.forceUpdate();

			// set to channels
			// set first channel

			/*
			this.setState({ liveChannelList: loadedChannels }, () =>
				this.setFirstChannel()
			);
			*/
			//console.log('chanenls'+this.state.channels)
		});
	};

	/**
	 * add queue channel listener
	 * ensure the channel belongs to this person querying for the channel
	 */
	addQueuedChannelListener = () => {
		const loadedChannels = [];
		var tmpsnap = [];
		const userRef = firebase.auth().currentUser.uid; // Shalom!! hack

		this.state.queuedChannelsRef.on("child_added", (snap) => {
			// push channels

			tmpsnap = snap.val(); // ! Shalom!! hack

			//console.log(firebase.auth().currentUser.uid);// Shalom!! hack
			if (tmpsnap.agentID === userRef) {
				// Shalom!! hack
				loadedChannels.push(snap.val());
				this.changeQueuedChannelList(loadedChannels);
				this.setState({ queuedChannelList: loadedChannels }); // ! shouldn't need this since it has props?
			}
			//this.forceUpdate();
			//console.log('channel added!')
			//console.log(currentUser)
			// set to channels
			// set first channel

			//this.setState({ queuedChannelList: loadedChannels });
		});
	};
	closedChannelListener = () => {
		var loadedChannels = [];
		var tmpsnap = [];

		const userRef = firebase.auth().currentUser.uid; // Shalom!! hack
		this.state.channelsRef.on("child_removed", (snap) => {
			// push channels

			tmpsnap = snap.val(); // ! Shalom!! hack
			console.log('closed channel')
			this.props.setChannel(null) // shalom hack to cover users leaving from front end, this clears current channel and removes 3rd column
			//console.log(this.state.channels)
			//console.log(firebase.auth().currentUser.uid);// Shalom!! hack
			if (tmpsnap.agentID === userRef) {
				// Shalom!! hack
				//loadedChannels.push(snap.val());

				const index = this.props.liveChannelList.findIndex(
					(user) => user.id === tmpsnap.id
				);
				if (index !== -1) {
					loadedChannels = this.props.liveChannelList.filter(
						(user) => user.id !== tmpsnap.id
					);
					this.changeLiveChannelList(loadedChannels);
					//this.setFirstChannel()
				}
			}
			//this.forceUpdate();

			// set to channels
			// set first channel

			/*
			this.setState({ liveChannelList: loadedChannels }, () =>
				this.setFirstChannel()
			);
			*/
		});
	};
	// this is for changing live chat colors  will trigger every message in channel
	changedChannelListener = () => {
		var loadedChannels = [];
		var tmpsnap = [];
		var channel = null;
		//const {liveChannelList}=this.props;
		const userRef = firebase.auth().currentUser.uid; // Shalom!! hack
		this.state.channelsRef.on("child_changed", (snap) => {
			// push channels

			tmpsnap = snap.val(); // Shalom!! hack

			console.log('channel-changed')
			//console.log(firebase.auth().currentUser.uid);// Shalom!! hack
			if (tmpsnap.agentID === userRef && tmpsnap.active === 1) {
				// ! Shalom!! hack
				const index = this.props.liveChannelList.findIndex(
					(user) => user.id === tmpsnap.id
				);
				const iindex = this.props.liveChannelList.findIndex(
					(user) => user.id === -1
				);
				console.log('iindex:'+iindex)
				if (index !== -1) {
					loadedChannels = this.props.liveChannelList.filter(
						(user) => (user.id !== tmpsnap.id && user.active === 1)
					);
					loadedChannels.push(tmpsnap);
					//console.log("forcedupdate");
					this.changeLiveChannelList(loadedChannels);
					//this.setState({ liveChannelList: loadedChannels }); // ! shouldn't need this since props has it?
					if (this.props.currentChannel){
						const channel=loadedChannels.find(
							(x) => x.id === this.props.currentChannel.id   // might be undefined since loaded and currentchannel clicked might be different
						)
						if (channel){						
							this.changeChannel(channel); // in case enduser=>endcustomer
						}
						
					}

				}
				if (iindex !== -1) {
					loadedChannels = this.props.liveChannelList.filter(
						(user) => user.id !== -1
					);
					loadedChannels.push(tmpsnap);
					//console.log("forcedupdate");
					this.changeLiveChannelList(loadedChannels);
					//this.setState({ liveChannelList: loadedChannels }); // ! shouldn't need this since props has it?
					
				}
			}
			//this.forceUpdate();

			// set to channels
			// set first channel

			//this.forceUpdate();
		});
	};

	closedQueuedChannelListener = () => {
		var loadedChannels = [];
		var tmpsnap = [];

		const userRef = firebase.auth().currentUser.uid; // Shalom!! hack
		this.state.queuedChannelsRef.on("child_removed", (snap) => {
			// push channels

			tmpsnap = snap.val(); // Shalom!! hack

			if (tmpsnap.agentID === userRef) {
				// ! Shalom!! hack

				const index = this.props.queuedChannelList.findIndex(
					(user) => user.id === tmpsnap.id
				);
				if (index !== -1) {
					loadedChannels = this.props.queuedChannelList.filter(
						(user) => user.id !== tmpsnap.id
					);
					this.changeQueuedChannelList(loadedChannels);
					//this.setFirstChannel()
				}
			}
			//this.forceUpdate();

			// set to channels
			// set first channel

			/*
			this.setState({ queuedChannelList: loadedChannels }, () =>
				this.setFirstChannel()
			);
			*/
		});
	};

	/**
	 * display channels
	 *
	 * @param channels
	 * @param userColors
	 */
	displayChannels = (channels, allowColor, userColors) =>
		/* tried to add random number to fix -1 being key duplicate error seemed to fix the live repeat ded people*/
		channels && 
		channels.length > 0 &&
		channels.map((channel) => (
			
			<li
				key={channel.id>0 ? channel.id : Math.floor(Math.random()*100000)}
				name={channel.name}
				className={
					this.props.currentChannel &&
					this.props.currentChannel.id === channel.id
						? "sc-item sc-active"
						: "sc-item"
				}
				style={{
					backgroundColor:
						this.props.currentChannel &&
						this.props.currentChannel.id === channel.id
							? "#2196f3" // TODO set to a system color not hard coded
							: null,
				}}
			>
				{this.props.currentChannel?  // is selected channel or null?
					(
					  ((this.props.currentChannel.id !== channel.id) && ( channel.active) && (Date.now() - channel.timestamp > 50000 ))? 
						(this.showNotifications() // tryin gto alert every 50s a cust is here... until they click on channel (i assume they will talk but not handling that just click channel)
							):(
								console.log()
								)
					):(
						( channel.active) && (Date.now() - channel.timestamp > 50000 )? 
						(this.showNotifications() // tryin gto alert every 50s a cust is here... until they click on channel (i assume they will talk but not handling that just click channel)
							):(
								console.log()
								)

					)				
				}
				<Button
					style={{
						color: userColors.color_secondary,
						backgroundColor: this.setChannelColor(
							channel,
							allowColor,
							userColors
						),
					}}
					variant="contained"
					type="button"
					onClick={() => this.changeChannel(channel)}
					fullWidth
				>
					<center>
						{channel.active &&
						Date.now() - channel.timestamp < 250000 ? (
							<StyledBadge
								overlap="circular"
								className="sc-dot"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								variant="dot"
							>
								<Avatar alt="Remy Sharp" src={channel.avatar} />
							</StyledBadge>
						) : (
							<Avatar alt="Remy Sharp" src={channel.avatar} />
						)}
						<p style={{ fontSize: "10px" }}>{channel.name + ""}</p>
					</center>
					&nbsp;&nbsp;
					{channel &&
						channel.lastMessage &&
						channel.lastMessage.content && (
							<p style={{ fontSize: "15px" }}>
								{channel.lastMessage.content.length-3 > 21
									? channel.lastMessage.content.substr(0, 21)+'...'
									: channel.lastMessage.content.replace('1-=-1','...').concat(
											this.state.blanks.substr(
												channel.lastMessage.content
														.length-3,
												16
													
											)
									  )}
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							</p>
						)}
					<p
						style={{
							fontSize: "10px",
							textAlign: "right",
							alignSelf: "stretch",
						}}
					>
						{Date.now() - channel.timestamp > 86400000
							? " (" +
							  Math.round(
									(Date.now() - channel.timestamp) / 86400000
							  ) +
							  " day(s) ago)"
							: Date.now() - channel.timestamp > 3600000
							? " (" +
							  Math.round(
									(Date.now() - channel.timestamp) / 3600000
							  ) +
							  " hr(s) ago)"
							: " (" +
							  formatMessageTime(
									Date.now() - channel.timestamp,
									"m"
							  ) +
							  " min(s) ago)"}
					</p>
				</Button>
			</li>
		));

	/**
	 * change channel
	 *
	 * @param channel
	 */
	changeChannel = (channel) => {
		// set current channel
		const {endCustomersRef}=this.state;
		this.props.setChannel(channel);
		if (channel.endCustomerId){
			endCustomersRef
			.child(channel.endCustomerId)
			.once("value",(snap) => {
				const snapshot = snap.val();
				this.props.setCustomer(snapshot)
			})

		}else{
			this.props.setCustomer(null)
			this.props.setCustomerNoteCount(0)
			this.props.setCustomerTicketCount(0)
		}
		// TODO how to set customer?
	};
	/**
	 * change channelList
	 *
	 * @param channelLivelist
	 */
	changeLiveChannelList = (channels) => {
		// set current channel
		this.props.setLiveChannelList(channels);
	};
	changeQueuedChannelList = (channels) => {
		// set current channel
		this.props.setQueuedChannelList(channels);
	};

	setChannelColor = (channel, allowColor, userColors) => {
		const { currentUser, currentChannel } = this.props;

		//console.log(currentUser.uid)
		if (currentChannel) {
			
			if (allowColor && channel.id !== currentChannel.id) {
				if (channel.lastMessageUid === currentUser.uid) {
					return "#00000"; // TODO set to a system color not hard coded
				} else {
					
					return channel.timestamp &&
						Date.now() - channel.timestamp > 50000
						? "#FF0000" // TODO set to a system color not hard coded
						: " #FFEE58";
				}
			}
		} else {
			if (allowColor) {
				if (channel.lastMessageUid === currentUser.uid) {
					return "#00000"; // TODO set to a system color not hard coded
				} else {
					return channel.timestamp &&
						Date.now() - channel.timestamp > 50000
						? "#FF0000" // TODO set to a system color not hard coded
						: " #FFEE58";
				}
			}
		}
		// if agent was last message green
		// else if customer typing/type yello
		// if customer type more than a minute ago red
	};

	clearBadgeSetShow(flag) {
		this.props.setCallsBadgesShow(flag);
		this.props.setLiveMessagesCounts(0);
	}

	/**
	 * remove channel listener
	 */
	removeChannelListener = () => {
		this.state.channelsRef.off("child_added"); // ? do i need the explicit "child_added"? etc below?
	};
	removeQueuedChannelListener = () => {
		this.state.queuedChannelsRef.off("child_added");
	};
	removeClosedChannelListener = () => {
		this.state.channelsRef.off("child_removed");
	};
	removeClosedQueuedChannelListener = () => {
		this.state.queuedChannelsRef.off("child_removed");
	};
	removeChangedChannelListener = () => {
		this.state.channelsRef.off("child_changed");
	};
}

// props
const mapStateToProps = (state) => ({
	currentUser: state.user.currentUser,
	currentChannel: state.channel.currentChannel,
	expandedPanel: state.expandedPanel,
	userStatus: state.user.status,
	userColors: state.user.colors,
	callsScreen: state.user.callsScreen,
	liveChannelList: state.channel.currentLiveChannelList,
	queuedChannelList: state.channel.currentQueuedChannelList,
});
export default connect(mapStateToProps, {
	setChannel,
	setLiveChannelList,
	setQueuedChannelList,
	setLiveMessagesCounts,
	setCallsBadgesShow,
	setCallsScreen,
	setCustomer,
	setCustomerNoteCount,
	setCustomerTicketCount,
})(Channels);
