
const callLogger = (dataIn)=>{
    var url = process.env.REACT_APP_SEND_LOG_ENDPOINT; 
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    //xhr.setRequestHeader("Api-Key","981bdc7203b81b159b38fa45cb9b9a75FFFFNRAL")
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
            console.log(xhr.responseText);
        }
    };
    xhr.send(JSON.stringify(dataIn));

}

export default callLogger;